


body.page-template-page-bunjou_2{
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  margin: 0;
  font-family: $font-family;
}

.bunjou{
  .bunjou-02__header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:80px;
    @include media($mobile){
      height: $fixed-height-mobile;
    }
    background-color: #fff;
    opacity: 1;
    z-index: 888;
  }
  .bunjou-02__header__logo{
    float: left;
    margin-left: 10px;
    margin-top: 15px;
    @include media($mobile){
      margin-top: 6px;
    }

    img{
      height: 50px;
      width: auto;
      @include media($mobile){
        height: 30px;
      }
    }
  }

  .bunjou-02__header__nav{
    float: right;
    margin-top: 15px;
    @include media($middle){
      display: none;
    }
    .bunjou-02__header__nav__list{
      line-height: 4.5;
      font-size: 12px;
      &__item{
        display: inline-block;
        margin-right: 15px;
        @include media($middle){
          margin-right: 7px;
        }
      }

    }
  }
  .bunjou-02__header_flag{
    float: right;
    margin-right: 30px;
    margin-top: 15px;
    @include media($middle){
      display: none;
    }
    &__list{
      margin-top: 8px;
      font-size: 16px;

      &__item--blue{
        display: inline-block;
        background-color: $flg-blue;
        padding: 5px 15px;
        margin-right: 5px;
        a{
          color: #fff;
        }
      }
      &__item--red{
        display: inline-block;
        background-color: $flg-red;
        padding: 5px 25px;
        a{color: #fff;}
      }
    }
  }
  .section-0{
    .sp-display-none{
      @include media($middle){
        display: none;
      }
    }
    a{
      display: block;
      font-size: 0px;
      &:hover{
        cursor: zoom-in !important;
      }
    }
    &__list{
      @include clearfix;
      width: 100%;
      .abusolute{
        width: 100%;
        text-align: center;
        position: absolute;
        top:50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);



        p{
          font-size: 2.0rem;
          font-weight: bold;
          color: #fff;
          @include media($middle){
            font-size: 1.0rem;
          }
        }
      }
      .relative{
        position: relative;
        font-size: 0px;
      }
      &__item{
        width: 50%;
        float: left;
        font-size: 0px;

      }
      &__item_pc{
        width: 50%;
        float: left;
        @include media($mobile){
          width: 100%;
        }

        video{
          width: 100%;
        }
      }
    }
  }

  .bunjo-02-section-1{
    background: none !important;
  }
}

.header_after_02{
  width:100%;
  height:80px;
  @include media($mobile){
    height: $fixed-height-mobile;
  }
}
.section-5{
  .section-5-bunjou-heading--bgcolor--2{
    background: none !important;
  }
  .section-5-bunjou-heading--large--2{
    font-size: 54px !important;
    @include media($middle){
      font-size: 20px !important;
    }
  }
  .bunjou-heading--h4--large--2{
    font-size: 18px !important;
    @include media($middle){
      font-size: 12px !important;
    }
  }
}
.section-6{
  .access__movie{
    margin-bottom: 40px;
  }
  .section-6__movie__width{
    @include media($middle){
      width: 100%;
    }
  }
}




.section-7{
  .section-7__bukken-02{
    padding: 50px 0px;
    @include clearfix;
    @include media($mobile){
      padding: 30px 0px;
    }
  }

  .section-7__bukken-02--1{
    @extend .section-7__bukken-02;
    background-color:$color-bulue;
  }
  .section-7__bukken-02--2{
    @extend .section-7__bukken-02;
    background-color:$color-green;
  }
  .section-7__bukken-02--3{
    @extend .section-7__bukken-02;
    background-color:$color-red;
  }
  .section-7__bukken-02--4{
    @extend .section-7__bukken-02;
    background-color:$color-green;
  }
  .section-7__bukken-02--5{
    @extend .section-7__bukken-02;
    background-color:$color-bulue;
  }



  .section-7__bukken-02--clearfix{
    @include clearfix;
    //margin-bottom: 40px;
  }


  .section-7__bukken-02__img--1{
    float: left;
    margin-right: 0px;
    width: 457px;
    height: 306px;
    background: url('./assets/img/bunjo_2/mitori_1_0422.png');
    background-repeat: no-repeat;
    background-size:cover;
    @include media($middle){
      float: none;
      width: 584px;
      height: 462px;
      background: url('./assets/img/bunjo_2/mitori_1_sp_0422.png');
      background-size:cover;
      background-repeat: no-repeat;
      margin-right: 0px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: 231px;
    }
  }
  .section-7__bukken-02__img--2{
    float: left;
    margin-right: 0px;
    width: 457px;
    height: 306px;
    background: url('./assets/img/bunjo_2/mitori_2_0422.png');
    background-repeat: no-repeat;
    background-size:cover;
    @include media($middle){
      float: none;
      width: 584px;
      height: 462px;
      background: url('./assets/img/bunjo_2/mitori_2_sp_0422.png');
      background-size:contain;
      background-repeat: no-repeat;
      margin-right: 0px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: 231px;
    }
  }
  .section-7__bukken-02__img--3{
    float: left;
    margin-right: 0px;
    width: 457px;
    height: 306px;
    background: url('./assets/img/bunjo_2/mitori_3_0422.png');
    background-repeat: no-repeat;
    background-size:cover;
    @include media($middle){
      float: none;
      width: 584px;
      height: 462px;
      background: url('./assets/img/bunjo_2/mitori_3_sp_0422.png');
      background-size:contain;
      background-repeat: no-repeat;
      margin-right: 0px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: 231px;
    }
  }
  .section-7__bukken-02__img--4{
    float: left;
    margin-right: 0px;
    width: 457px;
    height: 306px;
    background: url('./assets/img/bunjo_2/mitori_4_0422.png');
    background-repeat: no-repeat;
    background-size:cover;
    @include media($middle){
      float: none;
      width: 584px;
      height: 462px;
      background: url('./assets/img/bunjo_2/mitori_4_sp_0422.png');
      background-size:contain;
      background-repeat: no-repeat;
      margin-right: 0px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: 231px;
    }
  }
  .section-7__bukken-02__img--5{
    float: left;
    margin-right: 0px;
    width: 457px;
    height: 306px;
    background: url('./assets/img/bunjo_2/mitori_5_0422.png');
    background-repeat: no-repeat;
    background-size:cover;
    @include media($middle){
      float: none;
      width: 584px;
      height: 462px;
      background: url('./assets/img/bunjo_2/mitori_5_sp_0422.png');
      background-size:contain;
      background-repeat: no-repeat;
      margin-right: 0px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: 231px;
    }
  }

  .section-7__bukken-02__overflow{
    width: 100%;
    margin-bottom:50px;
    border: 1px solid #78c39c;
    background-color: #fff;
    padding: 40px 0px 35px 30px;
    @include media($middle){
      width: 584px;
      margin: 0 auto;
      margin-bottom: 50px;
      padding: 20px 0px 10px 20px;
    }
    @include media($mobile){
      padding: 20px 10px 10px;
      width: 292px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .section-7__bukken-02__overflow--title{
      display: inline-block;
      background-color: #999999;
      color: #fff;
      font-weight: normal;
      line-height: 1.8;
      text-align: center;
      letter-spacing: 0.05em;
      padding: 0px 7px;
      margin-bottom: 15px;
      margin-right: 5px;
      @include media($middle){
        font-size: 12px;
        margin-bottom: 15px;
        margin-right: 6px;
      }
      @include media($mobile){
        margin-right: 10px;
        width: 98px;
        padding: 0px;
        font-size: 11px;
      }
    }
    .section-7__bukken-02__overflow--content{
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      @include media($middle){
        font-size: 12px;
        margin-right: 5px;
      }
      @include media($mobile){
        margin-right: 0px;
        font-size: 11px;
        width: calc( 100% - 120px );
      }
      &:nth-child(8){
        @include media($large){
          margin-right: 70px;
        }
      }
    }
  }

  .section-7__bukken-02__txt{
    float: left;
    width: 563px;
    height: 305px;
    padding: 30px 20px;
    border: 1px solid #78c39c;
    background-color: #fff;
    @include media($middle){
      width: 584px;
      height: auto;
      float: none;
      padding: 30px;
      margin: 0 auto;
    }
    @include media($mobile){
      width: 292px;
      height: auto;
      padding: 30px 20px 25px;
    }
  }

  .section-7__bukken-02__txt--h3{
    //h3要素
    font-family:$font-family;
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
    @include media($middle){
      font-size: 18px;
      margin-bottom: 15px;
    }
    @include media($mobile){
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .section-7__bukken-02__txt--dl{
    display: block;
    margin-bottom: 20px;
    margin-left: 10px;
    @include media($middle){
      width: 300px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    @include media($mobile){
      width: 100%;
    }
  }
  .section-7__bukken-02__txt--title{
    font-size: 18px;
    display: inline-block;
    background-color: #999999;
    color: #fff;
    font-weight: normal;
    width: 140px;
    line-height: 1.8;
    text-align: center;
    margin-right: 10px;
    letter-spacing: 0.05em;
    @include media($middle){
      font-size: 14px;
    }
    @include media($mobile){
      margin-right: 10px;
      width: 90px;
      font-size: 12px;
    }
  }
  .section-7__bukken-02__txt--content{
    font-size: 18px;
    display: inline-block;
    letter-spacing: 0.05em;
    @include media($middle){
      font-size: 14px;
      letter-spacing: 0em;
    }
    @include media($mobile){
      font-size: 12px;
    }
  }

}

.fixed-left{
  position: fixed;
  top:50%;
  left:50px;
  -webkit-transform: translate(0%,-50%);
  -moz-transform: translate(0%,-50%);
  -ms-transform: translate(0%,-50%);
  -o-transform: translate(0%,-50%);
  transform: translate(0%,-50%);
  opacity: 0.6;
  @include media($middle){
    display: none;
  }

  #menu li a{
    &::before{
      content: "";
      display: block;
      background-image: url("./assets/img/bunjo_2/circle-regular.svg");
      width:13px;
      height: 13px;
      margin-bottom: 0px;
    }
    &::after{
      position: relative;
      top:0px;
      left: 6px;
      content:"";
      width: 1px;
      height: 20px;
      display: block;
      background-color: #e94e4e;
    }
  }
  #menu li:last-child{
    a::after{
      display: none;
    }
  }
  #menu li .on{
    &::before{
      background-image: url("./assets/img/bunjo_2/circle-solid.svg");
    }
  }
}
