@charset "UTF-8";
/* Theme Name: ogorifudosan */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #1565c0;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "Meiryo UI", "ＭＳ ゴシック", "ＭＳ Ｐ明朝", "MS PMincho", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

button:hover, [type='button']:hover, [type='reset']:hover, [type='submit']:hover {
  background-color: #11519a;
  color: #fff;
}

button:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus {
  outline: 3px solid rgba(21, 101, 192, 0.6);
  outline-offset: 2px;
}

button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
  background-color: #1565c0;
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em;
}

input,
select,
textarea {
  display: block;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "Meiryo UI", "ＭＳ ゴシック", "ＭＳ Ｐ明朝", "MS PMincho", sans-serif;
  font-size: 16px;
}

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%;
}

[type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover {
  border-color: #7a7a7a;
}

[type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
  border-color: #1565c0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(21, 101, 192, 0.7);
  outline: none;
}

[type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

[type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
  border: 1px solid #999;
}

[type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
  color: #858585;
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em;
}

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%;
}

select {
  margin-bottom: 0.75em;
  width: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus,
[type="file"]:focus,
select:focus {
  outline: 3px solid rgba(21, 101, 192, 0.6);
  outline-offset: 2px;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid #737373;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left;
}

td {
  border-bottom: 1px solid #999;
  padding: 0.75em 0;
}

tr,
td,
th {
  vertical-align: middle;
}

html {
  color: #333;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "Meiryo UI", "ＭＳ ゴシック", "ＭＳ Ｐ明朝", "MS PMincho", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  height: 100%;
}

body {
  font-size: 0.875rem;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, "Meiryo UI", "ＭＳ ゴシック", "ＭＳ Ｐ明朝", "MS PMincho", sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em;
}

p {
  margin: 0 0 0.75em;
}

a {
  color: #000;
  text-decoration-skip: ink;
  transition: color 150ms ease;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
  transition: all 0.3s ease;
}

hr {
  border-bottom: 1px solid #999;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0;
}

img {
  border: none;
}

/* ---------- content-width ---------- */
/* ---------- breakpoints ---------- */
/* ---------- gutter ---------- */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*! * * * * * * * * * * * * * * * * * * * *\  
  CSShake :: Package
  v1.5.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
.shake, .shake-little, .shake-slow, .shake-hard, .shake-horizontal, .shake-vertical, .shake-rotate, .shake-opacity, .shake-crazy, .shake-chunk {
  display: inline-block;
  transform-origin: center center;
}

.shake-freeze,
.shake-constant.shake-constant--hover:hover,
.shake-trigger:hover .shake-constant.shake-constant--hover {
  animation-play-state: paused;
}

.shake-freeze:hover,
.shake-trigger:hover .shake-freeze, .shake:hover,
.shake-trigger:hover .shake, .shake-little:hover,
.shake-trigger:hover .shake-little, .shake-slow:hover,
.shake-trigger:hover .shake-slow, .shake-hard:hover,
.shake-trigger:hover .shake-hard, .shake-horizontal:hover,
.shake-trigger:hover .shake-horizontal, .shake-vertical:hover,
.shake-trigger:hover .shake-vertical, .shake-rotate:hover,
.shake-trigger:hover .shake-rotate, .shake-opacity:hover,
.shake-trigger:hover .shake-opacity, .shake-crazy:hover,
.shake-trigger:hover .shake-crazy, .shake-chunk:hover,
.shake-trigger:hover .shake-chunk {
  animation-play-state: running;
}

@keyframes shake {
  2% {
    transform: translate(-1.5px, 2.5px) rotate(0.5deg);
  }
  4% {
    transform: translate(2.5px, 2.5px) rotate(-0.5deg);
  }
  6% {
    transform: translate(-1.5px, 1.5px) rotate(1.5deg);
  }
  8% {
    transform: translate(0.5px, -0.5px) rotate(-0.5deg);
  }
  10% {
    transform: translate(1.5px, -0.5px) rotate(-0.5deg);
  }
  12% {
    transform: translate(0.5px, -0.5px) rotate(1.5deg);
  }
  14% {
    transform: translate(2.5px, -0.5px) rotate(1.5deg);
  }
  16% {
    transform: translate(-0.5px, 2.5px) rotate(0.5deg);
  }
  18% {
    transform: translate(-0.5px, 1.5px) rotate(1.5deg);
  }
  20% {
    transform: translate(1.5px, 0.5px) rotate(0.5deg);
  }
  22% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg);
  }
  24% {
    transform: translate(0.5px, 2.5px) rotate(-0.5deg);
  }
  26% {
    transform: translate(2.5px, 2.5px) rotate(0.5deg);
  }
  28% {
    transform: translate(1.5px, -1.5px) rotate(-0.5deg);
  }
  30% {
    transform: translate(0.5px, -1.5px) rotate(1.5deg);
  }
  32% {
    transform: translate(-0.5px, 2.5px) rotate(-0.5deg);
  }
  34% {
    transform: translate(0.5px, -1.5px) rotate(-0.5deg);
  }
  36% {
    transform: translate(-0.5px, -1.5px) rotate(-0.5deg);
  }
  38% {
    transform: translate(1.5px, 2.5px) rotate(1.5deg);
  }
  40% {
    transform: translate(0.5px, 1.5px) rotate(1.5deg);
  }
  42% {
    transform: translate(0.5px, 2.5px) rotate(0.5deg);
  }
  44% {
    transform: translate(2.5px, 2.5px) rotate(0.5deg);
  }
  46% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg);
  }
  48% {
    transform: translate(-0.5px, 0.5px) rotate(-0.5deg);
  }
  50% {
    transform: translate(2.5px, -0.5px) rotate(-0.5deg);
  }
  52% {
    transform: translate(0.5px, 2.5px) rotate(0.5deg);
  }
  54% {
    transform: translate(0.5px, 1.5px) rotate(-0.5deg);
  }
  56% {
    transform: translate(2.5px, -1.5px) rotate(0.5deg);
  }
  58% {
    transform: translate(-0.5px, -1.5px) rotate(-0.5deg);
  }
  60% {
    transform: translate(2.5px, -1.5px) rotate(-0.5deg);
  }
  62% {
    transform: translate(0.5px, -0.5px) rotate(-0.5deg);
  }
  64% {
    transform: translate(0.5px, 1.5px) rotate(-0.5deg);
  }
  66% {
    transform: translate(1.5px, 1.5px) rotate(0.5deg);
  }
  68% {
    transform: translate(-1.5px, 1.5px) rotate(0.5deg);
  }
  70% {
    transform: translate(2.5px, -1.5px) rotate(0.5deg);
  }
  72% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  74% {
    transform: translate(2.5px, 1.5px) rotate(1.5deg);
  }
  76% {
    transform: translate(0.5px, 0.5px) rotate(-0.5deg);
  }
  78% {
    transform: translate(2.5px, -1.5px) rotate(1.5deg);
  }
  80% {
    transform: translate(0.5px, 1.5px) rotate(1.5deg);
  }
  82% {
    transform: translate(-1.5px, -0.5px) rotate(0.5deg);
  }
  84% {
    transform: translate(2.5px, 0.5px) rotate(0.5deg);
  }
  86% {
    transform: translate(1.5px, 0.5px) rotate(-0.5deg);
  }
  88% {
    transform: translate(-1.5px, -0.5px) rotate(-0.5deg);
  }
  90% {
    transform: translate(2.5px, 1.5px) rotate(-0.5deg);
  }
  92% {
    transform: translate(0.5px, -0.5px) rotate(1.5deg);
  }
  94% {
    transform: translate(-0.5px, 0.5px) rotate(-0.5deg);
  }
  96% {
    transform: translate(2.5px, 0.5px) rotate(1.5deg);
  }
  98% {
    transform: translate(-0.5px, -0.5px) rotate(-0.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake:hover,
.shake-trigger:hover .shake, .shake.shake-freeze, .shake.shake-constant {
  animation-name: shake;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-little {
  2% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  4% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  6% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  8% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  12% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  16% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  18% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  20% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  22% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  24% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  26% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  28% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  30% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  32% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  34% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  38% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  40% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  42% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  44% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  46% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  50% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  52% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  54% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  56% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  58% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  60% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  62% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  64% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  66% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  68% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  70% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  72% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  74% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  76% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  78% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  80% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  82% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  84% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  86% {
    transform: translate(0px, 1px) rotate(0.5deg);
  }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg);
  }
  90% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  92% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  94% {
    transform: translate(1px, 0px) rotate(0.5deg);
  }
  96% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  98% {
    transform: translate(1px, 1px) rotate(0.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-little:hover,
.shake-trigger:hover .shake-little, .shake-little.shake-freeze, .shake-little.shake-constant {
  animation-name: shake-little;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-slow {
  2% {
    transform: translate(-5px, -8px) rotate(-2.5deg);
  }
  4% {
    transform: translate(-6px, -9px) rotate(3.5deg);
  }
  6% {
    transform: translate(5px, 4px) rotate(3.5deg);
  }
  8% {
    transform: translate(-5px, 9px) rotate(-2.5deg);
  }
  10% {
    transform: translate(-8px, -4px) rotate(-2.5deg);
  }
  12% {
    transform: translate(4px, -7px) rotate(3.5deg);
  }
  14% {
    transform: translate(8px, 3px) rotate(-1.5deg);
  }
  16% {
    transform: translate(-1px, -6px) rotate(-1.5deg);
  }
  18% {
    transform: translate(-1px, 9px) rotate(1.5deg);
  }
  20% {
    transform: translate(-7px, 3px) rotate(0.5deg);
  }
  22% {
    transform: translate(-9px, 4px) rotate(-1.5deg);
  }
  24% {
    transform: translate(8px, -4px) rotate(2.5deg);
  }
  26% {
    transform: translate(10px, -1px) rotate(-1.5deg);
  }
  28% {
    transform: translate(4px, 4px) rotate(2.5deg);
  }
  30% {
    transform: translate(5px, 3px) rotate(-0.5deg);
  }
  32% {
    transform: translate(-7px, -7px) rotate(1.5deg);
  }
  34% {
    transform: translate(7px, -8px) rotate(3.5deg);
  }
  36% {
    transform: translate(-4px, 8px) rotate(2.5deg);
  }
  38% {
    transform: translate(9px, 1px) rotate(2.5deg);
  }
  40% {
    transform: translate(7px, 4px) rotate(0.5deg);
  }
  42% {
    transform: translate(3px, -5px) rotate(3.5deg);
  }
  44% {
    transform: translate(-6px, -1px) rotate(2.5deg);
  }
  46% {
    transform: translate(-5px, -9px) rotate(-1.5deg);
  }
  48% {
    transform: translate(4px, 2px) rotate(0.5deg);
  }
  50% {
    transform: translate(-2px, -9px) rotate(-2.5deg);
  }
  52% {
    transform: translate(-2px, 7px) rotate(-1.5deg);
  }
  54% {
    transform: translate(-6px, 0px) rotate(-1.5deg);
  }
  56% {
    transform: translate(-2px, -7px) rotate(1.5deg);
  }
  58% {
    transform: translate(9px, -3px) rotate(-2.5deg);
  }
  60% {
    transform: translate(4px, 5px) rotate(2.5deg);
  }
  62% {
    transform: translate(-5px, 10px) rotate(-1.5deg);
  }
  64% {
    transform: translate(-4px, 2px) rotate(0.5deg);
  }
  66% {
    transform: translate(2px, 9px) rotate(2.5deg);
  }
  68% {
    transform: translate(4px, 3px) rotate(3.5deg);
  }
  70% {
    transform: translate(8px, 6px) rotate(-1.5deg);
  }
  72% {
    transform: translate(4px, 10px) rotate(1.5deg);
  }
  74% {
    transform: translate(5px, -5px) rotate(3.5deg);
  }
  76% {
    transform: translate(6px, -2px) rotate(-0.5deg);
  }
  78% {
    transform: translate(2px, 7px) rotate(-2.5deg);
  }
  80% {
    transform: translate(-1px, 1px) rotate(-2.5deg);
  }
  82% {
    transform: translate(4px, 7px) rotate(-2.5deg);
  }
  84% {
    transform: translate(0px, 6px) rotate(2.5deg);
  }
  86% {
    transform: translate(-8px, -6px) rotate(2.5deg);
  }
  88% {
    transform: translate(-8px, 8px) rotate(-2.5deg);
  }
  90% {
    transform: translate(2px, 9px) rotate(3.5deg);
  }
  92% {
    transform: translate(-8px, 7px) rotate(-1.5deg);
  }
  94% {
    transform: translate(4px, 0px) rotate(3.5deg);
  }
  96% {
    transform: translate(9px, 5px) rotate(0.5deg);
  }
  98% {
    transform: translate(2px, -9px) rotate(-1.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-slow:hover,
.shake-trigger:hover .shake-slow, .shake-slow.shake-freeze, .shake-slow.shake-constant {
  animation-name: shake-slow;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-hard {
  2% {
    transform: translate(5px, -7px) rotate(-2.5deg);
  }
  4% {
    transform: translate(-7px, 3px) rotate(0.5deg);
  }
  6% {
    transform: translate(6px, 0px) rotate(-0.5deg);
  }
  8% {
    transform: translate(5px, 4px) rotate(0.5deg);
  }
  10% {
    transform: translate(0px, -2px) rotate(-0.5deg);
  }
  12% {
    transform: translate(0px, -3px) rotate(1.5deg);
  }
  14% {
    transform: translate(-1px, 5px) rotate(-2.5deg);
  }
  16% {
    transform: translate(-1px, -7px) rotate(2.5deg);
  }
  18% {
    transform: translate(-5px, 2px) rotate(-2.5deg);
  }
  20% {
    transform: translate(-8px, 2px) rotate(2.5deg);
  }
  22% {
    transform: translate(-6px, 8px) rotate(2.5deg);
  }
  24% {
    transform: translate(8px, 6px) rotate(2.5deg);
  }
  26% {
    transform: translate(-7px, -4px) rotate(1.5deg);
  }
  28% {
    transform: translate(8px, 0px) rotate(-2.5deg);
  }
  30% {
    transform: translate(10px, -1px) rotate(-2.5deg);
  }
  32% {
    transform: translate(-4px, 4px) rotate(0.5deg);
  }
  34% {
    transform: translate(0px, 6px) rotate(2.5deg);
  }
  36% {
    transform: translate(2px, -5px) rotate(3.5deg);
  }
  38% {
    transform: translate(-8px, 7px) rotate(1.5deg);
  }
  40% {
    transform: translate(-1px, -9px) rotate(1.5deg);
  }
  42% {
    transform: translate(7px, -3px) rotate(1.5deg);
  }
  44% {
    transform: translate(9px, 4px) rotate(-2.5deg);
  }
  46% {
    transform: translate(7px, -2px) rotate(-0.5deg);
  }
  48% {
    transform: translate(7px, -2px) rotate(-2.5deg);
  }
  50% {
    transform: translate(8px, -6px) rotate(-2.5deg);
  }
  52% {
    transform: translate(6px, -8px) rotate(1.5deg);
  }
  54% {
    transform: translate(-1px, 0px) rotate(-0.5deg);
  }
  56% {
    transform: translate(-7px, -6px) rotate(-2.5deg);
  }
  58% {
    transform: translate(7px, -4px) rotate(0.5deg);
  }
  60% {
    transform: translate(8px, 4px) rotate(2.5deg);
  }
  62% {
    transform: translate(4px, -1px) rotate(1.5deg);
  }
  64% {
    transform: translate(-8px, 3px) rotate(2.5deg);
  }
  66% {
    transform: translate(-9px, -1px) rotate(-1.5deg);
  }
  68% {
    transform: translate(-1px, -4px) rotate(1.5deg);
  }
  70% {
    transform: translate(3px, 5px) rotate(0.5deg);
  }
  72% {
    transform: translate(0px, 7px) rotate(-0.5deg);
  }
  74% {
    transform: translate(-4px, 8px) rotate(0.5deg);
  }
  76% {
    transform: translate(7px, -4px) rotate(2.5deg);
  }
  78% {
    transform: translate(-3px, 1px) rotate(3.5deg);
  }
  80% {
    transform: translate(-5px, 8px) rotate(3.5deg);
  }
  82% {
    transform: translate(7px, -2px) rotate(1.5deg);
  }
  84% {
    transform: translate(-7px, 6px) rotate(-1.5deg);
  }
  86% {
    transform: translate(3px, 10px) rotate(1.5deg);
  }
  88% {
    transform: translate(-2px, 8px) rotate(2.5deg);
  }
  90% {
    transform: translate(-7px, 0px) rotate(-0.5deg);
  }
  92% {
    transform: translate(-6px, 4px) rotate(-2.5deg);
  }
  94% {
    transform: translate(0px, 7px) rotate(0.5deg);
  }
  96% {
    transform: translate(8px, -6px) rotate(-1.5deg);
  }
  98% {
    transform: translate(-5px, -9px) rotate(-0.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-hard:hover,
.shake-trigger:hover .shake-hard, .shake-hard.shake-freeze, .shake-hard.shake-constant {
  animation-name: shake-hard;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-horizontal {
  2% {
    transform: translate(-7px, 0) rotate(0);
  }
  4% {
    transform: translate(3px, 0) rotate(0);
  }
  6% {
    transform: translate(-5px, 0) rotate(0);
  }
  8% {
    transform: translate(3px, 0) rotate(0);
  }
  10% {
    transform: translate(-8px, 0) rotate(0);
  }
  12% {
    transform: translate(-4px, 0) rotate(0);
  }
  14% {
    transform: translate(3px, 0) rotate(0);
  }
  16% {
    transform: translate(5px, 0) rotate(0);
  }
  18% {
    transform: translate(-7px, 0) rotate(0);
  }
  20% {
    transform: translate(5px, 0) rotate(0);
  }
  22% {
    transform: translate(-2px, 0) rotate(0);
  }
  24% {
    transform: translate(-7px, 0) rotate(0);
  }
  26% {
    transform: translate(7px, 0) rotate(0);
  }
  28% {
    transform: translate(6px, 0) rotate(0);
  }
  30% {
    transform: translate(-3px, 0) rotate(0);
  }
  32% {
    transform: translate(-1px, 0) rotate(0);
  }
  34% {
    transform: translate(5px, 0) rotate(0);
  }
  36% {
    transform: translate(-4px, 0) rotate(0);
  }
  38% {
    transform: translate(3px, 0) rotate(0);
  }
  40% {
    transform: translate(3px, 0) rotate(0);
  }
  42% {
    transform: translate(10px, 0) rotate(0);
  }
  44% {
    transform: translate(8px, 0) rotate(0);
  }
  46% {
    transform: translate(2px, 0) rotate(0);
  }
  48% {
    transform: translate(10px, 0) rotate(0);
  }
  50% {
    transform: translate(7px, 0) rotate(0);
  }
  52% {
    transform: translate(4px, 0) rotate(0);
  }
  54% {
    transform: translate(-6px, 0) rotate(0);
  }
  56% {
    transform: translate(-9px, 0) rotate(0);
  }
  58% {
    transform: translate(-4px, 0) rotate(0);
  }
  60% {
    transform: translate(-7px, 0) rotate(0);
  }
  62% {
    transform: translate(9px, 0) rotate(0);
  }
  64% {
    transform: translate(-2px, 0) rotate(0);
  }
  66% {
    transform: translate(7px, 0) rotate(0);
  }
  68% {
    transform: translate(-1px, 0) rotate(0);
  }
  70% {
    transform: translate(-2px, 0) rotate(0);
  }
  72% {
    transform: translate(-8px, 0) rotate(0);
  }
  74% {
    transform: translate(3px, 0) rotate(0);
  }
  76% {
    transform: translate(-6px, 0) rotate(0);
  }
  78% {
    transform: translate(3px, 0) rotate(0);
  }
  80% {
    transform: translate(8px, 0) rotate(0);
  }
  82% {
    transform: translate(-1px, 0) rotate(0);
  }
  84% {
    transform: translate(-5px, 0) rotate(0);
  }
  86% {
    transform: translate(-7px, 0) rotate(0);
  }
  88% {
    transform: translate(-3px, 0) rotate(0);
  }
  90% {
    transform: translate(-2px, 0) rotate(0);
  }
  92% {
    transform: translate(9px, 0) rotate(0);
  }
  94% {
    transform: translate(4px, 0) rotate(0);
  }
  96% {
    transform: translate(5px, 0) rotate(0);
  }
  98% {
    transform: translate(10px, 0) rotate(0);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-horizontal:hover,
.shake-trigger:hover .shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
  animation-name: shake-horizontal;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-vertical {
  2% {
    transform: translate(0, 5px) rotate(0);
  }
  4% {
    transform: translate(0, 7px) rotate(0);
  }
  6% {
    transform: translate(0, 7px) rotate(0);
  }
  8% {
    transform: translate(0, 0px) rotate(0);
  }
  10% {
    transform: translate(0, 8px) rotate(0);
  }
  12% {
    transform: translate(0, -9px) rotate(0);
  }
  14% {
    transform: translate(0, -4px) rotate(0);
  }
  16% {
    transform: translate(0, 7px) rotate(0);
  }
  18% {
    transform: translate(0, -7px) rotate(0);
  }
  20% {
    transform: translate(0, -3px) rotate(0);
  }
  22% {
    transform: translate(0, -6px) rotate(0);
  }
  24% {
    transform: translate(0, 0px) rotate(0);
  }
  26% {
    transform: translate(0, -6px) rotate(0);
  }
  28% {
    transform: translate(0, 10px) rotate(0);
  }
  30% {
    transform: translate(0, -9px) rotate(0);
  }
  32% {
    transform: translate(0, -8px) rotate(0);
  }
  34% {
    transform: translate(0, -5px) rotate(0);
  }
  36% {
    transform: translate(0, -1px) rotate(0);
  }
  38% {
    transform: translate(0, -3px) rotate(0);
  }
  40% {
    transform: translate(0, 4px) rotate(0);
  }
  42% {
    transform: translate(0, -2px) rotate(0);
  }
  44% {
    transform: translate(0, -9px) rotate(0);
  }
  46% {
    transform: translate(0, 3px) rotate(0);
  }
  48% {
    transform: translate(0, 9px) rotate(0);
  }
  50% {
    transform: translate(0, 3px) rotate(0);
  }
  52% {
    transform: translate(0, -1px) rotate(0);
  }
  54% {
    transform: translate(0, 8px) rotate(0);
  }
  56% {
    transform: translate(0, 8px) rotate(0);
  }
  58% {
    transform: translate(0, 3px) rotate(0);
  }
  60% {
    transform: translate(0, -6px) rotate(0);
  }
  62% {
    transform: translate(0, -9px) rotate(0);
  }
  64% {
    transform: translate(0, 1px) rotate(0);
  }
  66% {
    transform: translate(0, -1px) rotate(0);
  }
  68% {
    transform: translate(0, 3px) rotate(0);
  }
  70% {
    transform: translate(0, 3px) rotate(0);
  }
  72% {
    transform: translate(0, -2px) rotate(0);
  }
  74% {
    transform: translate(0, 4px) rotate(0);
  }
  76% {
    transform: translate(0, 7px) rotate(0);
  }
  78% {
    transform: translate(0, -9px) rotate(0);
  }
  80% {
    transform: translate(0, 3px) rotate(0);
  }
  82% {
    transform: translate(0, 8px) rotate(0);
  }
  84% {
    transform: translate(0, -3px) rotate(0);
  }
  86% {
    transform: translate(0, -3px) rotate(0);
  }
  88% {
    transform: translate(0, 3px) rotate(0);
  }
  90% {
    transform: translate(0, 9px) rotate(0);
  }
  92% {
    transform: translate(0, -6px) rotate(0);
  }
  94% {
    transform: translate(0, 7px) rotate(0);
  }
  96% {
    transform: translate(0, 10px) rotate(0);
  }
  98% {
    transform: translate(0, 8px) rotate(0);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-vertical:hover,
.shake-trigger:hover .shake-vertical, .shake-vertical.shake-freeze, .shake-vertical.shake-constant {
  animation-name: shake-vertical;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-rotate {
  2% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  4% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  6% {
    transform: translate(0, 0) rotate(5.5deg);
  }
  8% {
    transform: translate(0, 0) rotate(6.5deg);
  }
  10% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  12% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  14% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  16% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  18% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  20% {
    transform: translate(0, 0) rotate(0.5deg);
  }
  22% {
    transform: translate(0, 0) rotate(0.5deg);
  }
  24% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  26% {
    transform: translate(0, 0) rotate(-6.5deg);
  }
  28% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  30% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  32% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  34% {
    transform: translate(0, 0) rotate(-5.5deg);
  }
  36% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  38% {
    transform: translate(0, 0) rotate(5.5deg);
  }
  40% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  42% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  44% {
    transform: translate(0, 0) rotate(5.5deg);
  }
  46% {
    transform: translate(0, 0) rotate(5.5deg);
  }
  48% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  50% {
    transform: translate(0, 0) rotate(3.5deg);
  }
  52% {
    transform: translate(0, 0) rotate(-4.5deg);
  }
  54% {
    transform: translate(0, 0) rotate(3.5deg);
  }
  56% {
    transform: translate(0, 0) rotate(3.5deg);
  }
  58% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  60% {
    transform: translate(0, 0) rotate(7.5deg);
  }
  62% {
    transform: translate(0, 0) rotate(-4.5deg);
  }
  64% {
    transform: translate(0, 0) rotate(-0.5deg);
  }
  66% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  68% {
    transform: translate(0, 0) rotate(-5.5deg);
  }
  70% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  72% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  74% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  76% {
    transform: translate(0, 0) rotate(-5.5deg);
  }
  78% {
    transform: translate(0, 0) rotate(2.5deg);
  }
  80% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  82% {
    transform: translate(0, 0) rotate(-4.5deg);
  }
  84% {
    transform: translate(0, 0) rotate(-3.5deg);
  }
  86% {
    transform: translate(0, 0) rotate(-2.5deg);
  }
  88% {
    transform: translate(0, 0) rotate(4.5deg);
  }
  90% {
    transform: translate(0, 0) rotate(1.5deg);
  }
  92% {
    transform: translate(0, 0) rotate(0.5deg);
  }
  94% {
    transform: translate(0, 0) rotate(-1.5deg);
  }
  96% {
    transform: translate(0, 0) rotate(-0.5deg);
  }
  98% {
    transform: translate(0, 0) rotate(-4.5deg);
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-rotate:hover,
.shake-trigger:hover .shake-rotate, .shake-rotate.shake-freeze, .shake-rotate.shake-constant {
  animation-name: shake-rotate;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-opacity {
  10% {
    transform: translate(3px, -3px) rotate(1.5deg);
    opacity: 0.03;
  }
  20% {
    transform: translate(0px, 3px) rotate(2.5deg);
    opacity: 0.19;
  }
  30% {
    transform: translate(-2px, 2px) rotate(2.5deg);
    opacity: 0.29;
  }
  40% {
    transform: translate(0px, 4px) rotate(-1.5deg);
    opacity: 0.8;
  }
  50% {
    transform: translate(0px, 5px) rotate(2.5deg);
    opacity: 0.23;
  }
  60% {
    transform: translate(-4px, 1px) rotate(-1.5deg);
    opacity: 0.46;
  }
  70% {
    transform: translate(4px, -1px) rotate(0.5deg);
    opacity: 0.88;
  }
  80% {
    transform: translate(2px, -2px) rotate(1.5deg);
    opacity: 0.86;
  }
  90% {
    transform: translate(0px, -2px) rotate(0.5deg);
    opacity: 0.04;
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-opacity:hover,
.shake-trigger:hover .shake-opacity, .shake-opacity.shake-freeze, .shake-opacity.shake-constant {
  animation-name: shake-opacity;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-crazy {
  10% {
    transform: translate(-18px, -19px) rotate(0deg);
    opacity: 0.09;
  }
  20% {
    transform: translate(-13px, -9px) rotate(7deg);
    opacity: 0.25;
  }
  30% {
    transform: translate(8px, 7px) rotate(10deg);
    opacity: 0.99;
  }
  40% {
    transform: translate(-15px, 2px) rotate(9deg);
    opacity: 0.8;
  }
  50% {
    transform: translate(8px, 10px) rotate(6deg);
    opacity: 0.1;
  }
  60% {
    transform: translate(9px, -15px) rotate(-8deg);
    opacity: 0.67;
  }
  70% {
    transform: translate(16px, -18px) rotate(6deg);
    opacity: 0.37;
  }
  80% {
    transform: translate(0px, -6px) rotate(9deg);
    opacity: 0.15;
  }
  90% {
    transform: translate(-6px, 20px) rotate(-6deg);
    opacity: 0.33;
  }
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-crazy:hover,
.shake-trigger:hover .shake-crazy, .shake-crazy.shake-freeze, .shake-crazy.shake-constant {
  animation-name: shake-crazy;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes shake-chunk {
  2% {
    transform: translate(13px, 11px) rotate(-14deg);
  }
  4% {
    transform: translate(9px, -13px) rotate(-3deg);
  }
  6% {
    transform: translate(4px, 8px) rotate(9deg);
  }
  8% {
    transform: translate(-13px, 0px) rotate(-12deg);
  }
  10% {
    transform: translate(14px, -14px) rotate(-2deg);
  }
  12% {
    transform: translate(7px, 8px) rotate(-4deg);
  }
  14% {
    transform: translate(4px, 0px) rotate(15deg);
  }
  16% {
    transform: translate(-10px, -2px) rotate(13deg);
  }
  18% {
    transform: translate(-3px, -13px) rotate(-1deg);
  }
  20% {
    transform: translate(-8px, -5px) rotate(12deg);
  }
  22% {
    transform: translate(-12px, 0px) rotate(7deg);
  }
  24% {
    transform: translate(10px, 10px) rotate(15deg);
  }
  26% {
    transform: translate(-8px, 2px) rotate(10deg);
  }
  28% {
    transform: translate(-10px, -6px) rotate(14deg);
  }
  30% {
    transform: translate(10px, -13px) rotate(-8deg);
  }
  32% {
    transform: translate(11px, -14px) rotate(-14deg);
  }
  34% {
    transform: translate(-8px, 10px) rotate(9deg);
  }
  36% {
    transform: translate(-2px, 3px) rotate(6deg);
  }
  38% {
    transform: translate(-2px, 12px) rotate(13deg);
  }
  0%, 40%, 100% {
    transform: translate(0, 0) rotate(0);
  }
}

.shake-chunk:hover,
.shake-trigger:hover .shake-chunk, .shake-chunk.shake-freeze, .shake-chunk.shake-constant {
  animation-name: shake-chunk;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(./assets/img/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(./assets/img/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(./assets/img/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(./assets/img/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/*!------------------------------------*\
    Base
\*!------------------------------------*/
.drawer-nav {
  position: fixed;
  z-index: 101;
  top: 0;
  overflow: hidden;
  width: 16.25rem;
  height: 100%;
  color: #222;
  background-color: #fff;
}

.drawer-brand {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 3.75rem;
  display: block;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.drawer-menu-item {
  font-size: 1rem;
  display: block;
  padding: 0.75rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! overlay */
.drawer-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-open .drawer-overlay {
  display: block;
}

/*!------------------------------------*\
    Top
\*!------------------------------------*/
.drawer--top .drawer-nav {
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--top.drawer-open .drawer-nav {
  top: 0;
}

.drawer--top .drawer-hamburger,
.drawer--top.drawer-open .drawer-hamburger {
  right: 0;
}

/*!------------------------------------*\
    Left
\*!------------------------------------*/
.drawer--left .drawer-nav {
  left: -16.25rem;
  transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--left.drawer-open .drawer-nav,
.drawer--left .drawer-hamburger,
.drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  left: 0;
}

.drawer--left.drawer-open .drawer-hamburger {
  left: 16.25rem;
}

/*!------------------------------------*\
    Right
\*!------------------------------------*/
.drawer--right .drawer-nav {
  right: -16.25rem;
  transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
  right: 0;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 16.25rem;
}

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.drawer-hamburger {
  position: fixed;
  z-index: 104;
  top: 0;
  display: block;
  box-sizing: content-box;
  width: 2rem;
  padding: 0;
  padding-top: 18px;
  padding-right: 0.75rem;
  padding-bottom: 30px;
  padding-left: 0.75rem;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: transparent;
}

.drawer-hamburger:hover {
  cursor: pointer;
  background-color: transparent;
}

.drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 10px;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  width: 100%;
  height: 2px;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #222;
}

.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  position: absolute;
  top: -10px;
  left: 0;
  content: ' ';
}

.drawer-hamburger-icon:after {
  top: 10px;
}

.drawer-open .drawer-hamburger-icon {
  background-color: transparent;
}

.drawer-open .drawer-hamburger-icon:before,
.drawer-open .drawer-hamburger-icon:after {
  top: 0;
}

.drawer-open .drawer-hamburger-icon:before {
  transform: rotate(45deg);
}

.drawer-open .drawer-hamburger-icon:after {
  transform: rotate(-45deg);
}

/*!------------------------------------*\
    accessibility
\*!------------------------------------*/
/*!
 * Only display content to screen readers
 * See: http://a11yproject.com/posts/how-to-hide-content
 */
.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*!
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * Credit: HTML5 Boilerplate
 */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

/*!------------------------------------*\
    Sidebar
\*!------------------------------------*/
.drawer--sidebar {
  background-color: #fff;
}

.drawer--sidebar .drawer-contents {
  background-color: #fff;
}

@media (min-width: 64em) {
  .drawer--sidebar .drawer-hamburger {
    display: none;
    visibility: hidden;
  }
  .drawer--sidebar .drawer-nav {
    display: block;
    transform: none;
    position: fixed;
    width: 12.5rem;
    height: 100%;
  }
  /*! Left */
  .drawer--sidebar.drawer--left .drawer-nav {
    left: 0;
    border-right: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 12.5rem;
  }
  /*! Right */
  .drawer--sidebar.drawer--right .drawer-nav {
    right: 0;
    border-left: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 12.5rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 48rem;
  }
}

@media (min-width: 75em) {
  .drawer--sidebar .drawer-nav {
    width: 16.25rem;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 16.25rem;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 16.25rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 60rem;
  }
}

/*!------------------------------------*\
    Navbar
\*!------------------------------------*/
.drawer--navbarTopGutter {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-navbar-header {
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.drawer-navbar {
  z-index: 102;
  top: 0;
  width: 100%;
}

/*! .drawer-navbar modifier */
.drawer-navbar--fixed {
  position: fixed;
}

.drawer-navbar-header {
  position: relative;
  z-index: 102;
  box-sizing: border-box;
  width: 100%;
  height: 3.75rem;
  padding: 0 0.75rem;
  text-align: center;
}

.drawer-navbar .drawer-brand {
  line-height: 3.75rem;
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: none;
}

.drawer-navbar .drawer-brand:hover {
  background-color: transparent;
}

.drawer-navbar .drawer-nav {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-menu {
  padding-bottom: 7.5rem;
}

@media (min-width: 64em) {
  .drawer-navbar {
    height: 3.75rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }
  .drawer-navbar .drawer-navbar-header {
    position: relative;
    display: block;
    float: left;
    width: auto;
    padding: 0;
    border: 0;
  }
  .drawer-navbar .drawer-menu--right {
    float: right;
  }
  .drawer-navbar .drawer-menu li {
    float: left;
  }
  .drawer-navbar .drawer-menu-item {
    line-height: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .drawer-navbar .drawer-hamburger {
    display: none;
  }
  .drawer-navbar .drawer-nav {
    position: relative;
    left: 0;
    overflow: visible;
    width: auto;
    height: 3.75rem;
    padding-top: 0;
    transform: translate3d(0, 0, 0);
  }
  .drawer-navbar .drawer-menu {
    padding: 0;
  }
  /*! dropdown */
  .drawer-navbar .drawer-dropdown-menu {
    position: absolute;
    width: 16.25rem;
    border: 1px solid #ddd;
  }
  .drawer-navbar .drawer-dropdown-menu-item {
    padding-left: 0.75rem;
  }
}

/*!------------------------------------*\
    Dropdown
\*!------------------------------------*/
.drawer-dropdown-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.drawer-dropdown-menu > li {
  width: 100%;
  list-style: none;
}

.drawer-dropdown-menu-item {
  line-height: 3.75rem;
  display: block;
  padding: 0;
  padding-right: 0.75rem;
  padding-left: 1.5rem;
  text-decoration: none;
  color: #222;
}

.drawer-dropdown-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! open */
.drawer-dropdown.open > .drawer-dropdown-menu {
  display: block;
}

/*! drawer-caret */
.drawer-dropdown .drawer-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  transition: transform .2s ease, opacity .2s ease;
  transform: rotate(0deg);
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/*! open */
.drawer-dropdown.open .drawer-caret {
  transform: rotate(180deg);
}

/*!------------------------------------*\
    Container
\*!------------------------------------*/
.drawer-container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 64em) {
  .drawer-container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .drawer-container {
    max-width: 70rem;
  }
}

body.pc {
  position: relative;
  height: 100%;
  background: url(./assets/img/sky_03.png) no-repeat center top;
  background-color: #EAF4F9;
  background-size: 100%;
  z-index: 1;
  margin-top: 38px;
}

.pc {
  /* ---------- 並び替え ----------*/
}

@media screen and (max-width: 1023px) {
  .pc body {
    font-size: 0.875rem;
  }
}

.pc #loader {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #fff;
  /*Loaderの背景色としたいものを指定*/
  opacity: 1.0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  /*一番前面に出るように（今回はbxSlidrの矢印が9999だったのでそれを超えるようにしました）*/
}

.pc #loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  /* Safari用 */
  transform: translate(-50%, -50%);
}

.pc .mb-30 {
  margin-bottom: 30px;
}

.pc .content-wrap {
  margin: 0 20px;
}

.pc .content-wrap__inner {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  border-left: 10px solid #9acedb;
  border-right: 10px solid #9acedb;
  padding: 0;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dff2fa 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dff2fa 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dff2fa 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 */
}

.pc .content-wrap__inner::after {
  clear: both;
  content: "";
  display: block;
}

.pc .wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.pc .wrapper::after {
  clear: both;
  content: "";
  display: block;
}

.pc .wrapper::after {
  clear: both;
  content: "";
  display: block;
}

.pc .main {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.pc .main::after {
  clear: both;
  content: "";
  display: block;
}

.pc .lower-main {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  float: right;
  position: relative;
  z-index: 1111;
  height: 100%;
}

.pc .lower-main::after {
  clear: both;
  content: "";
  display: block;
}

.pc .lower-main:before {
  position: absolute;
  left: 0;
  z-index: 4444;
  content: "";
  display: block;
  height: 100%;
  width: 4px;
  background: #040000;
  opacity: 0.4;
}

.pc .lower-gradient__small {
  padding-right: 20px;
}

.pc .lower-gradient__h100 {
  height: 100%;
}

.pc .lower-gradient__wrap {
  padding: 20px;
  border-right: 10px solid #9acedb;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dff2fa 1%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dff2fa 1%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dff2fa 1%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 */
}

.pc .lower-gradient__wrap__h100 {
  height: 100%;
}

.pc .lower-content-box {
  padding: 30px 20px;
  background: #fff;
  border-radius: 10px;
}

.pc .lower-content-box__h100 {
  height: 100%;
}

.pc .lower-heading {
  font-size: 1.25rem;
  padding: 10px;
  margin-bottom: 20px;
  color: #010101;
  /*文字色*/
  background: #f3fafd;
  /*背景色*/
  border-bottom: solid 2px #0068b7;
  /*下線*/
}

.pc .lower-sub-heading {
  font-size: 1.125rem;
  line-height: 1.3;
  margin-bottom: 15px;
  padding: 5px 10px;
  border-left: 4px solid #0068b7;
}

.pc .lower-include-contact:before, .pc .lower-include-contact:after {
  width: 700px;
  content: "";
  display: block;
  height: 17px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .lower-include-contact--no-bar::after {
  clear: both;
  content: "";
  display: block;
}

.pc .lower-include-contact--no-bar:before, .pc .lower-include-contact--no-bar:after {
  height: 0;
}

.pc .lower-include-contact--no-bar:after {
  clear: both;
}

.pc .lower-include-contact--no-bar .lower-include-contact__container {
  background: none !important;
}

.pc .lower-include-contact__container {
  position: relative;
  padding: 30px 20px;
  background: #dcdfe7;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 1%, #f6f2f2 50%, #dcdfe7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 1%, #f6f2f2 50%, #dcdfe7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 1%, #f6f2f2 50%, #dcdfe7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 );
  /* IE6-9 */
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);
}

.pc .lower-include-contact__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .lower-include-contact__container__left {
  width: 360px;
  float: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border: 1px solid #7d7d7d;
  border-radius: 4px;
}

.pc .lower-include-contact__container__left__heading {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 5px;
}

.pc .lower-include-contact__container__left__tel {
  display: block;
  text-align: center;
}

.pc .lower-include-contact__container__left__time {
  text-align: center;
  font-size: 0.8125rem;
  margin: 0;
}

.pc .lower-include-contact__container__right {
  position: relative;
  width: 280px;
  float: left;
  background: #c70000;
  border-radius: 4px;
  margin-left: 10px;
}

.pc .lower-include-contact__container__right a {
  display: block;
  padding: 10px;
}

.pc .lower-include-contact__container__right__heading {
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
}

.pc .lower-include-contact__container__right__txt {
  color: #fff;
  font-size: 1rem;
  line-height: 1.4;
}

.pc .lower-include-contact__container__right__icon-mail {
  position: absolute;
  right: 15px;
  bottom: 20px;
}

.pc .lower-include-contact__title {
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.pc .heading-block {
  margin-bottom: -4px;
  background: transparent;
}

.pc .heading-block:before, .pc .heading-block:after {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  content: "";
  display: block;
  height: 17px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .heading-block:before::after, .pc .heading-block:after::after {
  clear: both;
  content: "";
  display: block;
}

.pc .heading-block:after {
  position: relative;
  bottom: 4px;
  z-index: 1111;
}

.pc .heading-block__container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: url(./assets/img/heading_block_bg.png) no-repeat;
  background-size: 1000px;
  height: 105px;
  z-index: 2222;
}

.pc .heading-block__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .heading-block__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .heading-03 {
  font-size: 1rem;
  line-height: 1.3;
  border-left: 5px solid #0068b7;
  padding: 5px 0 5px 8px;
  border-bottom: 1px solid #0068b7;
  margin-bottom: 20px;
}

.pc .btn__red {
  display: block;
  text-align: center;
  width: 166px;
  margin: 0 auto;
  background: #ca2c2c;
  border-radius: 6px;
  border-bottom: 2px solid #cccccc;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 10px 0;
  line-height: 1.3;
  color: #fff;
}

.pc .btn__red span {
  display: inline-block;
}

.pc .btn__red:hover {
  background: #ca2c2c;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid #cccccc;
  color: #fff;
}

.pc .btn__simulation {
  width: 250px;
  padding: 15px 0;
}

.pc .btn__simulation:hover {
  padding: 16px 0 15px 0;
}

.pc .icon-arrow {
  background: url(./assets/img/icon_arrow_white.png) no-repeat left center;
  background-size: 8px 12px;
  padding-left: 15px;
}

.pc .post-list::after {
  clear: both;
  content: "";
  display: block;
}

.pc .post-list .post-item {
  /*
    @include span-columns(4);
    @include omega(3n);
    */
  border-right: 1px solid #f0f0f0;
  background: #fff;
  padding: 0;
  border-bottom: 2px solid #8ba9b7;
}

.pc .post-list .post-item__thumb {
  margin-bottom: 10px;
  height: 225px;
  overflow: hidden;
}

.pc .post-list .post-item__thumb img {
  width: 100%;
  height: auto;
}

.pc .post-list .post-item__info {
  padding: 15px 15px 20px 15px;
}

.pc .post-list .post-item__name {
  font-size: 1rem;
  color: #3452a9;
  line-height: 1.3;
}

.pc .post-list .post-item__name a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .post-list .post-item__price {
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-bottom: 5px;
}

.pc .post-list .post-item__floor {
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-bottom: 5px;
}

.pc .post-list .post-item__address {
  color: #666666;
}

.pc .post-list .post-item__access {
  color: #666666;
}

.pc .post-list .post-item__years {
  color: #666666;
}

.pc .post-list .slick-prev, .pc .post-list .slick-next {
  height: 71px;
  width: 71px;
  z-index: 2222;
}

.pc .post-list .slick-prev {
  left: -22px;
}

.pc .post-list .slick-prev:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: url(./assets/img/slider_icon_left.png) no-repeat;
  background-size: cover;
}

.pc .post-list .slick-next {
  right: -20px;
}

.pc .post-list .slick-next:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: url(./assets/img/slider_icon_right.png) no-repeat;
  background-size: cover;
}

.pc .heading-block__title {
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
  padding-top: 30px;
}

.pc .heading-block__title img {
  vertical-align: bottom;
}

.pc .heading-block__col-03 {
  margin: 0 25px;
  padding-top: 15px;
}

.pc .heading-block__col-03::after {
  clear: both;
  content: "";
  display: block;
}

.pc .heading-block__col-03__item {
  float: left;
  margin: 0 25px;
}

.pc .heading-block__col-02 {
  margin: 0 50px;
  padding-top: 15px;
}

.pc .heading-block__col-02::after {
  clear: both;
  content: "";
  display: block;
}

.pc .heading-block__col-02__item:first-child {
  float: left;
}

.pc .heading-block__col-02__item:last-child {
  float: right;
}

.pc .col-03-content {
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .col-03-content::after {
  clear: both;
  content: "";
  display: block;
}

.pc .col-03-content__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
  box-sizing: content-box;
}

.pc .col-03-content__item:last-child {
  margin-right: 0;
}

.pc .col-03-content__item:nth-child(3n) {
  margin-right: 0;
}

.pc .col-03-content__item:nth-child(3n+1) {
  clear: left;
}

.pc .col-03-content__item:nth-child(1) {
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #dff2fa 0%, #b4d7e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #dff2fa 0%, #b4d7e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #dff2fa 0%, #b4d7e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#b4d7e8',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .col-03-content__item:nth-child(2) {
  background: #d5ecf6;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #d5ecf6 0%, #7bb2cf 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #d5ecf6 0%, #7bb2cf 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #d5ecf6 0%, #7bb2cf 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5ecf6', endColorstr='#7bb2cf',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .col-03-content__item:nth-child(3) {
  background: #9cc8de;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #9cc8de 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #9cc8de 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #9cc8de 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9cc8de', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .col-03-content__item:last-child {
  margin-right: 0;
}

.pc .col-03-content__list {
  padding: 20px 20px 15px 20px;
}

.pc .col-03-content__list__tintai .col-03-content__list__item a {
  background: url(./assets/img/icon_tintai.png) no-repeat left 10px center;
  background-size: 30px 30px;
  padding-left: 55px;
}

.pc .col-03-content__list__uri .col-03-content__list__item a {
  background: url(./assets/img/icon_uri.png) no-repeat left 10px center;
  background-size: 30px 30px;
  padding-left: 55px;
}

.pc .col-03-content__list__bunjyou .col-03-content__list__item a {
  background: url(./assets/img/icon_bunjyou.png) no-repeat left 10px center;
  background-size: 30px 30px;
  padding-left: 55px;
}

.pc .col-03-content__list__item {
  background: #fff;
  border-radius: 4px;
  border-bottom: 2px solid #afbfc6;
  margin-bottom: 5px;
}

.pc .col-03-content__list__item:hover {
  position: relative;
  top: 1px;
  padding-bottom: 1px;
  border-bottom: 1px solid #afbfc6;
}

.pc .col-03-content__list__item:last-child {
  margin-bottom: 0;
}

.pc .col-03-content__list__item a {
  color: #000;
  display: block;
  padding: 10px;
  font-size: 0.9375rem;
}

.pc .col-03-content__area {
  padding: 0 20px 20px 20px;
}

.pc .col-03-content__area__heading {
  font-size: 1rem;
  margin-bottom: 8px;
  padding: 5px 10px;
  font-weight: bold;
}

.pc .col-03-content__area__heading:before {
  content: "\f0dd";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1rem;
  margin-right: 6px;
  position: relative;
  bottom: 3px;
}

.pc .col-03-content__area__heading.tintai {
  background: #006EA7;
  color: #fff;
}

.pc .col-03-content__area__heading.buy {
  background: #FF5E5E;
  color: #fff;
}

.pc .col-03-content__area__heading.tochi {
  background: #5E951D;
  color: #fff;
}

.pc .col-03-content__area__list.tintai .col-03-content__area__list__item a:before {
  color: #006EA7;
}

.pc .col-03-content__area__list.buy .col-03-content__area__list__item a:before {
  color: #FF5E5E;
}

.pc .col-03-content__area__list.tochi .col-03-content__area__list__item a:before {
  color: #5E951D;
}

.pc .col-03-content__area__list__item {
  background: #fff;
  border-radius: 4px;
  border-bottom: 2px solid #afbfc6;
  margin-bottom: 3px;
}

.pc .col-03-content__area__list__item:hover {
  position: relative;
  top: 1px;
  padding-bottom: 1px;
  border-bottom: 1px solid #afbfc6;
}

.pc .col-03-content__area__list__item:last-child {
  margin-bottom: 0;
}

.pc .col-03-content__area__list__item a {
  color: #000;
  display: block;
  padding: 5px 10px;
  font-size: 0.75rem;
}

.pc .col-03-content__area__list__item a:before {
  content: "\f041";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1rem;
  margin-right: 6px;
}

.pc .col-02-content {
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .col-02-content::after {
  clear: both;
  content: "";
  display: block;
}

.pc .col-02-content__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  padding: 20px;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #dff2fa 0%, #98c5dc 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #dff2fa 0%, #98c5dc 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #dff2fa 0%, #98c5dc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#98c5dc',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .col-02-content__left:last-child {
  margin-right: 0;
}

.pc .col-02-content__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  padding: 20px;
  background: #bcdceb;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #bcdceb 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #bcdceb 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #bcdceb 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcdceb', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .col-02-content__right:last-child {
  margin-right: 0;
}

.pc .col-02-content__box {
  background: #fff;
  border-bottom: 2px solid #b2c2c8;
  border-radius: 4px;
  padding: 25px 25px 20px 25px;
}

.pc .col-02-content__box__select {
  width: 45%;
  display: inline-block;
  font-size: .875rem;
  margin-bottom: 30px;
}

.pc .col-02-content__word-search {
  margin-top: 30px;
}

.pc .col-02-content__word-search::after {
  clear: both;
  content: "";
  display: block;
}

.pc .col-02-content__word-search .btn__red {
  width: 20%;
  display: inline-block;
  float: right;
  letter-spacing: .01rem;
}

.pc .col-02-content__word-search__input {
  width: 78%;
  display: inline-block;
  float: left;
}

.pc .col-02-content__list {
  margin-bottom: 15px;
}

.pc .col-02-content__list::after {
  clear: both;
  content: "";
  display: block;
}

.pc .col-02-content__list__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  line-height: 2;
}

.pc .col-02-content__list__item:last-child {
  margin-right: 0;
}

.pc .col-02-content__list__item:nth-child(2n) {
  margin-right: 0;
}

.pc .col-02-content__list__item:nth-child(2n+1) {
  clear: left;
}

.pc .col-02-content__list__item:nth-child(1) span, .pc .col-02-content__list__item:nth-child(2) span {
  border-bottom: 1px dotted #7f7f7f;
  padding-bottom: 5px;
  width: 70%;
  display: inline-block;
}

.pc .col-02-content__list__item label {
  font-weight: normal;
}

.pc .pr {
  margin: 0 20px;
  padding: 20px 0;
}

.pc .info {
  margin: 0 20px;
  padding: 20px 0;
}

.pc .info::after {
  clear: both;
  content: "";
  display: block;
}

.pc .info__item {
  position: relative;
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
  background: #fff;
  padding: 13px 10px;
  border-radius: 4px;
  border-bottom: 2px solid #8ba9b7;
  min-height: 380px;
}

.pc .info__item:last-child {
  margin-right: 0;
}

.pc .info__item:nth-child(3n) {
  margin-right: 0;
}

.pc .info__item:nth-child(3n+1) {
  clear: left;
}

.pc .info__item__image {
  margin-bottom: 5px;
}

.pc .info__item__reform-txt {
  font-size: .75rem;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 15px;
}

.pc .info__item__container {
  padding: 0 10px;
}

.pc .info__item__news-list {
  margin-bottom: 10px;
}

.pc .info__item__news-list__item {
  border-bottom: 1px dotted #7f7f7f;
}

.pc .info__item__news-list__item a {
  color: #000;
  padding: 5px 0;
  display: inline-block;
}

.pc .info__item__archive-link {
  text-align: right;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.pc .info__item__archive-link a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .breadcrumb {
  padding-right: 20px;
}

.pc .breadcrumb__list {
  text-align: left;
  font-size: 0;
  padding: 10px 15px;
  background: #554c4c;
  /* Old browsers */
  background: -moz-linear-gradient(left, #554c4c 1%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #554c4c 1%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #554c4c 1%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#554c4c', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .breadcrumb__list__item {
  display: inline-block;
  font-size: 0.625rem;
  color: #fff;
}

.pc .breadcrumb__list__item:after {
  content: "＞";
  display: inline-block;
  color: #fff;
  font-size: 0.625rem;
  margin: 0 5px;
}

.pc .breadcrumb__list__item:last-child:after {
  display: none;
}

.pc .breadcrumb__list__item a {
  color: #fff;
}

.pc #nendebcopy {
  display: none;
}

.pc .pageback a {
  font-size: .75rem;
  display: inline-block;
  padding: 10px 10px;
  background: #FF7E00;
  color: #fff;
}

.pc .search-heading {
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
}

.pc .side-search-heading {
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
  margin: 20px 10px 0 10px;
}

.pc .header {
  position: relative;
  z-index: 4444;
}

.pc .header__bar {
  width: 100%;
  background: #d90000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4444;
}

.pc .header__bar__item {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
}

.pc .header__bar__item::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__bar__item::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__bar__item__txt {
  float: left;
  margin: 0;
  color: #fff;
  font-size: 0.75rem;
  margin-top: 7px;
}

.pc .header__bar__item__menu {
  float: right;
}

.pc .header__bar__item__menu__item {
  display: inline-block;
  font-size: 0.75rem;
  margin-left: 15px;
  border: 1px solid #fff;
}

.pc .header__bar__item__menu__item a {
  display: block;
  padding: 5px 10px;
  color: #fff;
}

.pc .header__container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.pc .header__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__logo {
  float: left;
  margin: 7px 0 0 20px;
  vertical-align: bottom;
  line-height: 0;
  position: relative;
  top: 1px;
}

.pc .header__contact {
  float: right;
  margin-top: 30px;
}

.pc .header__contact__tel {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  bottom: 2px;
}

.pc .header__contact__mail {
  display: inline-block;
}

.pc .header__navi {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.pc .header__navi::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__navi__list {
  background: url(./assets/img/header_navi_bg.png) no-repeat;
  background-size: 1000px;
  padding: 0 20px;
}

.pc .header__navi__list::after {
  clear: both;
  content: "";
  display: block;
}

.pc .header__navi__list:after {
  content: "";
  display: block;
}

.pc .header__navi__list__item {
  width: 137px;
  float: left;
  text-align: center;
  font-size: 1rem;
  padding: 10px 0;
  font-weight: bold;
}

.pc .header__navi__list__item:last-child a {
  border-right: none;
}

.pc .header__navi__list__item a {
  color: #010101;
  display: block;
  border-right: 1px solid #dadada;
  padding: 11px 0;
  transition: all  0.3s ease;
}

.pc .header__navi__list__item a:hover {
  background: #d5d5d5;
  transition: all  0.3s ease;
}

.pc .header__navi__list__item__en {
  display: block;
  text-align: center;
  color: #0078cc;
  font-size: 0.75rem;
}

.pc .footer-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2223;
}

.pc .footer-fixed-btn {
  position: absolute;
  right: 0px;
  top: -30px;
}

.pc .footer-fixed-btn:hover {
  cursor: pointer;
}

.pc .footer-fixed-btn img {
  width: 25px;
}

.pc .bottom-contact {
  position: relative;
  z-index: 2222;
  background: url(./assets/img/bottom_contact_bg.png) repeat-x center bottom;
  height: 360px;
}

.pc .bottom-contact__title {
  text-align: center;
  padding-top: 34px;
  z-index: 3000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.pc .bottom-contact__container {
  position: relative;
  width: 1057px;
  height: 334px;
  margin: 0 auto;
  /*background: url(./assets/img/bottom_contact_building_2.png) no-repeat center top;*/
  background-size: 1057px;
}

.pc .bottom-contact__btn {
  position: absolute;
  right: 90px;
  bottom: 10px;
  z-index: 3333;
}

.pc .bottom-contact #img_01 {
  position: absolute;
  width: 1057px;
  height: 334px;
  z-index: 5;
}

.pc #img_02 {
  position: absolute;
  top: 122px;
  left: 379px;
  z-index: 1;
  z-index: 4;
}

.pc #img_03 {
  position: absolute;
  top: 122px;
  left: 530px;
  z-index: 4;
}

.pc #img_04 {
  position: absolute;
  top: 122px;
  left: 381px;
  z-index: 2;
}

.pc #peple img {
  position: absolute;
  right: 0;
  bottom: -25px;
  left: 0;
  margin: auto;
  z-index: 4000;
  display: none;
  height: 310px;
  width: auto;
}

.pc .footer {
  background: #fff;
}

.pc .footer__container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 50px 20px;
  background: #fff;
}

.pc .footer__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .footer__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .footer__left {
  width: 318px;
  float: left;
}

.pc .footer__left__logo {
  display: block;
  margin-bottom: 10px;
}

.pc .footer__left__info::after {
  clear: both;
  content: "";
  display: block;
}

.pc .footer__left__info__tel {
  display: block;
  margin-bottom: 5px;
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.pc .footer__left__info__tel:last-child {
  margin-right: 0;
}

.pc .footer__left__info__tel:first-child {
  padding-right: 5px;
}

.pc .footer__left__info__tel:last-child {
  padding-left: 5px;
}

.pc .footer__left__txt {
  margin: 0;
  font-size: 0.75rem;
  color: #646464;
}

.pc .footer__right {
  width: 620px;
  float: right;
}

.pc .footer__right__txt {
  color: #666666;
  font-size: 0.625rem;
}

.pc .footer__search {
  display: table;
  width: 100%;
  border-bottom: 1px solid #666666;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.pc .footer__search__item {
  display: table-row;
  line-height: 1.8;
}

.pc .footer__search__heading {
  display: table-cell;
  color: #666666;
  font-size: 0.75rem;
  width: 117px;
}

.pc .footer__search__list {
  display: table-cell;
}

.pc .footer__search__list__item {
  display: inline-block;
  font-size: 0.75rem;
}

.pc .footer__search__list__item a {
  color: #666666;
}

.pc .footer__bottom {
  background: #c70000;
}

.pc .footer__bottom__container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 20px;
}

.pc .footer__bottom__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .footer__bottom__container::after {
  clear: both;
  content: "";
  display: block;
}

.pc .footer__bottom__navi {
  text-align: center;
  font-size: 0;
  margin-bottom: 35px;
}

.pc .footer__bottom__navi__item {
  display: inline-block;
  font-weight: bold;
  font-size: 0.875rem;
}

.pc .footer__bottom__navi__item:after {
  content: "|";
  color: #fff;
  display: inline-block;
  margin: 0 5px;
}

.pc .footer__bottom__navi__item:last-child:after {
  display: none;
}

.pc .footer__bottom__navi__item a {
  color: #fff;
}

.pc .footer__bottom .copy {
  display: block;
  text-align: center;
  color: #fff;
  font-size: .75rem;
}

.pc .add-fixed-koukoku {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9998;
  padding: 7px 0px;
}

.pc .add-fixed-koukoku__list {
  text-align: center;
}

.pc .add-fixed-koukoku__list::after {
  clear: both;
  content: "";
  display: block;
}

.pc .add-fixed-koukoku__list__item:first-child {
  width: 40%;
  display: inline-block;
}

.pc .add-fixed-koukoku__close {
  width: 50px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 9999;
  width: 30px;
}

.pc .add-fixed-koukoku img {
  display: block;
  width: 780px;
  margin: 0 auto;
}

.pc .sidebar {
  position: relative;
  z-index: 3333;
  margin-top: -4px;
  width: 260px;
  float: left;
  background: #dee1e8;
  margin-left: 20px;
  padding: 17px 10px;
  height: 100%;
}

.pc .sidebar__menu {
  background: #fff;
  padding-bottom: 20px;
}

.pc .sidebar__menu__heading {
  position: relative;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.3;
}

.pc .sidebar__menu__heading:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  width: 0;
  margin: 0 auto;
  border: 25px solid transparent;
  border-top: 10px solid transparent;
  border-top-color: #ccc;
  border-bottom-width: 0;
}

.pc .sidebar__menu__heading__icon {
  display: inline-block;
  padding: 15px 0;
}

.pc .sidebar__menu__heading a {
  color: #fff;
  display: block;
}

.pc .sidebar__menu__list {
  padding: 10px 20px 15px 20px;
}

.pc .sidebar__menu__list__item {
  border-bottom: 1px dotted #c7c7c7;
}

.pc .sidebar__menu__list__item a {
  display: block;
  padding: 5px 0;
}

.pc .sidebar__menu__list__item label {
  font-weight: normal;
  line-height: 1.8;
  padding-top: 5px;
}

.pc .sidebar__menu__area {
  padding: 0 20px;
}

.pc .sidebar__menu__area__heading {
  font-size: .875rem;
  margin-bottom: 5px;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
}

.pc .sidebar__menu__area__heading.open:before {
  content: "\f0de";
  position: relative;
  top: 4px;
}

.pc .sidebar__menu__area__heading:before {
  content: "\f0dd";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1rem;
  margin-right: 6px;
  position: relative;
  bottom: 3px;
}

.pc .sidebar__menu__area__heading.tintai {
  background: #006EA7;
  color: #fff;
}

.pc .sidebar__menu__area__heading.buy {
  background: #FF5E5E;
  color: #fff;
}

.pc .sidebar__menu__area__heading.tochi {
  background: #5E951D;
  color: #fff;
}

.pc .sidebar__menu__area__list {
  display: none;
}

.pc .sidebar__menu__area__list.tintai .sidebar__menu__area__list__item a:before {
  color: #006EA7;
}

.pc .sidebar__menu__area__list.buy .sidebar__menu__area__list__item a:before {
  color: #FF5E5E;
}

.pc .sidebar__menu__area__list.tochi .sidebar__menu__area__list__item a:before {
  color: #5E951D;
}

.pc .sidebar__menu__area__list__item {
  background: #fff;
  margin-bottom: 3px;
  border-bottom: 1px dotted #c7c7c7;
}

.pc .sidebar__menu__area__list__item:last-child {
  margin-bottom: 0;
}

.pc .sidebar__menu__area__list__item a {
  color: #000;
  display: block;
  padding: 5px 5px;
  font-size: 0.813rem;
}

.pc .sidebar__menu__area__list__item a:before {
  content: "\f041";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1rem;
  margin-right: 6px;
}

.pc .sidebar__menu--tintai .sidebar__menu__heading {
  background: #006ea7;
}

.pc .sidebar__menu--tintai .sidebar__menu__heading:after {
  border-top-color: #006ea7;
}

.pc .sidebar__menu--tintai .sidebar__menu__heading .sidebar__menu__heading__icon {
  background: url(./assets/img/icon_sidebar_tintai.png) no-repeat left center;
  background-size: 34px 33px;
  padding-left: 42px;
}

.pc .sidebar__menu--tintai .sidebar__menu__list__item a {
  background: url(./assets/img/icon_tintai.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 28px;
}

.pc .sidebar__menu--uri .sidebar__menu__heading {
  background: #ff5e5e;
}

.pc .sidebar__menu--uri .sidebar__menu__heading:after {
  border-top-color: #ff5e5e;
}

.pc .sidebar__menu--uri .sidebar__menu__heading .sidebar__menu__heading__icon {
  background: url(./assets/img/icon_sidebar_uri.png) no-repeat left center;
  background-size: 28px 33px;
  padding-left: 36px;
}

.pc .sidebar__menu--uri .sidebar__menu__list__item a {
  background: url(./assets/img/icon_uri.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 28px;
}

.pc .sidebar__menu--bunjyou .sidebar__menu__heading {
  background: #5e951d;
}

.pc .sidebar__menu--bunjyou .sidebar__menu__heading:after {
  border-top-color: #5e951d;
}

.pc .sidebar__menu--bunjyou .sidebar__menu__heading .sidebar__menu__heading__icon {
  background: url(./assets/img/icon_sidebar_bunjyou.png) no-repeat left center;
  background-size: 30px 30px;
  padding-left: 38px;
}

.pc .sidebar__menu--bunjyou .sidebar__menu__list__item a {
  background: url(./assets/img/icon_bunjyou.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 28px;
}

.pc .sidebar__menu--search .sidebar__menu__heading {
  background: #ff7e00;
}

.pc .sidebar__menu--search .sidebar__menu__heading:after {
  border-top-color: #ff7e00;
}

.pc .sidebar__menu--search .sidebar__menu__heading .sidebar__menu__heading__icon {
  background: url(./assets/img/icon_sidebar_search.png) no-repeat left center;
  background-size: 30px 30px;
  padding-left: 38px;
}

.pc .sidebar__menu__select {
  padding: 0 20px;
  margin-bottom: 20px;
  font-size: .75rem;
}

.pc .sidebar__menu__select__item {
  margin: 0;
  padding: 0;
  font-size: .75rem;
  width: 44%;
  display: inline-block;
}

.pc .sidebar__menu__word {
  margin-top: 20px;
  padding: 0 20px;
}

.pc .sidebar__pr {
  margin-top: 20px;
}

.pc .sidebar__pr__title {
  text-align: center;
  margin: 0 0 15px 0;
  line-height: 1.3;
}

.pc .sidebar__pr__item {
  background: #fff;
  padding: 10px;
}

.pc .sidebar__pr__item__thumb {
  margin-bottom: 10px;
  height: 170px;
  overflow: hidden;
}

.pc .sidebar__pr__item__thumb img {
  width: 100%;
  height: auto;
}

.pc .sidebar__pr__item__name {
  font-size: .875rem;
  color: #3452a9;
  line-height: 1.3;
}

.pc .sidebar__pr__item__name a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .sidebar__pr__item__price {
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-bottom: 5px;
}

.pc .sidebar__pr__item__floor {
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-bottom: 5px;
}

.pc .sidebar__pr__item__address {
  color: #666666;
  font-size: .75rem;
}

.pc .sidebar__pr__item__access {
  color: #666666;
  font-size: .75rem;
}

.pc .sidebar__pr__item__years {
  color: #666666;
  font-size: .75rem;
}

.pc .sidebar__pr__bunjo {
  padding: 5px;
  background-color: #fff;
}

.pc .sidebar__pr__bunjo img {
  border: 1px solid #000;
}

.pc .sidebar__pr__kominka {
  padding: 0px 5px;
  background-color: #fff;
}

.pc .sidebar__pr__kominka img {
  border: 1px solid #000;
}

.pc .sidebar__pr .slick-prev {
  left: 0;
  z-index: 2222;
}

.pc .sidebar__pr .slick-prev:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: url(./assets/img/slider_icon_left.png) no-repeat;
  background-size: auto auto;
  background-size: cover;
}

.pc .sidebar__pr .slick-next {
  right: 0;
  z-index: 2222;
}

.pc .sidebar__pr .slick-next:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: url(./assets/img/slider_icon_right.png) no-repeat;
  background-size: auto auto;
  background-size: cover;
}

.pc .sidebar__info {
  margin-top: 20px;
}

.pc .sidebar__info__title {
  text-align: center;
  margin: 0 0 15px 0;
  line-height: 1.3;
}

.pc .sidebar__info__sub-title {
  border-left: 4px solid #0068b7;
  border-bottom: 1px solid #0068b7;
  font-size: 0.8125rem;
  padding: 2px 0 4px 8px;
}

.pc .sidebar__info__container {
  background: #fff;
  padding: 15px 10px;
}

.pc .sidebar__info__image {
  margin-bottom: 5px;
}

.pc .sidebar__info__txt {
  font-size: .75rem;
  margin-bottom: 30px;
  text-align: justify;
}

.pc .sidebar__info .btn__simulation {
  width: 208px;
  font-size: .75rem;
  margin-bottom: 10px;
}

.pc .sidebar__reform {
  margin-top: 20px;
  min-height: 150px;
}

.pc .sidebar__reform__title {
  text-align: center;
  margin: 0 0 15px 0;
  line-height: 1.3;
}

.pc .sidebar__reform__sub-title {
  border-left: 4px solid #0068b7;
  border-bottom: 1px solid #0068b7;
  font-size: 0.8125rem;
  padding: 2px 0 4px 8px;
}

.pc .sidebar__reform__container {
  background: #fff;
  padding: 15px 10px;
}

.pc .sidebar__reform__txt {
  font-size: .75rem;
  margin-bottom: 30px;
}

.pc .sidebar__reform .btn__simulation {
  width: 208px;
  font-size: .75rem;
  margin-bottom: 10px;
}

.pc .sidebar__news {
  margin-top: 20px;
  min-height: 150px;
  position: relative;
}

.pc .sidebar__news__title {
  text-align: center;
  margin: 0 0 15px 0;
  line-height: 1.3;
}

.pc .sidebar__news__sub-title {
  border-left: 4px solid #0068b7;
  border-bottom: 1px solid #0068b7;
  font-size: 0.8125rem;
  padding: 2px 0 4px 8px;
}

.pc .sidebar__news__container {
  background: #fff;
  padding: 15px 10px;
}

.pc .sidebar__news__txt {
  font-size: .75rem;
  margin-bottom: 30px;
}

.pc .sidebar__news__link {
  text-align: right;
  margin-bottom: 10px;
}

.pc .sidebar__news__link a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .sidebar__news__list__item {
  margin-bottom: 5px;
  border-bottom: 1px dotted #7f7f7f;
}

.pc .slider {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  background: #7B7B7B;
  margin-top: -3px;
}

.pc .slider::after {
  clear: both;
  content: "";
  display: block;
}

.pc .slider:before {
  content: "";
  display: block;
  width: 100%;
  height: 20px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.pc .slider__list {
  margin: 0 10px;
  padding: 0px;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.pc .slider__list__item {
  text-align: center;
}

.pc .slider .slick-prev, .pc .slider .slick-next {
  height: 71px;
  width: 71px;
  z-index: 2222;
}

.pc .slider .slick-prev {
  left: 5px;
}

.pc .slider .slick-prev:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 71px;
  height: 71px;
  background: url(./assets/img/slider_icon_left.png) no-repeat;
  background-size: cover;
}

.pc .slider .slick-next {
  right: 5px;
}

.pc .slider .slick-next:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 71px;
  height: 71px;
  background: url(./assets/img/slider_icon_right.png) no-repeat;
  background-size: cover;
}

.pc .top .thumb-slider {
  text-align: center;
  margin: 0 20px;
  padding: 20px 10px;
}

.pc .top .thumb-slider__item {
  position: relative;
  background: #fff;
  text-align: left;
  border-right: 1px solid #f0f0f0;
}

.pc .top .thumb-slider__item__thumb {
  text-align: center;
  height: 165px;
  overflow: hidden;
}

.pc .top .thumb-slider__item__thumb img {
  width: 100%;
}

.pc .top .thumb-slider__item__detail {
  padding: 10px 15px 15px 15px;
}

.pc .top .thumb-slider__item__title {
  font-size: .875rem;
  line-height: 1.3;
  margin-bottom: 5px;
}

.pc .top .thumb-slider__item__title a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .top .thumb-slider__item__price {
  font-size: .75rem;
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-right: 5px;
}

.pc .top .thumb-slider__item__floor {
  position: absolute;
  left: 0;
  top: 0;
  background: #ca2c2c;
  font-size: .75rem;
  padding: 2px 5px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
}

.pc .top .thumb-slider__item__address {
  font-size: .675rem;
  color: #666666;
}

.pc .top .thumb-slider .slick-prev, .pc .top .thumb-slider .slick-next {
  height: 71px;
  width: 71px;
  z-index: 2222;
}

.pc .top .thumb-slider .slick-prev {
  left: -22px;
}

.pc .top .thumb-slider .slick-prev:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: url(./assets/img/slider_icon_left.png) no-repeat;
  background-size: cover;
}

.pc .top .thumb-slider .slick-next {
  right: -20px;
}

.pc .top .thumb-slider .slick-next:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: url(./assets/img/slider_icon_right.png) no-repeat;
  background-size: cover;
}

.pc .top .home-search {
  margin: 0 20px;
}

.pc .top .home-search__container {
  padding: 20px 0;
}

.pc .top .home-search__form {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.pc .top .home-search__form::after {
  clear: both;
  content: "";
  display: block;
}

.pc .top .home-search__form__section {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  margin-bottom: 20px;
}

.pc .top .home-search__form__section:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__section:nth-child(2n) {
  margin-right: 0;
}

.pc .top .home-search__form__section:nth-child(2n+1) {
  clear: left;
}

.pc .top .home-search__form__section::after {
  clear: both;
  content: "";
  display: block;
}

.pc .top .home-search__form__section ul {
  line-height: 2.2;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 0 5px;
}

.pc .top .home-search__form__section .area-search {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 57.69445%;
}

.pc .top .home-search__form__section .area-search:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__section .madori-search {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 40.77223%;
  margin-right: 0;
}

.pc .top .home-search__form__section .madori-search:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__section .price-select {
  clear: both;
}

.pc .top .home-search__form__section .price-select::after {
  clear: both;
  content: "";
  display: block;
}

.pc .top .home-search__form__section .price-select select {
  display: inline-block;
  width: 45%;
  font-size: 14px;
}

.pc .top .home-search__form__section label {
  font-weight: normal;
}

.pc .top .home-search__form__section__right {
  padding-left: 32px;
}

.pc .top .home-search__form__section__right__list::after {
  clear: both;
  content: "";
  display: block;
}

.pc .top .home-search__form__section__right__list li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.pc .top .home-search__form__section__right__list li:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__section__right__list li:nth-child(2n) {
  margin-right: 0;
}

.pc .top .home-search__form__section__right__list li:nth-child(2n+1) {
  clear: left;
}

.pc .top .home-search__form__txt-search {
  margin-top: 30px;
  padding: 0 60px;
  border-top: 1px solid #999;
  padding-top: 20px;
}

.pc .top .home-search__form__txt-search::after {
  clear: both;
  content: "";
  display: block;
}

.pc .top .home-search__form__txt-search__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 74.61667%;
}

.pc .top .home-search__form__txt-search__left:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__txt-search__left input {
  width: 100%;
}

.pc .top .home-search__form__txt-search__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 23.85001%;
}

.pc .top .home-search__form__txt-search__right:last-child {
  margin-right: 0;
}

.pc .top .home-search__form__txt-search__right .btn__red {
  width: 100%;
  padding: 12px 0;
}

.pc .top .home-search__form .btn__red {
  clear: both;
}

.pc .top .top-bunjou-link {
  text-align: center;
  padding: 20px 20px 0 20px;
  margin-bottom: -10px;
}

.pc .top .top-bunjou-link img {
  border: 1px solid #fff;
}

.pc .top .kominka-link {
  text-align: center;
  padding: 20px 20px 0 20px;
  margin-bottom: -10px;
}

.pc .top .kominka-link img {
  border: 1px solid #fff;
}

.pc .detail {
  position: relative;
  /* =======================================
      ClearFixElements
  ======================================= */
}

.pc .detail:after {
  width: 700px;
  height: auto;
  position: absolute;
  border-right: 10px solid #9acedb;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dff2fa 1%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dff2fa 1%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dff2fa 1%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 */
}

.pc .detail .detail__print {
  position: absolute;
  right: 6px;
  top: 6px;
  background: #ca2c2c;
  display: inline-block;
  border-radius: 6px;
  border-bottom: 1px solid #a6a6a6;
}

.pc .detail .detail__print__normal {
  position: static;
  margin: 10px 0;
}

.pc .detail .detail__print a {
  padding: 10px 15px 10px 45px;
  display: block;
  color: #fff;
  font-size: .75rem;
  background: url(./assets/img/icon_print.png) no-repeat left 15px center;
  background-size: 23px 18px;
}

.pc .detail__top-content__title {
  font-size: 1.5rem;
}

.pc .detail__top-content__title:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.pc .detail__top-content__title__tintai:before {
  background: url(./assets/img/icon_tintai.png) no-repeat center center;
  background-size: cover;
}

.pc .detail__top-content__title__uri:before {
  background: url(./assets/img/icon_uri.png) no-repeat center center;
  background-size: cover;
}

.pc .detail__top-content__title__bunjyou:before {
  background: url(./assets/img/icon_bunjyou.png) no-repeat center center;
  background-size: cover;
}

.pc .detail__top-content__box {
  position: relative;
  background: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.pc .detail__top-content__box .detail__num {
  position: absolute;
  right: 5px;
  top: -47px;
  background: url(./assets/img/detail_num_bg.png) no-repeat;
  width: 189px;
  height: 48px;
}

.pc .detail__top-content__box .detail__num__item {
  margin: 27px 0 0 56px;
}

.pc .detail__top-content__box .detail__num__item dt {
  display: inline-block;
  font-weight: normal;
  font-size: 0.75rem;
}

.pc .detail__top-content__box .detail__num__item dd {
  display: inline-block;
  font-size: 0.75rem;
}

.pc .detail__top-content__box__top {
  padding-bottom: 13px;
  margin-bottom: 13px;
  border-bottom: 1px solid #bfbfbf;
}

.pc .detail__top-content__box__top::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail__top-content__box__top__left {
  display: table-cell;
  font-size: 0;
  border-right: 1px solid #ccc;
  padding: 0 20px 5px 0;
}

.pc .detail__top-content__box__top__left .rent {
  display: inline-block;
}

.pc .detail__top-content__box__top__left .rent dt {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
  margin-right: 10px;
}

.pc .detail__top-content__box__top__left .rent dd {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
}

.pc .detail__top-content__box__top__left .rent dd .strong {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: bold;
  color: #ff6464;
  display: inline-block;
  margin-right: 5px;
}

.pc .detail__top-content__box__top__left .service-fee {
  font-size: .75rem;
}

.pc .detail__top-content__box__top__left .service-fee dt, .pc .detail__top-content__box__top__left .service-fee dd {
  font-weight: normal;
  display: inline;
}

.pc .detail__top-content__box__top__right {
  font-size: 0;
  padding: 0 0 5px 20px;
  display: table-cell;
}

.pc .detail__top-content__box__top__right .floor {
  display: inline-block;
}

.pc .detail__top-content__box__top__right .floor dt {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
  margin-right: 10px;
}

.pc .detail__top-content__box__top__right .floor dd {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
}

.pc .detail__top-content__box__top__right .floor dd .strong {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ff6464;
  display: inline-block;
  margin-right: 5px;
}

.pc .detail__top-content__box__top__right .area-size {
  font-size: .75rem;
}

.pc .detail__top-content__box__top__right .area-size dt, .pc .detail__top-content__box__top__right .area-size dd {
  font-weight: normal;
  display: inline;
}

.pc .detail__top-content__box__bottom .other-info {
  margin-bottom: 15px;
}

.pc .detail__top-content__box__bottom .other-info::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail__top-content__box__bottom .other-info__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  margin-bottom: 1%;
}

.pc .detail__top-content__box__bottom .other-info__item:last-child {
  margin-right: 0;
}

.pc .detail__top-content__box__bottom .other-info__item:nth-child(2n) {
  margin-right: 0;
}

.pc .detail__top-content__box__bottom .other-info__item:nth-child(2n+1) {
  clear: left;
}

.pc .detail__top-content__box__bottom .other-info__item dt {
  color: #fff;
  background: #ff6464;
  font-weight: normal;
  font-size: .75rem;
  min-width: 70px;
  text-align: center;
  padding: 0 5px;
  display: inline-block;
  margin-right: 5px;
}

.pc .detail__top-content__box__bottom .other-info__item dd {
  display: inline-block;
  font-size: .75rem;
}

.pc .detail__top-content__box__bottom .point dt {
  position: relative;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: bold;
  border: 1px solid #ff6464;
  border-radius: 10px;
  padding: 0 10px 0 30px;
  margin-right: 10px;
}

.pc .detail__top-content__box__bottom .point dt:before {
  position: absolute;
  left: 0;
  top: -6px;
  content: "";
  display: inline-block;
  background: url(./assets/img/icon_point.png) no-repeat center center;
  background-size: cover;
  width: 25px;
  height: 28px;
}

.pc .detail__top-content__box__bottom .point dd {
  display: inline-block;
  font-size: .75rem;
}

.pc .detail .viewer {
  width: 650px;
  margin: 0 auto;
  min-height: 300px;
}

.pc .detail .viewer::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail .viewer div {
  position: relative;
  width: 365px;
  min-height: 300px;
  float: left;
}

.pc .detail .viewer img {
  line-height: 1;
  vertical-align: top;
}

.pc .detail .viewer__main img {
  background: #fff;
  padding: 2px;
}

.pc .detail .viewer div img {
  width: auto;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  line-height: 1;
  vertical-align: top;
}

.pc .detail .viewer ul {
  width: 270px;
  float: right;
}

.pc .detail .viewer ul::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail .viewer ul li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
  cursor: pointer;
  height: 66px;
  padding: 2px;
  margin-bottom: 3px;
  background: #fff;
  padding: 2px;
  overflow: hidden;
}

.pc .detail .viewer ul li:last-child {
  margin-right: 0;
}

.pc .detail .viewer ul li:nth-child(3n) {
  margin-right: 0;
}

.pc .detail .viewer ul li:nth-child(3n+1) {
  clear: left;
}

.pc .detail .viewer .active {
  filter: alpha(opacity=100) !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
}

.pc .detail .viewer ul:after {
  content: ".";
  height: 0;
  clear: both;
  display: block;
  visibility: hidden;
}

.pc .detail .viewer ul {
  display: inline-block;
  overflow: hidden;
}

.pc .detail .more-info__box {
  padding: 20px 10px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 35px;
}

.pc .detail .more-info__title {
  position: relative;
  background: #ffd7d7;
  font-size: 1.125rem;
  line-height: 1.3;
  padding: 5px 15px 5px 55px;
  border-radius: 10px 0 0 10px;
  margin-bottom: 40px;
}

.pc .detail .more-info__title:before {
  position: absolute;
  left: -2px;
  top: -5px;
  content: "";
  display: inline-block;
  background: url(./assets/img/detail_more_info_icon.png) no-repeat;
  width: 46px;
  height: 46px;
  background-size: cover;
}

.pc .detail .more-info__col-02 {
  margin-bottom: 20px;
}

.pc .detail .more-info__col-02::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail .more-info__col-02__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.pc .detail .more-info__col-02__left:last-child {
  margin-right: 0;
}

.pc .detail .more-info__col-02__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.pc .detail .more-info__col-02__right:last-child {
  margin-right: 0;
}

.pc .detail .more-info__col-02__right table {
  width: 100%;
  line-height: 1.3;
  margin: 0;
}

.pc .detail .more-info__col-02__right table th {
  background: #e3eef4;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  width: 120px;
  padding: 10px;
  font-weight: normal;
}

.pc .detail .more-info__col-02__right table td {
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  padding: 10px;
}

.pc .detail .more-info__col-01 table {
  width: 100%;
  line-height: 1.3;
  margin: 0 0 10px 0;
}

.pc .detail .more-info__col-01 table th {
  background: #e3eef4;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  width: 100px;
  padding: 10px;
  font-weight: normal;
}

.pc .detail .more-info__col-01 table td {
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  padding: 10px;
}

.pc .detail .more-info__col-01__notices {
  margin-bottom: 15px;
}

.pc .detail .more-info__col-01__notices__item {
  display: table;
  width: 100%;
}

.pc .detail .more-info__col-01__notices__item dt {
  width: 90px;
  display: table-cell;
  border: 1px solid #dedede;
  vertical-align: middle;
  padding: 15px;
  background: #ffe3e3;
  color: #ff0000;
  font-weight: normal;
  border-right: none;
}

.pc .detail .more-info__col-01__notices__item dd {
  display: table-cell;
  border: 1px solid #dedede;
  vertical-align: middle;
  padding: 15px;
  line-height: 1.75;
}

.pc .detail .more-info__col-01__map {
  margin-bottom: 5px;
}

.pc .detail .more-info__col-01__map iframe {
  width: 100%;
  height: 290px;
}

.pc .detail .more-info__col-01__caution {
  margin: 0;
}

.pc .detail__bookmark {
  margin-bottom: 10px;
}

.pc .detail__bookmark__container {
  background: #FF7E00;
  display: inline-block;
}

.pc .detail__bookmark__container .wpfp-span {
  line-height: 1.3;
  display: inline-block;
  color: #fff;
  font-size: .875rem;
  width: 216px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.pc .detail__bookmark__container .wpfp-span a {
  color: #fff;
  display: block;
}

.pc .detail__bookmark__container .wpfp-span a:before {
  content: "\f02e";
  font-family: FontAwesome;
  display: inline-block;
  font-size: 1rem;
  margin-right: 10px;
  color: #fff;
}

.pc .detail .widget_fudo_syousai {
  background: #fff;
  margin-top: 20px;
  padding: 20px 15px 15px 15px;
  border-radius: 8px;
}

.pc .detail .widget_fudo_syousai h3 {
  margin: 0 0 20px;
  border-left: 4px solid #0068b7;
  border-bottom: 1px solid #0068b7;
  font-size: 1.125rem;
  padding: 3px 0 5px 10px;
}

.pc .detail .syousai-content::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail .syousai-content .syousai-content-li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 23.85001%;
  border: 1px solid #f2f2f2;
  position: relative;
  background: #fff;
  padding: 10px;
  margin-bottom: 2%;
  font-size: .75rem;
}

.pc .detail .syousai-content .syousai-content-li:last-child {
  margin-right: 0;
}

.pc .detail .syousai-content .syousai-content-li:nth-child(4n) {
  margin-right: 0;
}

.pc .detail .syousai-content .syousai-content-li:nth-child(4n+1) {
  clear: left;
}

.pc .detail .syousai-content .syousai-content-li a {
  display: block;
}

.pc .detail .syousai-content .syousai-content-li a img {
  width: 100%;
}

.pc .detail .syousai-content .syousai-content-li .new_mark {
  background: #ca2c2c;
  color: #fff;
  font-size: .75rem;
  font-weight: bold;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 46px;
  padding: 3px 0;
  line-height: 1;
  text-align: center;
}

.pc .detail .syousai-content .syousai-content-li .top_title {
  font-weight: bold;
  color: #3452a9;
}

.pc .detail .syousai-content .syousai-content-li .top_price {
  color: #ff6464;
  font-weight: bold;
}

.pc .detail .syousai-content .syousai-content-li .top_madori {
  color: #ff6464;
  font-weight: bold;
}

.pc .detail .syousai-content .syousai-content-li .top_menseki {
  color: #ff6464;
  font-weight: bold;
}

.pc .detail .syousai-content .syousai-content-li .top_shozaichi {
  color: #666666;
  line-height: 1.5;
}

.pc .detail .akijouhou {
  margin-bottom: 30px;
}

.pc .detail__pet {
  display: inline-block;
  background: url(./assets/img/icon_pet.png) no-repeat;
  background-size: contain;
  padding-left: 20px;
  margin-right: 10px;
}

.pc .detail__pet__inu {
  display: inline-block;
  background: url(./assets/img/icon_dog.png) no-repeat;
  background-size: contain;
  padding-left: 20px;
  margin-right: 10px;
}

.pc .detail__pet__neko {
  display: inline-block;
  background: url(./assets/img/icon_cat.png) no-repeat;
  background-size: contain;
  padding-left: 20px;
  margin-right: 10px;
}

.pc .detail .tochikau-images::after {
  clear: both;
  content: "";
  display: block;
}

.pc .detail .tochikau-images__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  background: #fff;
  padding: 2px;
  margin-bottom: 1%;
}

.pc .detail .tochikau-images__item:last-child {
  margin-right: 0;
}

.pc .detail .tochikau-images__item:nth-child(2n) {
  margin-right: 0;
}

.pc .detail .tochikau-images__item:nth-child(2n+1) {
  clear: left;
}

.pc .detail .tochikau-images__item img {
  vertical-align: top;
  width: 100%;
  height: auto;
}

.pc .archive-fudo {
  /* ---------- bookmark ---------- */
}

.pc .archive-fudo::after {
  clear: both;
  content: "";
  display: block;
}

.pc .archive-fudo #list_simplepage {
  padding: 0;
}

.pc .archive-fudo #list_simplepage::after {
  clear: both;
  content: "";
  display: block;
}

.pc .archive-fudo .list_simple_box {
  width: 280px;
  float: left;
  clear: none;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 12px 30px 12px;
}

.pc .archive-fudo .list_simple_box .list_picsam {
  text-align: center;
  margin-bottom: 10px;
}

.pc .archive-fudo .list_simple_box .list_picsam a:first-child {
  display: none;
}

.pc .archive-fudo .list_simple_box .list_picsam img {
  width: 100%;
  height: auto;
  border: 2px solid #e9e9e9;
}

.pc .archive-fudo .list_simple_box .list_picsam_img {
  width: 280px;
  max-height: 280px;
  height: auto;
  overflow: hidden;
  margin-bottom: 15px;
  max-height: 210px;
  overflow: hidden;
}

.pc .archive-fudo .list_simple_box .entry-title {
  margin: 0 0 5px 0;
  font-size: 1rem;
  line-height: 1.3;
}

.pc .archive-fudo .list_simple_box .entry-title a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .archive-fudo .list_simple_box .entry-num {
  font-size: 0.625rem;
  margin: 0 0 2px 0;
  color: #666666;
}

.pc .archive-fudo .list_simple_box .entry-excerpt {
  text-align: left;
  font-size: .75rem;
  font-weight: bold;
  background: url(./assets/img/icon_point.png) no-repeat left top;
  background-size: 15px 16px;
  padding: 0 0 0 20px;
  color: #333;
  line-height: 1.3;
}

.pc .archive-fudo .list_simple_box .list_detail table {
  width: 100%;
  margin: 0;
  font-size: 0.75rem;
  background: #f9f9f9;
}

.pc .archive-fudo .list_simple_box .list_detail table th {
  padding: 0;
  font-weight: normal;
  border: none;
}

.pc .archive-fudo .list_simple_box .list_detail table td {
  border: none;
  padding: 0;
}

.pc .archive-fudo .list_simple_box .list_detail table td::after {
  clear: both;
  content: "";
  display: block;
}

.pc .archive-fudo .list_simple_box .list_detail table td dt {
  display: block;
  width: 22%;
  font-weight: normal;
  background: #ff6464;
  color: #fff;
  font-size: 0.6875rem;
  text-align: center;
  float: left;
  margin-bottom: 1px;
  padding: 2px;
}

.pc .archive-fudo .list_simple_box .list_detail table td dd {
  display: block;
  width: 78%;
  font-size: 0.6875rem;
  padding: 2px 0 2px 10px;
  float: left;
  margin-bottom: 1px;
}

.pc .archive-fudo .color-red {
  color: #ff6464;
  font-weight: bold;
  font-size: 1rem;
}

.pc .archive-fudo.bookmark .wpfp-link {
  background: #FF7E00;
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
}

.pc .archive-fudo.bookmark .wpfp-link.remove-parent {
  background: #FF7E00;
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
  margin-top: 10px;
}

.pc .archive-fudo.bookmark .list_simple_box {
  border-bottom: none;
}

.pc .important-items {
  margin-bottom: 5px;
}

.pc .important-items dl {
  display: inline-block;
  margin-right: 10px;
  font-size: 0;
}

.pc .important-items dl:last-child {
  margin-right: 0;
}

.pc .important-items dl dt {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: normal;
  margin-right: 5px;
}

.pc .important-items dl dd {
  display: inline-block;
  font-size: 1rem;
  color: #ff6464;
  font-weight: bold;
}

.pc .list_price_others {
  display: table;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  display: none;
}

.pc .list_price_others::after {
  clear: both;
  content: "";
  display: block;
}

.pc .list_price_others dt {
  display: table-cell;
  border: 1px solid #ddd;
  background: #FAFCFD;
}

.pc .list_price_others dd {
  display: table-cell;
  border: 1px solid #ddd;
}

.pc .list_price_others dd.detail-link {
  float: right;
  font-weight: bold;
}

.pc .list_price_others dd.detail-link a {
  color: #3452a9;
  text-decoration: underline;
}

.pc .navigation {
  margin: 15px 0;
  clear: both;
}

.pc .navigation a {
  line-height: 1;
  display: inline-block;
  border: 1px solid #c7c7c7;
  padding: 10px;
  margin: 0 3px;
}

.pc .navigation a img {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}

.pc .navigation .nav-previous {
  margin-bottom: 15px;
}

.pc .navigation .nav-next {
  font-size: 1rem;
  font-weight: bold;
}

.pc .navigation .nav-next a {
  line-height: 1;
  display: inline-block;
  border: 1px solid #c7c7c7;
  padding: 10px 12px;
  margin: 0 3px;
  font-size: 0.875rem;
  font-weight: normal;
}

.pc .navigation .nav-next b {
  font-size: 0.875rem;
  line-height: 1;
  display: inline-block;
  border: 1px solid #000;
  padding: 10px 12px;
  background: #000;
  color: #fff;
  margin: 0 3px;
  font-weight: normal;
}

.pc .company .greeting {
  margin-bottom: 45px;
}

.pc .company .greeting__name {
  text-align: right;
  margin-bottom: 30px;
}

.pc .company .greeting__photo {
  padding: 0 30px;
}

.pc .company .greeting__photo::after {
  clear: both;
  content: "";
  display: block;
}

.pc .company .greeting__photo__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.pc .company .greeting__photo__item:last-child {
  margin-right: 0;
}

.pc .company .info__table {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.pc .company .info__table::after {
  clear: both;
  content: "";
  display: block;
}

.pc .company .info__table tr th {
  padding: 10px;
  width: 140px;
  background: #fafcfd;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  text-align: center;
  vertical-align: middle;
}

.pc .company .info__table tr td {
  padding: 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.pc .contact__table {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.pc .contact__table::after {
  clear: both;
  content: "";
  display: block;
}

.pc .contact__table tr th {
  padding: 10px;
  width: 180px;
  background: #fafcfd;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  text-align: left;
  vertical-align: middle;
}

.pc .contact__table tr th::after {
  clear: both;
  content: "";
  display: block;
}

.pc .contact__table tr th .required {
  float: right;
  color: #fff;
  background: #ff7e00;
  padding: 2px 10px;
  display: inline-block;
  font-size: .75rem;
}

.pc .contact__table tr td {
  padding: 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.pc .contact__table tr td input {
  margin: 0;
}

.pc .contact__table tr select {
  margin: 0;
}

.pc .contact__table tr textarea {
  margin: 0;
}

.pc .contact input[type="submit"] {
  display: block;
  width: 180px;
  background: #ff7e00;
  color: #fff;
  margin: 0 auto;
}

.pc .single-post img {
  height: auto;
}

.pc .single-post__time {
  font-size: 1rem;
  margin-bottom: 20px;
}

.pc .single-post__thumb {
  margin-bottom: 15px;
}

.pc .single-post__content {
  margin-bottom: 45px;
  font-size: 0.875rem;
}

.pc .single-post__content p {
  margin-bottom: 1.25rem;
  line-height: 1.8;
}

.pc .single-post__content img {
  display: inline-block;
  margin: 5px 0;
}

.pc .single-post__navi {
  text-align: center;
}

.pc .single-post__navi a {
  display: inline-block;
  margin: 10px;
}

.pc .archive__list__item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #7f7f7f;
}

.pc .archive__list__item::after {
  clear: both;
  content: "";
  display: block;
}

.pc .archive__list__item__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 23.85001%;
}

.pc .archive__list__item__left:last-child {
  margin-right: 0;
}

.pc .archive__list__item__left img {
  height: auto;
  vertical-align: top;
}

.pc .archive__list__item__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 74.61667%;
  padding-top: 5px;
}

.pc .archive__list__item__right:last-child {
  margin-right: 0;
}

.pc .archive__list__item__time {
  font-size: .75rem;
  margin-bottom: 5px;
}

.pc .archive__list__item__heading {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.3;
}

.pc .archive__list__item__heading a {
  color: #3452a9;
}

.pc .archive__paging {
  text-align: center;
  font-size: 0;
  margin-top: 45px;
}

.pc .archive__paging .page-numbers {
  font-size: .875rem;
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  line-height: 1;
  border: 1px solid #000;
  color: #000;
}

.pc .archive__paging .page-numbers.current {
  color: #fff;
  background: #000;
}

.pc .empty {
  width: 100%;
  max-width: 100%;
}

.pc .empty .breadcrumb {
  padding-right: 0px;
}

.pc .empty .lower-gradient__wrap {
  border-right: 0px;
}

.pc .empty-title--h1 {
  margin: 30px 0 10px;
  padding: 0;
  font-size: 28px;
  line-height: 1.6;
  font-weight: 600;
  text-align: center;
  color: #104e6f;
}

.pc .empty-img {
  text-align: center;
}

.pc .empty-img img {
  max-width: 750px;
}

.pc .empty-wrap {
  margin-bottom: 60px;
}

.pc .empty-wrap-title--h3 {
  margin: 20px 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  color: #1aa8b5;
  line-height: 1.6;
  text-align: left;
  padding: 0px 30px;
}

.pc .empty-wrap-title--h3 .txt-red {
  color: #ea512d;
}

.pc .empty-wrap p {
  color: #104e6f;
  font-size: 16px;
  line-height: 1.8;
  padding: 0px 30px;
}

.pc .empty-wrap-clear::after {
  clear: both;
  content: "";
  display: block;
}

.pc .empty-wrap-clear-left {
  float: left;
  width: 48%;
}

.pc .empty-wrap-clear-right {
  width: 48%;
  float: right;
}

.pc .empty-wrap-bbox {
  background-color: #1aa8b5;
  background-color: #4db7bf;
  border-radius: 3px;
  padding: 12px;
  color: #fff;
}

.pc .empty-wrap-bbox h2 {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.5;
  font-weight: normal;
}

.pc .empty-wrap-btn {
  width: 350px;
  margin: 0 auto;
}

.pc .empty-wrap-btn a {
  text-align: center;
  background-color: #d90000;
  color: #fff;
  display: block;
  padding: 10px 0px;
}

.pc .empty-wrap-link {
  text-align: center;
  margin-top: 25px;
}

.pc .empty-wrap-link a {
  text-decoration: underline;
}

body.mobile {
  overflow-x: hidden;
}

.mobile {
  /* ---------- 並び替え ----------*/
  /* ---------- 並び替え ----------*/
}

.mobile #loader {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #fff;
  /*Loaderの背景色としたいものを指定*/
  opacity: 1.0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  /*一番前面に出るように（今回はbxSlidrの矢印が9999だったのでそれを超えるようにしました）*/
}

.mobile #loader img {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  /* Safari用 */
  transform: translate(-50%, -50%);
}

.mobile .mb-30 {
  margin-bottom: 30px;
}

.mobile .container {
  width: 100%;
}

.mobile .white-box {
  background: #fff;
  padding: 15px 10px;
}

.mobile .content-wrap {
  padding: 0 6px;
}

.mobile .content-wrap__first:before {
  content: "";
  display: block;
  height: 6px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.mobile .content-wrap__inner {
  border-left: 4px solid #9acedb;
  border-right: 4px solid #9acedb;
  padding: 0 7px;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dff2fa 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dff2fa 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dff2fa 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 */
}

.mobile .content-wrap__inner__add-pd {
  padding: 10px 7px;
}

.mobile .heading-block:before, .mobile .heading-block:after {
  content: "";
  display: block;
  height: 6px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
}

.mobile .heading-block__container {
  position: relative;
  background: #dcdfe7;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 );
  /* IE6-9 */
  min-height: 60px;
  padding: 10px 10px;
  z-index: 2222;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
}

.mobile .heading-block__container::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .heading-block__tab::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .heading-block__tab__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
  cursor: pointer;
  position: relative;
  bottom: 2px;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  background: #999999;
}

.mobile .heading-block__tab__item:last-child {
  margin-right: 0;
}

.mobile .heading-block__tab__item:before {
  margin-bottom: 2px !important;
}

.mobile .heading-block__tab__item.tintai:before {
  content: "";
  display: block;
  width: 30px;
  height: 29px;
  margin: 0 auto;
  background: url(./assets/img/sp_icon_tab_tintai_off.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item.uri:before {
  content: "";
  display: block;
  width: 22px;
  height: 29px;
  margin: 0 auto;
  background: url(./assets/img/sp_icon_tab_uri_off.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item.bunjyou:before {
  content: "";
  display: block;
  width: 25px;
  height: 29px;
  margin: 0 auto;
  background: url(./assets/img/sp_icon_tab_bunjyou_off.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item--active {
  position: relative;
  cursor: auto;
}

.mobile .heading-block__tab__item--active:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  width: 0;
  margin: 0 auto;
  border: 20px solid transparent;
  border-top: 8px solid transparent;
  border-top-color: #ccc;
  border-bottom-width: 0;
}

.mobile .heading-block__tab__item--active.tintai {
  background: #006ea7;
}

.mobile .heading-block__tab__item--active.tintai:before {
  background: url(./assets/img/sp_icon_tab_tintai_on.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item--active.tintai:after {
  border-top-color: #006ea7;
}

.mobile .heading-block__tab__item--active.uri {
  background: #ff5e5e;
}

.mobile .heading-block__tab__item--active.uri:before {
  background: url(./assets/img/sp_icon_tab_uri_on.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item--active.uri:after {
  border-top-color: #ff5e5e;
}

.mobile .heading-block__tab__item--active.bunjyou {
  background: #5e951d;
}

.mobile .heading-block__tab__item--active.bunjyou:before {
  background: url(./assets/img/sp_icon_tab_bunjyou_on.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .heading-block__tab__item--active.bunjyou:after {
  border-top-color: #5e951d;
}

.mobile .heading-block__tab__item__txt {
  color: #fff;
}

.mobile .heading-03 {
  font-size: 0.875rem;
  line-height: 1.3;
  border-left: 6px solid #0068b7;
  padding: 5px 0 5px 8px;
  border-bottom: 2px solid #0068b7;
  margin-bottom: 15px;
}

.mobile .btn__red {
  display: block;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background: #ca2c2c;
  border-radius: 6px;
  border-bottom: 1px solid #cccccc;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 10px 0;
  color: #fff;
}

.mobile .btn__red span {
  display: inline-block;
}

.mobile .btn__simulation {
  width: 250px;
  padding: 15px 0;
}

.mobile .btn__simulation:hover {
  padding: 16px 0 15px 0;
}

.mobile .page-title {
  font-size: .875rem;
}

.mobile .icon-arrow {
  background: url(./assets/img/icon_arrow_white.png) no-repeat left center;
  background-size: 8px 12px;
  padding-left: 15px;
}

.mobile .sp-post-list {
  padding: 10px 0;
}

.mobile .sp-post-list .post-item {
  margin: 0 3px;
  background: #fff;
}

.mobile .sp-post-list .post-item__thumb {
  text-align: center;
  overflow: hidden;
  height: 150px;
}

.mobile .sp-post-list .post-item__thumb img {
  width: 100%;
  height: auto;
}

.mobile .sp-post-list .post-item__right {
  padding: 10px 15px 15px 15px;
}

.mobile .sp-post-list .post-item__name {
  font-size: .875rem;
  line-height: 1.3;
  margin-bottom: 5px;
}

.mobile .sp-post-list .post-item__name a {
  color: #3452a9;
  text-decoration: underline;
}

.mobile .sp-post-list .post-item__price {
  font-size: .75rem;
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-right: 5px;
}

.mobile .sp-post-list .post-item__floor {
  position: absolute;
  left: 0;
  top: 0;
  background: #ca2c2c;
  font-size: .75rem;
  padding: 2px 5px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
}

.mobile .sp-post-list .post-item__address {
  font-size: .675rem;
  color: #666666;
}

.mobile .sp-post-list .slick-prev {
  left: -5px;
  z-index: 2222;
}

.mobile .sp-post-list .slick-prev:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: url(./assets/img/slider_icon_left.png) no-repeat;
  background-size: cover;
}

.mobile .sp-post-list .slick-next {
  right: -5px;
  z-index: 2222;
}

.mobile .sp-post-list .slick-next:before {
  content: "";
  display: inline-block;
  opacity: 1;
  width: 20px;
  height: 20px;
  background: url(./assets/img/slider_icon_right.png) no-repeat;
  background-size: cover;
}

.mobile .heading-block__title {
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.mobile .heading-block__title img {
  vertical-align: middle;
}

.mobile .heading-block__col-03 {
  margin: 0 25px;
  padding-top: 15px;
}

.mobile .heading-block__col-03::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .heading-block__col-03__item {
  float: left;
  margin: 0 25px;
}

.mobile .heading-block__col-01 {
  position: relative;
  bottom: 2px;
  background: #ff7e00;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 15px 0;
  margin-bottom: 0;
}

.mobile .heading-block__col-01:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  width: 0;
  margin: 0 auto;
  border: 20px solid transparent;
  border-top: 8px solid transparent;
  border-top-color: #ff7e00;
  border-bottom-width: 0;
}

.mobile .heading-block__col-02::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .heading-block__col-02__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  position: relative;
  bottom: 2px;
  background: #ff7e00;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 15px 0;
}

.mobile .heading-block__col-02__item:last-child {
  margin-right: 0;
}

.mobile .heading-block__col-02__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  width: 0;
  margin: 0 auto;
  border: 20px solid transparent;
  border-top: 8px solid transparent;
  border-top-color: #ff7e00;
  border-bottom-width: 0;
}

.mobile .col-02-content {
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.mobile .col-02-content::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .col-02-content__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  padding: 10px 8px 10px 0;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dff2fa 0%, #aad0e3 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dff2fa 0%, #aad0e3 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dff2fa 0%, #aad0e3 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#aad0e3',GradientType=1 );
  /* IE6-9 */
}

.mobile .col-02-content__left:last-child {
  margin-right: 0;
}

.mobile .col-02-content__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  padding: 10px 0 10px 8px;
  background: #a6cee2;
  /* Old browsers */
  background: -moz-linear-gradient(left, #a6cee2 0%, #72adcb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #a6cee2 0%, #72adcb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #a6cee2 0%, #72adcb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6cee2', endColorstr='#72adcb',GradientType=1 );
  /* IE6-9 */
}

.mobile .col-02-content__right:last-child {
  margin-right: 0;
}

.mobile .col-02-content__box {
  background: #fff;
  border-bottom: 1px solid #b2c2c8;
  border-radius: 4px;
  padding: 10px 8px;
  margin-bottom: 8px;
}

.mobile .col-02-content__box__select {
  width: 100%;
  font-size: .875rem;
  border: 1px solid #ccc;
  margin: 3px 0;
}

.mobile .col-02-content__box__01 {
  margin-bottom: 15px;
}

.mobile .col-02-content__price {
  margin-bottom: 10px;
}

.mobile .col-02-content__list {
  margin-bottom: 15px;
}

.mobile .col-02-content__list::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .col-02-content__list__item {
  border-bottom: 1px solid #e5e5e5;
  padding-top: 5px;
}

.mobile .col-02-content__list__item:last-child {
  border-bottom: none;
}

.mobile .col-02-content__list__item label {
  font-size: .8125rem;
}

.mobile .pr {
  padding: 10px 0;
}

.mobile .info {
  margin: 0;
  padding: 8px 0;
}

.mobile .info::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .info__item {
  position: relative;
  background: #fff;
  padding: 8px 8px 20px 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border-bottom: 1px solid #8ba9b7;
}

.mobile .info__item:last-child {
  margin-bottom: 0;
}

.mobile .info__item__image {
  margin-bottom: 5px;
  text-align: center;
}

.mobile .info__item__reform-txt {
  font-size: .75rem;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 15px;
}

.mobile .info__item__news-list {
  margin-bottom: 10px;
}

.mobile .info__item__news-list__item {
  border-bottom: 1px dotted #7f7f7f;
}

.mobile .info__item__news-list__item:last-child {
  border-bottom: none;
}

.mobile .info__item__news-list__item a {
  color: #000;
  padding: 10px 0;
  display: inline-block;
}

.mobile .info__item__archive-link {
  text-align: right;
  position: absolute;
  right: 10px;
  bottom: 0px;
  font-size: .75rem;
}

.mobile .info__item__archive-link a {
  color: #3452a9;
  text-decoration: underline;
}

.mobile .page-top {
  display: none;
  position: fixed;
  z-index: 3333;
  right: 10px;
  bottom: 60px;
}

.mobile .lower-content-box {
  background: #fff;
  padding: 20px 10px;
}

.mobile .lower-heading {
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 15px;
  color: #010101;
  background: #f3fafd;
  border-bottom: solid 2px #0068b7;
  line-height: 1.3;
}

.mobile .lower-sub-heading {
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 10px;
  padding: 5px 0 5px 7px;
  border-left: 3px solid #0068b7;
}

.mobile .lower-include-contact__no-bar:before, .mobile .lower-include-contact__no-bar:after {
  display: none !important;
}

.mobile .lower-include-contact__no-bar .lower-include-contact__container {
  background: none !important;
  box-shadow: none;
}

.mobile .lower-include-contact:before, .mobile .lower-include-contact:after {
  content: "";
  display: block;
  height: 6px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
}

.mobile .lower-include-contact__container {
  position: relative;
  background: #dcdfe7;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 );
  /* IE6-9 */
  min-height: 60px;
  padding: 20px 10px;
  z-index: 2222;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);
}

.mobile .lower-include-contact__container::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .lower-include-contact__tel {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  background: #fff;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.mobile .lower-include-contact__tel a {
  display: block;
  background: url(./assets/img/sp_include_contact_icon_tel.png) no-repeat left 10px center;
  background-size: 34px 34px;
  padding: 15px 0 15px 70px;
}

.mobile .lower-include-contact__mail {
  border-radius: 4px;
  background: #c70000;
  font-size: 1rem;
  font-weight: bold;
}

.mobile .lower-include-contact__mail a {
  display: block;
  background: url(./assets/img/sp_include_contact_icon_mail.png) no-repeat left 10px center;
  background-size: 34px 34px;
  color: #fff;
  padding: 15px 0 15px 70px;
}

.mobile #nendebcopy {
  display: none;
}

.mobile .pageback a {
  font-size: .75rem;
  display: inline-block;
  padding: 5px 10px;
  background: #FF7E00;
  color: #fff;
}

.mobile .header {
  width: 100%;
}

.mobile .header.mobile-header .header__navi {
  opacity: 1;
  transition: all 0.3s;
}

.mobile .header.mobile-header .header__navi.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3333;
  width: 100%;
}

.mobile .header__bar {
  background: #d90000;
  padding: 5px 10px;
}

.mobile .header__bar__item {
  color: #fff;
  font-size: 0.675rem;
  line-height: 1.3;
}

.mobile .header__container {
  position: relative;
  width: 100%;
  height: 55px;
  background: url(./assets/img/sp_header_bg.png) no-repeat center bottom;
  background-size: cover;
}

.mobile .header__logo {
  position: absolute;
  left: 10px;
  bottom: -1px;
  margin: 0;
  vertical-align: bottom;
}

.mobile .header__logo img {
  vertical-align: bottom;
}

.mobile .header__contact {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 0;
}

.mobile .header__contact__tel {
  display: inline-block;
  font-size: 1rem;
  margin-right: 5px;
}

.mobile .header__contact__mail {
  display: inline-block;
  font-size: 1rem;
}

.mobile .header__navi {
  display: block;
  background: #dcdfe7;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 );
  /* IE6-9 */
  border-bottom: 2px solid #969698;
}

.mobile .header__navi__list {
  text-align: center;
  font-size: 0;
  padding: 10px 0;
}

.mobile .header__navi__list__item {
  display: inline-block;
  vertical-align: middle;
  font-size: calc(11* (100vw / 375));
  border-left: 1px solid #cdcdce;
}

.mobile .header__navi__list__item:first-child {
  border-left: none;
}

.mobile .header__navi__list__item a {
  display: block;
  padding: 5px 4px;
  vertical-align: middle;
  color: #010101;
  font-weight: bold;
  position: relative;
}

.mobile .footer-fixed {
  position: fixed;
  bottom: 65px;
  left: 10px;
  z-index: 2223;
}

.mobile .footer-fixed img {
  width: 200px;
}

.mobile .footer-fixed-btn {
  position: absolute;
  right: -25px;
  top: 0px;
}

.mobile .footer-fixed-btn:hover {
  cursor: pointer;
}

.mobile .footer-fixed-btn img {
  width: 20px;
}

.mobile .mobile-navi {
  opacity: 0.9;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2222;
  background: #dcdfe7;
  /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 );
  /* IE6-9 */
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.mobile .mobile-navi::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .mobile-navi__list {
  text-align: center;
  font-size: 0;
}

.mobile .mobile-navi__list__item {
  position: relative;
  font-size: 0.625rem;
  width: 25%;
  float: left;
}

.mobile .mobile-navi__list__item:hover {
  background: #cdcdce;
}

.mobile .mobile-navi__list__item:nth-child(2) a {
  padding: 18px 0 12px;
}

.mobile .mobile-navi__list__item:last-child:after {
  display: none;
}

.mobile .mobile-navi__list__item:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 3333;
  background: #cdcdce;
}

.mobile .mobile-navi__list__item a {
  padding: 15px 0;
  display: block;
}

.mobile .mobile-navi__list__item a img {
  width: auto;
  margin: 0 auto 3px;
  display: block;
  vertical-align: bottom;
}

.mobile .mobile-footer-bar:before {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  content: "";
  display: block;
  height: 6px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
}

.mobile .mobile-footer-bar:before::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .bottom-contact {
  position: relative;
  background: url(./assets/img/footer_door_bg.png) repeat center bottom;
  background-size: 100% 165px;
}

.mobile .bottom-contact__container {
  position: relative;
  width: 290px;
  background-size: contain;
  height: 165px;
  margin: 0 auto;
}

.mobile .bottom-contact__btn {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 10;
}

.mobile #mobile_img_01 {
  position: absolute;
  width: 100%;
  z-index: 5;
}

.mobile #mobile_img_02 {
  position: absolute;
  top: 49px;
  left: 92px;
  z-index: 3;
}

.mobile #mobile_img_03 {
  position: absolute;
  top: 49px;
  left: 152px;
  z-index: 3;
}

.mobile #mobile_peple img {
  position: absolute;
  right: 0;
  bottom: 2px;
  left: 0;
  margin: auto;
  z-index: 2000;
  display: none;
  height: 130px;
  width: auto;
}

.mobile .footer {
  padding-bottom: 41px;
}

.mobile .footer__container {
  padding: 20px 10px;
}

.mobile .footer__container::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .footer__logo {
  text-align: center;
  margin-bottom: 15px;
}

.mobile .footer__logo img {
  vertical-align: bottom;
}

.mobile .footer__tel {
  text-align: center;
  margin-bottom: 10px;
}

.mobile .footer__tel img {
  vertical-align: bottom;
}

.mobile .footer__txt {
  font-size: .75rem;
  color: #545454;
  text-align: center;
}

.mobile .footer__bottom {
  background: #c70000;
}

.mobile .footer__bottom__container {
  padding: 20px;
}

.mobile .footer__bottom__navi {
  text-align: center;
  font-size: 0;
  line-height: 2.5;
  margin-bottom: 15px;
}

.mobile .footer__bottom__navi__item {
  display: inline-block;
  font-weight: bold;
  font-size: .875rem;
}

.mobile .footer__bottom__navi__item:last-child:after {
  display: none;
}

.mobile .footer__bottom__navi__item:after {
  content: "|";
  display: inline-block;
  font-size: .875rem;
  color: #fff;
  margin: 0 .875rem;
}

.mobile .footer__bottom__navi__item a {
  color: #fff;
}

.mobile .footer__bottom .copy {
  display: block;
  text-align: center;
  color: #fff;
  font-size: .75rem;
}

.mobile .footer__bunjo-link {
  text-align: center;
  padding-top: 8px;
  margin-bottom: -10px;
}

.mobile .footer__bunjo-link img {
  border: 1px solid #fff;
}

.mobile .footer__kominka-link {
  text-align: center;
  padding-top: 8px;
  margin-bottom: 0px;
}

.mobile .footer__kominka-link img {
  border: 1px solid #fff;
}

.mobile .add-fixed-koukoku {
  position: fixed;
  bottom: 62px;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9998;
  padding: 5px 10px;
}

.mobile .add-fixed-koukoku__list {
  text-align: center;
}

.mobile .add-fixed-koukoku__list::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .add-fixed-koukoku__list__item:first-child {
  width: 23%;
  display: inline-block;
}

.mobile .add-fixed-koukoku__list__item:last-child {
  width: 14%;
  display: inline-block;
}

.mobile .add-fixed-koukoku__close {
  width: 50px;
  position: fixed;
  bottom: 75px;
  right: 10px;
  z-index: 9999;
  width: 25px;
}

.mobile .add-fixed-koukoku img {
  display: block;
  width: 90%;
}

.mobile .slider {
  overflow: hidden;
  padding: 0 6px;
}

.mobile .slider:before {
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  background: #7b7b7b;
  /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 );
  /* IE6-9 */
}

.mobile .slider__list {
  width: 100%;
  padding: 7px;
  border-left: 4px solid #9acedb;
  border-right: 4px solid #9acedb;
  background: #dff2fa;
  /* Old browsers */
  background: -moz-linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(36deg, #dff2fa 0%, #71accb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.mobile .slider__list__item {
  text-align: center;
}

.mobile .slider .slick-prev, .mobile .slider .slick-next {
  display: none;
}

.mobile .top .tab-content {
  display: none;
}

.mobile .top .tab-content:first-child {
  display: block;
}

.mobile .top .tab-content__list {
  padding-top: 1%;
  margin-bottom: 5px;
}

.mobile .top .tab-content__list::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .top .tab-content__list.tintai .tab-content__list__item {
  background: url(./assets/img/icon_tintai.png) no-repeat left 8px center;
  background-size: 16px 16px;
  background-color: #fff;
}

.mobile .top .tab-content__list.uri .tab-content__list__item {
  background: url(./assets/img/icon_uri.png) no-repeat left 8px center;
  background-size: 16px 16px;
  background-color: #fff;
}

.mobile .top .tab-content__list.bunjyou .tab-content__list__item {
  background: url(./assets/img/icon_bunjyou.png) no-repeat left 8px center;
  background-size: 16px 16px;
  background-color: #fff;
}

.mobile .top .tab-content__list__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  margin-bottom: 1%;
  background: #fff;
  border-radius: 4px;
  border-bottom: 1px solid #abc1cb;
  text-align: left;
  font-weight: bold;
  font-size: 0.75em;
  letter-spacing: -0.01rem;
}

.mobile .top .tab-content__list__item:last-child {
  margin-right: 0;
}

.mobile .top .tab-content__list__item:nth-child(2n) {
  margin-right: 0;
}

.mobile .top .tab-content__list__item:nth-child(2n+1) {
  clear: left;
}

.mobile .top .tab-content__list__item a {
  display: block;
  padding: 15px 0 15px 30px;
}

.mobile .top .tab-content__area {
  padding: 0 0 10px 0;
}

.mobile .top .tab-content__area__heading {
  font-size: .875rem;
  margin-bottom: 8px;
  padding: 5px 10px;
  cursor: pointer;
}

.mobile .top .tab-content__area__heading.open:before {
  content: "\f0de";
  position: relative;
  top: 4px;
}

.mobile .top .tab-content__area__heading:before {
  content: "\f0dd";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 1rem;
  margin-right: 6px;
  position: relative;
  bottom: 3px;
}

.mobile .top .tab-content__area__heading.tintai {
  background: #006EA7;
  color: #fff;
}

.mobile .top .tab-content__area__heading.buy {
  background: #FF5E5E;
  color: #fff;
}

.mobile .top .tab-content__area__heading.tochi {
  background: #5E951D;
  color: #fff;
}

.mobile .top .tab-content__area__list {
  display: none;
}

.mobile .top .tab-content__area__list.tintai .tab-content__area__list__item a:before {
  color: #006EA7;
}

.mobile .top .tab-content__area__list.buy .tab-content__area__list__item a:before {
  color: #FF5E5E;
}

.mobile .top .tab-content__area__list.tochi .tab-content__area__list__item a:before {
  color: #5E951D;
}

.mobile .top .tab-content__area__list__item {
  background: #fff;
  border-radius: 4px;
  border-bottom: 1px solid #afbfc6;
  margin-bottom: 2px;
  display: inline-block;
}

.mobile .top .tab-content__area__list__item:last-child {
  margin-bottom: 0;
}

.mobile .top .tab-content__area__list__item a {
  color: #000;
  display: block;
  padding: 5px 10px;
  font-size: 0.625rem;
  font-weight: bold;
}

.mobile .top .tab-content__area__list__item a:before {
  content: "\f041";
  display: inline-block;
  font-family: FontAwesome;
  font-size: .875rem;
  margin-right: 6px;
}

.mobile .top .sp-thumb-slider {
  padding: 10px 0;
}

.mobile .top .sp-thumb-slider__item {
  margin: 0 3px;
  background: #fff;
}

.mobile .top .sp-thumb-slider__item__thumb {
  text-align: center;
  overflow: hidden;
  height: 150px;
}

.mobile .top .sp-thumb-slider__item__thumb img {
  width: 100%;
  height: auto;
}

.mobile .top .sp-thumb-slider__item__detail {
  padding: 10px 15px 15px 15px;
}

.mobile .top .sp-thumb-slider__item__title {
  font-size: .875rem;
  line-height: 1.3;
  margin-bottom: 5px;
}

.mobile .top .sp-thumb-slider__item__title a {
  color: #3452a9;
  text-decoration: underline;
}

.mobile .top .sp-thumb-slider__item__price {
  font-size: .75rem;
  display: inline-block;
  color: #ca2c2c;
  font-weight: bold;
  margin-right: 5px;
}

.mobile .top .sp-thumb-slider__item__floor {
  position: absolute;
  left: 0;
  top: 0;
  background: #ca2c2c;
  font-size: .75rem;
  padding: 2px 5px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
}

.mobile .top .sp-thumb-slider__item__address {
  font-size: .675rem;
  color: #666666;
}

.mobile .top .home-search__container {
  padding: 10px 0;
}

.mobile .top .home-search__form {
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
}

.mobile .top .home-search__form::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .top .home-search__form__section {
  margin-bottom: 10px;
}

.mobile .top .home-search__form__section ul {
  line-height: 2.2;
}

.mobile .top .home-search__form__section label {
  font-weight: normal;
}

.mobile .top .home-search__form__section select {
  font-size: 14px;
  border: 1px solid #999;
  padding: 4px;
}

.mobile .top .home-search__form__txt-search {
  margin-top: 15px;
  border-top: 1px solid #999;
  padding-top: 15px;
}

.mobile .top .home-search__form__txt-search::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .top .home-search__form__txt-search input.home-search-input::-webkit-input-placeholder {
  font-size: 14px;
}

.mobile .top .home-search__form__txt-search input.home-search-input:-ms-input-placeholder {
  font-size: 14px;
}

.mobile .top .home-search__form__txt-search input.home-search-input::-moz-placeholder {
  font-size: 14px;
}

.mobile .top .home-search__form__txt-search__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 74.61667%;
}

.mobile .top .home-search__form__txt-search__left:last-child {
  margin-right: 0;
}

.mobile .top .home-search__form__txt-search__left input {
  width: 100%;
}

.mobile .top .home-search__form__txt-search__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 23.85001%;
}

.mobile .top .home-search__form__txt-search__right:last-child {
  margin-right: 0;
}

.mobile .top .home-search__form__txt-search__right .btn__red {
  width: 100%;
  padding: 12px 0;
}

.mobile .top .home-search__form .btn__red {
  clear: both;
}

.mobile .madori-search::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .madori-search li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.mobile .madori-search li:last-child {
  margin-right: 0;
}

.mobile .madori-search li:nth-child(2n) {
  margin-right: 0;
}

.mobile .madori-search li:nth-child(2n+1) {
  clear: left;
}

.mobile .kakaku-search::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .kakaku-search select {
  display: inline-block;
  width: 42%;
  font-size: 14px;
  margin: 0 5px;
}

.mobile .kodawari-search {
  text-align: left;
}

.mobile .kodawari-search li {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}

.mobile .top-bunjo-link {
  text-align: center;
  padding-top: 8px;
  margin-bottom: -10px;
}

.mobile .top-bunjo-link img {
  border: 1px solid #fff;
}

.mobile .kominka-link {
  text-align: center;
  padding-top: 8px;
  margin-bottom: -20px;
}

.mobile .kominka-link img {
  border: 1px solid #fff;
}

.mobile .search-heading {
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
}

.mobile .archive-fudo {
  /* ---------- bookmark ---------- */
}

.mobile .archive-fudo .list_simple_box {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
}

.mobile .archive-fudo .list_simple_box .list_picsam a:first-child {
  display: none;
}

.mobile .archive-fudo .list_simple_box .list_picsam_img {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  margin: 0 auto 10px;
  height: 230px;
  overflow: hidden;
}

.mobile .archive-fudo .list_simple_box .list_picsam_img img {
  border: 1px solid #e9e9e9;
  width: 100%;
  height: auto;
}

.mobile .archive-fudo .list_simple_box .entry-title {
  margin: 0 0 5px 0;
  font-size: 0.875rem;
  line-height: 1.3;
}

.mobile .archive-fudo .list_simple_box .entry-title a {
  color: #3452a9;
  text-decoration: underline;
}

.mobile .archive-fudo .list_simple_box .entry-excerpt {
  text-align: left;
  margin-bottom: 5px;
  font-size: .75rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.mobile .archive-fudo .list_simple_box .entry-excerpt span {
  display: inline-block;
  background: url(./assets/img/icon_point.png) no-repeat left top;
  background-size: 15px 16px;
  padding-left: 20px;
}

.mobile .archive-fudo .list_simple_box .entry-num {
  font-size: 0.625rem;
  margin: 0 0 2px 0;
  color: #666666;
}

.mobile .archive-fudo .list_simple_box .list_detail table {
  font-size: .75rem;
  margin: 0;
}

.mobile .archive-fudo .list_simple_box .list_detail table th {
  padding: 0;
}

.mobile .archive-fudo .list_simple_box .list_detail table td {
  padding: 0;
  border: none;
}

.mobile .archive-fudo .list_simple_box .list_detail table td dt {
  display: inline-block;
}

.mobile .archive-fudo .list_simple_box .list_detail table td dd {
  display: inline-block;
  margin: 0 5px;
  color: #666666;
}

.mobile .archive-fudo .color-red {
  color: #ff6464;
  font-weight: bold;
  font-size: 1rem;
}

.mobile .archive-fudo.bookmark .wpfp-link {
  background: #FF7E00;
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
}

.mobile .archive-fudo.bookmark .wpfp-link.remove-parent {
  background: #FF7E00;
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
  margin-top: 10px;
}

.mobile .archive-fudo.bookmark .list_simple_box {
  border-bottom: none;
}

.mobile .important-items {
  margin-bottom: 0px;
}

.mobile .important-items dl {
  display: inline-block;
  margin-right: 15px;
  font-size: 0;
}

.mobile .important-items dl:last-child {
  margin-right: 0;
}

.mobile .important-items dl dt {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: normal;
  margin-right: 5px;
}

.mobile .important-items dl dd {
  display: inline-block;
  font-size: .875rem;
  color: #ff6464;
  font-weight: bold;
}

.mobile .list_price_others {
  display: table;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  display: none;
}

.mobile .list_price_others::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .list_price_others dt {
  display: table-cell;
  border: 1px solid #ddd;
  background: #FAFCFD;
}

.mobile .list_price_others dd {
  display: table-cell;
  border: 1px solid #ddd;
}

.mobile .list_price_others dd.detail-link {
  float: right;
  font-weight: bold;
}

.mobile .list_price_others dd.detail-link a {
  color: #3452a9;
  text-decoration: underline;
}

.mobile .navigation {
  margin: 15px 0;
  text-align: center;
}

.mobile .navigation a {
  line-height: 1;
  display: inline-block;
  border: 1px solid #c7c7c7;
  padding: 5px 10px;
  margin: 0 2px 5px 2px;
}

.mobile .navigation a img {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}

.mobile .navigation .nav-previous {
  margin-bottom: 15px;
}

.mobile .navigation .nav-next {
  font-size: .625rem;
  font-weight: bold;
}

.mobile .navigation .nav-next a {
  line-height: 1;
  display: inline-block;
  border: 1px solid #c7c7c7;
  padding: 8px;
  margin: 0 2px;
  font-weight: normal;
}

.mobile .navigation .nav-next b {
  line-height: 1;
  display: inline-block;
  border: 1px solid #000;
  padding: 8px;
  background: #000;
  color: #fff;
  margin: 0 3px;
  font-weight: normal;
}

.mobile .detail {
  /* =======================================
      ClearFixElements
  ======================================= */
}

.mobile .detail__num {
  margin-bottom: 6px;
}

.mobile .detail__num__item dt {
  background: #ff6464;
  color: #fff;
  display: inline-block;
  font-weight: normal;
  font-size: 0.75rem;
  padding: 0 5px;
}

.mobile .detail__num__item dd {
  display: inline-block;
  font-size: 0.875rem;
}

.mobile .detail__top-content {
  padding-top: 15px;
}

.mobile .detail__top-content__title {
  font-size: 1.125rem;
  margin-bottom: 10px;
  line-height: 1.5;
}

.mobile .detail__top-content__title:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}

.mobile .detail__top-content__title__tintai:before {
  background: url(./assets/img/icon_tintai.png) no-repeat center center;
  background-size: cover;
}

.mobile .detail__top-content__title__uri:before {
  background: url(./assets/img/icon_uri.png) no-repeat center center;
  background-size: cover;
}

.mobile .detail__top-content__title__bunjyou:before {
  background: url(./assets/img/icon_bunjyou.png) no-repeat center center;
  background-size: cover;
}

.mobile .detail__top-content__box {
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.mobile .detail__top-content__box__top {
  padding-bottom: 13px;
  margin-bottom: 13px;
  border-bottom: 1px solid #bfbfbf;
}

.mobile .detail__top-content__box__top::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail__top-content__box__top__left {
  display: block;
  font-size: 0;
  padding: 5px 15px 5px 0;
}

.mobile .detail__top-content__box__top__left .rent {
  display: inline-block;
}

.mobile .detail__top-content__box__top__left .rent dt {
  font-size: 0.875rem;
  font-weight: normal;
  display: inline-block;
  margin-right: 5px;
}

.mobile .detail__top-content__box__top__left .rent dd {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
}

.mobile .detail__top-content__box__top__left .rent dd .strong {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: bold;
  color: #ff6464;
  display: inline-block;
  margin-right: 5px;
}

.mobile .detail__top-content__box__top__left .service-fee {
  font-size: .625rem;
}

.mobile .detail__top-content__box__top__left .service-fee dt, .mobile .detail__top-content__box__top__left .service-fee dd {
  font-weight: normal;
  display: inline;
}

.mobile .detail__top-content__box__top__right {
  font-size: 0;
  display: block;
}

.mobile .detail__top-content__box__top__right .floor {
  display: inline-block;
}

.mobile .detail__top-content__box__top__right .floor dt {
  font-size: 0.875rem;
  font-weight: normal;
  display: inline-block;
  margin-right: 5px;
}

.mobile .detail__top-content__box__top__right .floor dd {
  font-size: 1.125rem;
  font-weight: normal;
  display: inline-block;
}

.mobile .detail__top-content__box__top__right .floor dd .strong {
  font-size: 1.125rem;
  font-weight: bold;
  color: #ff6464;
  display: inline-block;
  margin-right: 5px;
}

.mobile .detail__top-content__box__top__right .area-size {
  font-size: .75rem;
}

.mobile .detail__top-content__box__top__right .area-size dt, .mobile .detail__top-content__box__top__right .area-size dd {
  font-weight: normal;
  display: inline;
}

.mobile .detail__top-content__box__bottom .detail__num {
  position: absolute;
  right: 0;
  top: -47px;
  background: url(./assets/img/detail_num_bg.png) no-repeat;
  width: 189px;
  height: 48px;
}

.mobile .detail__top-content__box__bottom .detail__num__item {
  margin: 27px 0 0 56px;
}

.mobile .detail__top-content__box__bottom .detail__num__item dt {
  display: inline-block;
  font-weight: normal;
  font-size: 0.75rem;
}

.mobile .detail__top-content__box__bottom .detail__num__item dd {
  display: inline-block;
  font-size: 0.75rem;
}

.mobile .detail__top-content__box__bottom .other-info {
  margin-bottom: 15px;
}

.mobile .detail__top-content__box__bottom .other-info::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail__top-content__box__bottom .other-info__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  margin-bottom: 1%;
}

.mobile .detail__top-content__box__bottom .other-info__item:last-child {
  margin-right: 0;
}

.mobile .detail__top-content__box__bottom .other-info__item:nth-child(2n) {
  margin-right: 0;
}

.mobile .detail__top-content__box__bottom .other-info__item:nth-child(2n+1) {
  clear: left;
}

.mobile .detail__top-content__box__bottom .other-info__item__w100 {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 100%;
}

.mobile .detail__top-content__box__bottom .other-info__item__w100:last-child {
  margin-right: 0;
}

.mobile .detail__top-content__box__bottom .other-info__item__w100:nth-child(1n) {
  margin-right: 0;
}

.mobile .detail__top-content__box__bottom .other-info__item__w100:nth-child(1n+1) {
  clear: left;
}

.mobile .detail__top-content__box__bottom .other-info__item dt {
  color: #fff;
  background: #ff6464;
  font-weight: normal;
  font-size: .75rem;
  min-width: 70px;
  text-align: center;
  padding: 0 5px;
  display: inline-block;
  margin-right: 5px;
}

.mobile .detail__top-content__box__bottom .other-info__item dd {
  display: inline-block;
  font-size: .75rem;
}

.mobile .detail__top-content__box__bottom .other-info__item__btn-map {
  display: inline-block;
  margin-left: 10px;
}

.mobile .detail__top-content__box__bottom .other-info__item__btn-map a {
  display: block;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: .75rem;
  border: 1px solid #b7b7b7;
  background: #e7e7e7;
  /* Old browsers */
  background: #ffffff;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2U3ZTdlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top, #ffffff 0%, #e7e7e7 88%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #e7e7e7 88%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #e7e7e7 88%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e7e7',GradientType=0 );
  /* IE6-8 */
}

.mobile .detail__top-content__box__bottom .other-info__item__btn-map a:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 16px;
  background: url(./assets/img/icon_map.png) no-repeat center center;
  background-size: cover;
  margin-left: 5px;
  position: relative;
  top: 3px;
}

.mobile .detail__top-content__box__bottom .point dt {
  position: relative;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: bold;
  border: 1px solid #ff6464;
  border-radius: 10px;
  padding: 0 10px 0 30px;
  margin-right: 10px;
}

.mobile .detail__top-content__box__bottom .point dt:before {
  position: absolute;
  left: 0;
  top: -6px;
  content: "";
  display: inline-block;
  background: url(./assets/img/icon_point.png) no-repeat center center;
  background-size: cover;
  width: 25px;
  height: 28px;
}

.mobile .detail__top-content__box__bottom .point dd {
  display: inline-block;
  font-size: .75rem;
}

.mobile .detail .viewer {
  height: auto !important;
}

.mobile .detail .viewer::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail .viewer__main img {
  background: #fff;
  padding: 2px;
}

.mobile .detail .viewer div {
  position: relative;
  width: 290px;
  height: 226px;
  margin: 0 auto;
  overflow: hidden;
}

.mobile .detail .viewer img {
  line-height: 1;
  vertical-align: top;
}

.mobile .detail .viewer div img {
  top: 0;
  left: 0;
  position: absolute;
}

.mobile .detail .viewer ul {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}

.mobile .detail .viewer ul::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail .viewer ul li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
  cursor: pointer;
  text-align: center;
  background: #fff;
  padding: 2px;
  margin-bottom: 3px;
}

.mobile .detail .viewer ul li:last-child {
  margin-right: 0;
}

.mobile .detail .viewer ul li:nth-child(3n) {
  margin-right: 0;
}

.mobile .detail .viewer ul li:nth-child(3n+1) {
  clear: left;
}

.mobile .detail .viewer ul li img {
  height: 80px;
  width: auto;
  overflow: hidden;
}

.mobile .detail .viewer .active {
  filter: alpha(opacity=100) !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
}

.mobile .detail .viewer ul:after {
  content: ".";
  height: 0;
  clear: both;
  display: block;
  visibility: hidden;
}

.mobile .detail .viewer ul {
  display: inline-block;
  overflow: hidden;
}

.mobile .detail .more-title {
  background: #3d77ae;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  padding: 10px 0;
  line-height: 1.3;
  margin-bottom: 20px;
}

.mobile .detail .more-image {
  text-align: center;
  padding: 10px;
  background: #fff;
  margin-bottom: 20px;
}

.mobile .detail .more-info-01, .mobile .detail .more-info-02 {
  border-radius: 4px;
  border: 1px solid #dedede;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: .75rem;
}

.mobile .detail .more-info-01 table, .mobile .detail .more-info-02 table {
  width: 100%;
  margin: 0;
}

.mobile .detail .more-info-01 table th, .mobile .detail .more-info-02 table th {
  background: #e3eef4;
  border: 1px solid #dedede;
  padding: 10px;
  font-weight: normal;
  width: 30%;
}

.mobile .detail .more-info-01 table td, .mobile .detail .more-info-02 table td {
  background: #fff;
  border: 1px solid #dedede;
  padding: 10px;
}

.mobile .detail .more-notices {
  border: 1px solid #dedede;
  border-radius: 4px;
  margin-bottom: 10px;
}

.mobile .detail .more-notices__item dt {
  background: #ffe3e3;
  color: #ff0000;
  text-align: center;
  border-bottom: 1px solid #dedede;
  font-size: 1rem;
  padding: 10px 0;
  font-weight: normal;
}

.mobile .detail .more-notices__item dd {
  background: #fff;
  font-size: .875rem;
  padding: 10px;
}

.mobile .detail .more-info__map {
  margin-bottom: 10px;
}

.mobile .detail .more-caution {
  font-size: .75rem;
}

.mobile .detail__bookmark {
  margin-bottom: 10px;
}

.mobile .detail__bookmark__container {
  background: #FF7E00;
  display: inline-block;
}

.mobile .detail__bookmark__container .wpfp-span {
  line-height: 1.3;
  display: inline-block;
  color: #fff;
  font-size: .75rem;
  width: 186px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.mobile .detail__bookmark__container .wpfp-span a {
  color: #fff;
  display: block;
}

.mobile .detail__bookmark__container .wpfp-span a:before {
  content: "\f02e";
  font-family: FontAwesome;
  display: inline-block;
  font-size: 1rem;
  margin-right: 10px;
  color: #fff;
}

.mobile .detail .widget_fudo_syousai {
  background: #fff;
  margin-top: 15px;
  padding: 15px 10px 10px 10px;
  border-radius: 8px;
}

.mobile .detail .widget_fudo_syousai h3 {
  margin: 0 0 20px;
  border-left: 4px solid #0068b7;
  border-bottom: 1px solid #0068b7;
  font-size: 1.125rem;
  padding: 3px 0 5px 10px;
}

.mobile .detail .syousai-content::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail .syousai-content .syousai-content-li {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  border: 1px solid #f2f2f2;
  position: relative;
  background: #fff;
  padding: 5px;
  margin-bottom: 2%;
  font-size: .75rem;
}

.mobile .detail .syousai-content .syousai-content-li:last-child {
  margin-right: 0;
}

.mobile .detail .syousai-content .syousai-content-li:nth-child(2n) {
  margin-right: 0;
}

.mobile .detail .syousai-content .syousai-content-li:nth-child(2n+1) {
  clear: left;
}

.mobile .detail .syousai-content .syousai-content-li a {
  display: block;
}

.mobile .detail .syousai-content .syousai-content-li a img {
  width: 100%;
}

.mobile .detail .syousai-content .syousai-content-li .new_mark {
  background: #ca2c2c;
  color: #fff;
  font-size: .75rem;
  font-weight: bold;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 46px;
  padding: 3px 0;
  line-height: 1;
  text-align: center;
}

.mobile .detail .syousai-content .syousai-content-li .top_title {
  font-weight: bold;
  color: #3452a9;
}

.mobile .detail .syousai-content .syousai-content-li .top_price {
  color: #ff6464;
  font-weight: bold;
}

.mobile .detail .syousai-content .syousai-content-li .top_madori {
  color: #ff6464;
  font-weight: bold;
}

.mobile .detail .syousai-content .syousai-content-li .top_menseki {
  color: #ff6464;
  font-weight: bold;
}

.mobile .detail .syousai-content .syousai-content-li .top_shozaichi {
  color: #666666;
  line-height: 1.5;
}

.mobile .detail .akijouhou {
  width: 280px;
  margin: 0 auto 20px;
}

.mobile .detail__pet {
  display: block;
  background: url(./assets/img/icon_pet.png) no-repeat;
  background-size: contain;
  padding-left: 25px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.mobile .detail__pet__inu {
  display: block;
  background: url(./assets/img/icon_dog.png) no-repeat;
  background-size: contain;
  padding-left: 25px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.mobile .detail__pet__neko {
  display: block;
  background: url(./assets/img/icon_cat.png) no-repeat;
  background-size: contain;
  padding-left: 25px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.mobile .detail .tochikau-images::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .detail .tochikau-images__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  background: #fff;
  padding: 2px;
  margin-bottom: 1%;
}

.mobile .detail .tochikau-images__item:last-child {
  margin-right: 0;
}

.mobile .detail .tochikau-images__item:nth-child(2n) {
  margin-right: 0;
}

.mobile .detail .tochikau-images__item:nth-child(2n+1) {
  clear: left;
}

.mobile .detail .tochikau-images__item img {
  vertical-align: top;
  width: 100%;
  height: auto;
}

.mobile .company .greeting {
  margin-bottom: 20px;
}

.mobile .company .greeting__name {
  text-align: right;
}

.mobile .company .greeting__photo::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .company .greeting__photo__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.mobile .company .greeting__photo__item:last-child {
  margin-right: 0;
}

.mobile .company .info__table {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.mobile .company .info__table::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .company .info__table tr th {
  padding: 10px;
  width: 80px;
  background: #fafcfd;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  text-align: center;
  vertical-align: middle;
  font-size: .75rem;
}

.mobile .company .info__table tr td {
  padding: 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  font-size: .75rem;
}

.mobile .contact__table {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-top: 1px solid #dedede;
}

.mobile .contact__table::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .contact__table tr th {
  text-align: left;
  display: block;
  padding: 10px;
  width: 100%;
  background: #fafcfd;
  font-size: 0.8125rem;
  border: 1px solid #dedede;
  border-top: none;
  vertical-align: middle;
}

.mobile .contact__table tr th::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .contact__table tr th .required {
  float: right;
  color: #fff;
  background: #ff7e00;
  padding: 2px 10px;
  display: inline-block;
  font-size: .75rem;
}

.mobile .contact__table tr td {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  border-top: none;
}

.mobile .contact__table tr td input {
  margin: 0;
}

.mobile .contact__table tr select {
  margin: 0;
}

.mobile .contact__table tr textarea {
  margin: 0;
}

.mobile .contact input[type="submit"] {
  display: block;
  width: 180px;
  background: #ff7e00;
  color: #fff;
  margin: 0 auto;
}

.mobile .single-post img {
  height: auto;
}

.mobile .single-post__time {
  font-size: 1rem;
  margin-bottom: 10px;
}

.mobile .single-post__thumb {
  margin-bottom: 10px;
}

.mobile .single-post__content {
  margin-bottom: 45px;
  font-size: 0.875rem;
}

.mobile .single-post__content p {
  margin-bottom: 1.25rem;
  line-height: 1.6;
}

.mobile .single-post__content img {
  display: inline-block;
  margin: 5px 0;
}

.mobile .single-post__navi {
  text-align: center;
  font-size: 0.875rem;
}

.mobile .single-post__navi a {
  display: inline-block;
  margin: 5px;
}

.mobile .archive__list__item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #7f7f7f;
}

.mobile .archive__list__item::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .archive__list__item__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.mobile .archive__list__item__left:last-child {
  margin-right: 0;
}

.mobile .archive__list__item__left img {
  height: auto;
  vertical-align: top;
}

.mobile .archive__list__item__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 100%;
}

.mobile .archive__list__item__right:last-child {
  margin-right: 0;
}

.mobile .archive__list__item__time {
  font-size: .75rem;
  margin-bottom: 5px;
}

.mobile .archive__list__item__heading {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3;
}

.mobile .archive__list__item__heading a {
  color: #3452a9;
}

.mobile .archive__paging {
  text-align: center;
  font-size: 0;
  margin-top: 30px;
}

.mobile .archive__paging .page-numbers {
  font-size: .875rem;
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  line-height: 1;
  border: 1px solid #000;
  color: #000;
}

.mobile .archive__paging .page-numbers.current {
  color: #fff;
  background: #000;
}

.mobile .empty {
  width: 100%;
  max-width: 100%;
}

.mobile .empty .breadcrumb {
  padding-right: 0px;
}

.mobile .empty .lower-gradient__wrap {
  border-right: 0px;
}

.mobile .empty-title--h1 {
  margin: 30px 0 10px;
  padding: 0;
  font-size: 28px;
  line-height: 1.6;
  font-weight: 600;
  text-align: center;
  color: #104e6f;
}

@media screen and (max-width: 767px) {
  .mobile .empty-title--h1 {
    font-size: 16px;
    text-align: left;
  }
}

.mobile .empty-img {
  text-align: center;
}

.mobile .empty-img img {
  max-width: 750px;
}

@media screen and (max-width: 767px) {
  .mobile .empty-img img {
    max-width: 100%;
  }
}

.mobile .empty-wrap {
  margin-bottom: 60px;
}

.mobile .empty-wrap-title--h3 {
  margin: 20px 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  color: #1aa8b5;
  line-height: 1.6;
  text-align: left;
  padding: 0px 30px;
}

.mobile .empty-wrap-title--h3 .txt-red {
  color: #ea512d;
}

.mobile .empty-wrap p {
  color: #104e6f;
  font-size: 16px;
  line-height: 1.8;
  padding: 0px 30px;
}

.mobile .empty-wrap-clear::after {
  clear: both;
  content: "";
  display: block;
}

.mobile .empty-wrap-clear-left {
  float: left;
  width: 48%;
}

@media screen and (max-width: 767px) {
  .mobile .empty-wrap-clear-left {
    width: 100%;
    margin-bottom: 20px;
  }
}

.mobile .empty-wrap-clear-right {
  width: 48%;
  float: right;
}

@media screen and (max-width: 767px) {
  .mobile .empty-wrap-clear-right {
    width: 100%;
  }
}

.mobile .empty-wrap-bbox {
  background-color: #1aa8b5;
  background-color: #4db7bf;
  border-radius: 3px;
  padding: 12px;
  color: #fff;
}

.mobile .empty-wrap-bbox h2 {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.5;
  font-weight: normal;
}

.mobile .empty-wrap-btn {
  width: 350px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .mobile .empty-wrap-btn {
    width: 100%;
  }
}

.mobile .empty-wrap-btn a {
  text-align: center;
  background-color: #d90000;
  color: #fff;
  display: block;
  padding: 10px 0px;
}

.mobile .empty-wrap-link {
  text-align: center;
  margin-top: 25px;
}

.mobile .empty-wrap-link a {
  text-decoration: underline;
}

/* -------------------- common -------------------- */
.kominka {
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
  line-height: 2.2;
  letter-spacing: 0.1rem;
  color: #333;
}

@media screen and (max-width: 1023px) {
  .kominka {
    font-size: 1rem;
    line-height: 1.6;
  }
}

.kominka h1, .kominka h2, .kominka h3 {
  font-family: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ 明朝', 'MS Mincho','ＭＳ Ｐ明朝','MS PMincho','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
  font-weight: normal;
  line-height: 1.3;
  color: #000;
}

.kominka .contant-wrap {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 50px;
}

.kominka .contant-wrap::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  .kominka .contant-wrap {
    padding: 0 10px 25px 10px;
  }
}

.kominka__heading {
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 30px;
  padding-top: 100px;
}

@media screen and (max-width: 1023px) {
  .kominka__heading {
    padding-top: 45px;
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
}

.kominka__heading:after {
  content: "";
  display: block;
  width: 75px;
  height: 1px;
  background: #000;
  margin: 15px auto 0;
}

@media screen and (max-width: 1023px) {
  .kominka__heading:after {
    margin: 10px auto 0;
  }
}

.kominka .normal-txt {
  margin: 0;
}

@media screen and (max-width: 1023px) {
  .kominka .normal-txt {
    font-size: .875rem;
    text-align: left;
  }
}

.kominka .normal-txt__center {
  text-align: center;
}

.tablet-show {
  display: none !important;
}

@media screen and (max-width: 1023px) {
  .tablet-show {
    display: block !important;
  }
}

.tablet-none {
  display: table-cell !important;
}

@media screen and (max-width: 1023px) {
  .tablet-none {
    display: none !important;
  }
}

/* -------------------- 田楽庵 -------------------- */
body.page-template-page-kominka_vol_1 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
  margin: 0;
  font-family: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ 明朝', 'MS Mincho','ＭＳ Ｐ明朝','MS PMincho','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
}

body.page-template-page-kominka_vol_1 .main-visual__title {
  margin: 0;
  line-height: 0;
}

body.page-template-page-kominka_vol_1 .main-visual__title img {
  width: 100%;
  height: auto;
  line-height: 1;
}

body.page-template-page-kominka_vol_1 .section-01 {
  background: url(./assets/img/kominka/vol01/sec_01_bg.jpg) no-repeat center bottom;
  background-size: cover;
}

body.page-template-page-kominka_vol_1 .section-01__col2 {
  margin-top: 75px;
}

body.page-template-page-kominka_vol_1 .section-01__col2::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-01__col2 {
    margin-top: 45px;
  }
}

body.page-template-page-kominka_vol_1 .section-01__col2__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  margin-bottom: 20px;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item:last-child {
  margin-right: 0;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item:nth-child(2n) {
  margin-right: 0;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item:nth-child(2n+1) {
  clear: left;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item::after {
  clear: both;
  content: "";
  display: block;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 40.77223%;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__left:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-01__col2__item__left {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
  }
  body.page-template-page-kominka_vol_1 .section-01__col2__item__left:last-child {
    margin-right: 0;
  }
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 57.69445%;
  padding: 5px 10px 0 10px;
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__right:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-01__col2__item__right {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
    padding: 5px 5px 0 5px;
  }
  body.page-template-page-kominka_vol_1 .section-01__col2__item__right:last-child {
    margin-right: 0;
  }
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__right__heading {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-01__col2__item__right__heading {
    font-size: 1rem;
  }
}

body.page-template-page-kominka_vol_1 .section-01__col2__item__right__txt {
  font-size: 0.8125rem;
  line-height: 1.5;
  text-align: justify;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-01__col2__item__right__txt {
    font-size: .75rem;
  }
}

body.page-template-page-kominka_vol_1 .section-02 {
  background: url(./assets/img/kominka/vol01/sec_02_bg.jpg) no-repeat center top;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02 .normal-txt {
    padding: 0 10px;
  }
}

body.page-template-page-kominka_vol_1 .section-02__col2 {
  display: table;
  width: 100%;
  margin-top: 75px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02__col2 {
    margin-top: 45px;
    display: block;
  }
}

body.page-template-page-kominka_vol_1 .section-02__col2__item {
  background: rgba(255, 255, 255, 0.6);
  display: table-row;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02__col2__item {
    display: block;
  }
}

body.page-template-page-kominka_vol_1 .section-02__col2__item__thumb {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 50%;
  line-height: 0;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02__col2__item__thumb {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

body.page-template-page-kominka_vol_1 .section-02__col2__item__thumb img {
  width: 100%;
  height: auto;
}

body.page-template-page-kominka_vol_1 .section-02__col2__item__txtarea {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 50%;
  font-size: 0.875rem;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02__col2__item__txtarea {
    width: 100%;
    padding: 0 10px 30px 10px;
    display: block;
  }
}

body.page-template-page-kominka_vol_1 .section-02__col2__item__txtarea__heading {
  font-size: 1.5rem;
  margin-bottom: 25px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-02__col2__item__txtarea__heading {
    font-size: 1.25rem;
    margin-bottom: 15px;
  }
}

body.page-template-page-kominka_vol_1 .section-03 {
  background: url(./assets/img/kominka/vol01/sec_03_bg.jpg) no-repeat center top;
  background-size: cover;
}

body.page-template-page-kominka_vol_1 .section-03__col2 {
  margin-top: 75px;
}

body.page-template-page-kominka_vol_1 .section-03__col2::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-03__col2 {
    margin-top: 30px;
  }
}

body.page-template-page-kominka_vol_1 .section-03__col2__item {
  width: 580px;
  float: left;
  margin-right: 80px;
  margin-bottom: 75px;
}

body.page-template-page-kominka_vol_1 .section-03__col2__item:nth-child(2n) {
  margin-right: 0;
}

body.page-template-page-kominka_vol_1 .section-03__col2__item:nth-child(2n+1) {
  clear: left;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-03__col2__item {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 45px;
  }
}

body.page-template-page-kominka_vol_1 .section-03__col2__item__info {
  padding: 0 20px;
  margin-top: -35px;
}

body.page-template-page-kominka_vol_1 .section-03__col2__item__heading {
  background: #000;
  color: #fff;
  display: inline-block;
  padding: 8px 0;
  width: 200px;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-03__col2__item__txt {
    font-size: .875rem;
  }
}

body.page-template-page-kominka_vol_1 .section-04 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
}

body.page-template-page-kominka_vol_1 .section-04__contents {
  margin-top: 75px;
}

body.page-template-page-kominka_vol_1 .section-04__contents::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-04__contents {
    margin-top: 30px;
  }
}

body.page-template-page-kominka_vol_1 .section-04__contents__item {
  width: 310px;
  float: left;
  box-shadow: 0 0px 3px #e9e9e9;
  padding: 35px 25px;
  position: relative;
  height: 390px;
  background: #fff;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-04__contents__item {
    width: 100%;
    float: none;
    padding: 35px 20px;
    height: auto;
  }
}

body.page-template-page-kominka_vol_1 .section-04__contents__item__thumb {
  text-align: center;
  margin-bottom: 20px;
}

body.page-template-page-kominka_vol_1 .section-04__contents__item__txt {
  font-size: 0.875rem;
  line-height: 1.8;
  text-align: justify;
  letter-spacing: 0.05rem;
}

body.page-template-page-kominka_vol_1 .section-05 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox {
  margin-top: 20px;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox__list::after {
  clear: both;
  content: "";
  display: block;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox__list__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 15.38891%;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox__list__item:last-child {
  margin-right: 0;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox__list__item:nth-child(6n) {
  margin-right: 0;
}

body.page-template-page-kominka_vol_1 .section-05__lightbox__list__item:nth-child(6n+1) {
  clear: left;
}

body.page-template-page-kominka_vol_1 .section-05__media {
  margin-top: 45px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-05__media {
    margin-top: 30px;
  }
}

body.page-template-page-kominka_vol_1 .section-05__media__heading {
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-05__media__heading {
    font-size: 1rem;
  }
}

body.page-template-page-kominka_vol_1 .section-05__media__image {
  text-align: center;
}

body.page-template-page-kominka_vol_1 .section-06 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
}

body.page-template-page-kominka_vol_1 .section-07 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
}

body.page-template-page-kominka_vol_1 .section-07__container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
}

body.page-template-page-kominka_vol_1 .section-07__container::after {
  clear: both;
  content: "";
  display: block;
}

body.page-template-page-kominka_vol_1 .section-07__container::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-07__container {
    margin-top: 30px;
  }
}

body.page-template-page-kominka_vol_1 .section-07__container__left {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 40.77223%;
}

body.page-template-page-kominka_vol_1 .section-07__container__left:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-07__container__left {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  body.page-template-page-kominka_vol_1 .section-07__container__left:last-child {
    margin-right: 0;
  }
}

body.page-template-page-kominka_vol_1 .section-07__container__right {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 57.69445%;
}

body.page-template-page-kominka_vol_1 .section-07__container__right:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-07__container__right {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
  }
  body.page-template-page-kominka_vol_1 .section-07__container__right:last-child {
    margin-right: 0;
  }
}

body.page-template-page-kominka_vol_1 .section-07__table {
  line-height: 1.3;
  font-size: .875rem;
  width: 100%;
  margin: 0;
}

body.page-template-page-kominka_vol_1 .section-07__table th {
  width: 28%;
  padding: 10px;
}

body.page-template-page-kominka_vol_1 .section-07__table td {
  padding: 10px;
}

body.page-template-page-kominka_vol_1 .section-07 .map iframe {
  width: 100%;
}

body.page-template-page-kominka_vol_1 .section-08 {
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
}

body.page-template-page-kominka_vol_1 .section-08 .map iframe {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-08 .map iframe {
    height: 300px;
  }
}

body.page-template-page-kominka_vol_1 .section-08__txt {
  text-align: center;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .section-08__txt {
    font-size: .75rem;
  }
}

body.page-template-page-kominka_vol_1 .contact {
  background-size: cover;
  margin-bottom: 150px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .contact {
    margin-bottom: 60px;
  }
}

body.page-template-page-kominka_vol_1 .contact__container {
  background: url(./assets/img/kominka/vol01/bg_contag.jpg) no-repeat center center;
  height: 320px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .contact__container {
    height: auto;
    padding: 10px;
  }
}

body.page-template-page-kominka_vol_1 .contact__heading {
  color: #fff;
  text-align: center;
  padding: 75px 0 0 0;
  font-size: 1.5rem;
  margin-bottom: 55px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .contact__heading {
    font-size: 1rem;
    padding: 30px 0 0 0;
    margin-bottom: 20px;
  }
}

body.page-template-page-kominka_vol_1 .contact__list {
  text-align: center;
}

body.page-template-page-kominka_vol_1 .contact__list__item {
  display: inline-block;
  width: 360px;
  font-size: 1.5rem;
  margin: 0 10px;
}

@media screen and (max-width: 1023px) {
  body.page-template-page-kominka_vol_1 .contact__list__item {
    margin: 5px 0;
    width: 270px;
    font-size: 1rem;
  }
}

body.page-template-page-kominka_vol_1 .contact__list__item a {
  color: #fff;
  display: block;
  padding: 10px 0;
  border: 1px solid #fff;
}

/* -------------------- 古民家ヘッダー -------------------- */
/* -------------------- 古民家フッター -------------------- */
.kominka-footer__list {
  text-align: center;
  margin-bottom: 30px;
}

.kominka-footer__list__item {
  display: inline-block;
  margin: 0 20px;
}

@media screen and (max-width: 1023px) {
  .kominka-footer__list__item {
    font-size: .75rem;
    margin: 0 5px;
  }
}

.kominka-footer .copy {
  display: block;
  text-align: center;
  background: #000;
  color: #fff;
  padding: 15px 0;
}

@media screen and (max-width: 1023px) {
  .kominka-footer .copy {
    font-size: .75rem;
  }
}

.kominka-sns {
  text-align: center;
  margin-bottom: 45px;
}

.kominka-sns__list__item {
  display: inline-block;
  line-height: 1;
  vertical-align: top;
  margin: 0 5px;
}

@media screen and (max-width: 767px) {
  .kominka-sns__list__item {
    margin: 5px 5px;
  }
}

/* -------------------- Variables -------------------- */
/* -------------------- import -------------------- */
body.page-template-page-bunjou_2 {
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  margin: 0;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
}

.bunjou .bunjou-02__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  opacity: 1;
  z-index: 888;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-02__header {
    height: 40px;
  }
}

.bunjou .bunjou-02__header__logo {
  float: left;
  margin-left: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-02__header__logo {
    margin-top: 6px;
  }
}

.bunjou .bunjou-02__header__logo img {
  height: 50px;
  width: auto;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-02__header__logo img {
    height: 30px;
  }
}

.bunjou .bunjou-02__header__nav {
  float: right;
  margin-top: 15px;
}

@media screen and (max-width: 1023px) {
  .bunjou .bunjou-02__header__nav {
    display: none;
  }
}

.bunjou .bunjou-02__header__nav .bunjou-02__header__nav__list {
  line-height: 4.5;
  font-size: 12px;
}

.bunjou .bunjou-02__header__nav .bunjou-02__header__nav__list__item {
  display: inline-block;
  margin-right: 15px;
}

@media screen and (max-width: 1023px) {
  .bunjou .bunjou-02__header__nav .bunjou-02__header__nav__list__item {
    margin-right: 7px;
  }
}

.bunjou .bunjou-02__header_flag {
  float: right;
  margin-right: 30px;
  margin-top: 15px;
}

@media screen and (max-width: 1023px) {
  .bunjou .bunjou-02__header_flag {
    display: none;
  }
}

.bunjou .bunjou-02__header_flag__list {
  margin-top: 8px;
  font-size: 16px;
}

.bunjou .bunjou-02__header_flag__list__item--blue {
  display: inline-block;
  background-color: #5454ff;
  padding: 5px 15px;
  margin-right: 5px;
}

.bunjou .bunjou-02__header_flag__list__item--blue a {
  color: #fff;
}

.bunjou .bunjou-02__header_flag__list__item--red {
  display: inline-block;
  background-color: #e94e4e;
  padding: 5px 25px;
}

.bunjou .bunjou-02__header_flag__list__item--red a {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-0 .sp-display-none {
    display: none;
  }
}

.bunjou .section-0 a {
  display: block;
  font-size: 0px;
}

.bunjou .section-0 a:hover {
  cursor: zoom-in !important;
}

.bunjou .section-0__list {
  width: 100%;
}

.bunjou .section-0__list::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou .section-0__list .abusolute {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bunjou .section-0__list .abusolute p {
  font-size: 2.0rem;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-0__list .abusolute p {
    font-size: 1.0rem;
  }
}

.bunjou .section-0__list .relative {
  position: relative;
  font-size: 0px;
}

.bunjou .section-0__list__item {
  width: 50%;
  float: left;
  font-size: 0px;
}

.bunjou .section-0__list__item_pc {
  width: 50%;
  float: left;
}

@media screen and (max-width: 767px) {
  .bunjou .section-0__list__item_pc {
    width: 100%;
  }
}

.bunjou .section-0__list__item_pc video {
  width: 100%;
}

.bunjou .bunjo-02-section-1 {
  background: none !important;
}

.header_after_02 {
  width: 100%;
  height: 80px;
}

@media screen and (max-width: 767px) {
  .header_after_02 {
    height: 40px;
  }
}

.section-5 .section-5-bunjou-heading--bgcolor--2 {
  background: none !important;
}

.section-5 .section-5-bunjou-heading--large--2 {
  font-size: 54px !important;
}

@media screen and (max-width: 1023px) {
  .section-5 .section-5-bunjou-heading--large--2 {
    font-size: 20px !important;
  }
}

.section-5 .bunjou-heading--h4--large--2 {
  font-size: 18px !important;
}

@media screen and (max-width: 1023px) {
  .section-5 .bunjou-heading--h4--large--2 {
    font-size: 12px !important;
  }
}

.section-6 .access__movie {
  margin-bottom: 40px;
}

@media screen and (max-width: 1023px) {
  .section-6 .section-6__movie__width {
    width: 100%;
  }
}

.section-7 .section-7__bukken-02, .section-7 .section-7__bukken-02--1, .section-7 .section-7__bukken-02--2, .section-7 .section-7__bukken-02--3, .section-7 .section-7__bukken-02--4, .section-7 .section-7__bukken-02--5 {
  padding: 50px 0px;
}

.section-7 .section-7__bukken-02::after, .section-7 .section-7__bukken-02--1::after, .section-7 .section-7__bukken-02--2::after, .section-7 .section-7__bukken-02--3::after, .section-7 .section-7__bukken-02--4::after, .section-7 .section-7__bukken-02--5::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02, .section-7 .section-7__bukken-02--1, .section-7 .section-7__bukken-02--2, .section-7 .section-7__bukken-02--3, .section-7 .section-7__bukken-02--4, .section-7 .section-7__bukken-02--5 {
    padding: 30px 0px;
  }
}

.section-7 .section-7__bukken-02--1 {
  background-color: #edf2f8;
}

.section-7 .section-7__bukken-02--2 {
  background-color: #ecfaef;
}

.section-7 .section-7__bukken-02--3 {
  background-color: #fff8f2;
}

.section-7 .section-7__bukken-02--4 {
  background-color: #ecfaef;
}

.section-7 .section-7__bukken-02--5 {
  background-color: #edf2f8;
}

.section-7 .section-7__bukken-02--clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.section-7 .section-7__bukken-02__img--1 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo_2/mitori_1_0422.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__img--1 {
    float: none;
    width: 584px;
    height: 462px;
    background: url("./assets/img/bunjo_2/mitori_1_sp_0422.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__img--1 {
    width: 292px;
    height: 231px;
  }
}

.section-7 .section-7__bukken-02__img--2 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo_2/mitori_2_0422.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__img--2 {
    float: none;
    width: 584px;
    height: 462px;
    background: url("./assets/img/bunjo_2/mitori_2_sp_0422.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__img--2 {
    width: 292px;
    height: 231px;
  }
}

.section-7 .section-7__bukken-02__img--3 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo_2/mitori_3_0422.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__img--3 {
    float: none;
    width: 584px;
    height: 462px;
    background: url("./assets/img/bunjo_2/mitori_3_sp_0422.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__img--3 {
    width: 292px;
    height: 231px;
  }
}

.section-7 .section-7__bukken-02__img--4 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo_2/mitori_4_0422.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__img--4 {
    float: none;
    width: 584px;
    height: 462px;
    background: url("./assets/img/bunjo_2/mitori_4_sp_0422.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__img--4 {
    width: 292px;
    height: 231px;
  }
}

.section-7 .section-7__bukken-02__img--5 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo_2/mitori_5_0422.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__img--5 {
    float: none;
    width: 584px;
    height: 462px;
    background: url("./assets/img/bunjo_2/mitori_5_sp_0422.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__img--5 {
    width: 292px;
    height: 231px;
  }
}

.section-7 .section-7__bukken-02__overflow {
  width: 100%;
  margin-bottom: 50px;
  border: 1px solid #78c39c;
  background-color: #fff;
  padding: 40px 0px 35px 30px;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__overflow {
    width: 584px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 20px 0px 10px 20px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__overflow {
    padding: 20px 10px 10px;
    width: 292px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--title {
  display: inline-block;
  background-color: #999999;
  color: #fff;
  font-weight: normal;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0px 7px;
  margin-bottom: 15px;
  margin-right: 5px;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--title {
    font-size: 12px;
    margin-bottom: 15px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--title {
    margin-right: 10px;
    width: 98px;
    padding: 0px;
    font-size: 11px;
  }
}

.section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--content {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--content {
    font-size: 12px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--content {
    margin-right: 0px;
    font-size: 11px;
    width: calc( 100% - 120px);
  }
}

@media screen and (min-width: 1024px) {
  .section-7 .section-7__bukken-02__overflow .section-7__bukken-02__overflow--content:nth-child(8) {
    margin-right: 70px;
  }
}

.section-7 .section-7__bukken-02__txt {
  float: left;
  width: 563px;
  height: 305px;
  padding: 30px 20px;
  border: 1px solid #78c39c;
  background-color: #fff;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__txt {
    width: 584px;
    height: auto;
    float: none;
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__txt {
    width: 292px;
    height: auto;
    padding: 30px 20px 25px;
  }
}

.section-7 .section-7__bukken-02__txt--h3 {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__txt--h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__txt--h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.section-7 .section-7__bukken-02__txt--dl {
  display: block;
  margin-bottom: 20px;
  margin-left: 10px;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__txt--dl {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__txt--dl {
    width: 100%;
  }
}

.section-7 .section-7__bukken-02__txt--title {
  font-size: 18px;
  display: inline-block;
  background-color: #999999;
  color: #fff;
  font-weight: normal;
  width: 140px;
  line-height: 1.8;
  text-align: center;
  margin-right: 10px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__txt--title {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__txt--title {
    margin-right: 10px;
    width: 90px;
    font-size: 12px;
  }
}

.section-7 .section-7__bukken-02__txt--content {
  font-size: 18px;
  display: inline-block;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1023px) {
  .section-7 .section-7__bukken-02__txt--content {
    font-size: 14px;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 767px) {
  .section-7 .section-7__bukken-02__txt--content {
    font-size: 12px;
  }
}

.fixed-left {
  position: fixed;
  top: 50%;
  left: 50px;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  opacity: 0.6;
}

@media screen and (max-width: 1023px) {
  .fixed-left {
    display: none;
  }
}

.fixed-left #menu li a::before {
  content: "";
  display: block;
  background-image: url("./assets/img/bunjo_2/circle-regular.svg");
  width: 13px;
  height: 13px;
  margin-bottom: 0px;
}

.fixed-left #menu li a::after {
  position: relative;
  top: 0px;
  left: 6px;
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  background-color: #e94e4e;
}

.fixed-left #menu li:last-child a::after {
  display: none;
}

.fixed-left #menu li .on::before {
  background-image: url("./assets/img/bunjo_2/circle-solid.svg");
}

/* -------------------- common -------------------- */
body.page-template-page-bunjou {
  background: url(./assets/img/bunjo/kominka/vol01/bg_normal.jpg) repeat center center;
  margin: 0;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
}

.bunjou .bunjou-heading {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-heading {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

.bunjou .bunjou-heading:before {
  width: 50px;
  height: 1px;
  background: #333333;
  content: "";
  display: inline-block;
  margin-bottom: 9px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-heading:before {
    margin-bottom: 6px;
    width: 25px;
  }
}

.bunjou .bunjou-heading:after {
  width: 50px;
  height: 1px;
  background: #333333;
  content: "";
  display: inline-block;
  margin-bottom: 9px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-heading:after {
    margin-bottom: 6px;
    width: 25px;
  }
}

.bunjou .section-5-bunjou-heading {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou .section-5-bunjou-heading {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.bunjou .section-5-bunjou-heading--large {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 26px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  color: #EB6767;
}

@media screen and (max-width: 767px) {
  .bunjou .section-5-bunjou-heading--large {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.bunjou .section-5-bunjou-heading--bgcolor {
  padding: 40px 0px;
  background-color: #f5f5f5;
  margin: 20px 0;
}

.bunjou .bunjou-heading--h4, .bunjou .bunjou-heading--h4--large {
  text-align: center;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  margin-bottom: 30px;
  font-size: 14px;
}

.bunjou .bunjou-heading--h4--large {
  color: #EB6767;
  font-size: 14px;
  margin-bottom: 0px;
  letter-spacing: 0.05em;
}

.bunjou .bunjou-heading--time {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
}

.bunjou .bunjou-heading--time .red {
  color: #EB6767;
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou-heading--time {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.bunjou .section {
  padding-top: 50px;
}

@media screen and (max-width: 1023px) {
  .bunjou .section {
    padding-top: 30px;
  }
}

.bunjou .content-width {
  width: 1020px;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .bunjou .content-width {
    width: 100%;
    padding: 0px 15px;
  }
}

/* -------------------- 嘉川 -------------------- */
.bunjou__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 53px;
  background-color: #fff;
  opacity: 0.85;
  z-index: 888;
}

@media screen and (max-width: 767px) {
  .bunjou__header {
    height: 40px;
  }
}

.bunjou__header::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou__header .bunjou__header__logo {
  float: left;
  margin-left: 10px;
  margin-top: 2px;
}

.bunjou__header .bunjou__header__logo img {
  height: 50px;
  width: auto;
}

@media screen and (max-width: 767px) {
  .bunjou__header .bunjou__header__logo img {
    height: 30px;
  }
}

.bunjou__header .bunjou__header__nav {
  float: right;
}

@media screen and (max-width: 1023px) {
  .bunjou__header .bunjou__header__nav {
    display: none;
  }
}

.bunjou__header .bunjou__header__nav .bunjou__header__nav__list {
  line-height: 4.5;
  font-size: 12px;
}

.bunjou__header .bunjou__header__nav .bunjou__header__nav__list__item {
  display: inline-block;
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .bunjou__header .bunjou__header__nav .bunjou__header__nav__list__item {
    margin-right: 7px;
  }
}

.bunjou__header .bunjou__header_flag {
  float: right;
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .bunjou__header .bunjou__header_flag {
    display: none;
  }
}

.bunjou__header .bunjou__header_flag__list {
  margin-top: 8px;
  font-size: 16px;
}

.bunjou__header .bunjou__header_flag__list__item--blue {
  display: inline-block;
  background-color: #5454ff;
  padding: 5px 15px;
  margin-right: 5px;
}

.bunjou__header .bunjou__header_flag__list__item--blue a {
  color: #fff;
}

.bunjou__header .bunjou__header_flag__list__item--red {
  display: inline-block;
  background-color: #e94e4e;
  padding: 5px 15px;
}

.bunjou__header .bunjou__header_flag__list__item--red a {
  color: #fff;
}

.bunjou .banner-sp {
  display: none;
}

@media screen and (max-width: 1023px) {
  .bunjou .banner-sp {
    display: block;
  }
}

.bunjou .banner-sp .drawer-hamburger {
  padding-top: 8px;
  width: 1.8rem;
}

.bunjou .banner-sp .drawer-menu-item {
  text-align: center;
}

.bunjou .banner-sp .drawer-menu-item:hover {
  text-decoration: none;
}

.bunjou .header_after {
  width: 100%;
  height: 53px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou .header_after {
    height: 40px;
  }
}

.bunjou .kanbai-img {
  text-align: center;
  margin-bottom: 90px;
}

@media screen and (max-width: 767px) {
  .bunjou .kanbai-img {
    margin-bottom: 50px;
  }
}

.bunjou .kanbai-img img {
  width: 100%;
}

.bunjou .main-visual, .bunjou .sub-visual {
  height: auto;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
  top: 0;
  border-bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 1024px) {
  .bunjou .main-visual, .bunjou .sub-visual {
    height: 574px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou .main-visual, .bunjou .sub-visual {
    width: 100%;
    padding: 0px;
    height: auto;
    margin-bottom: 20px;
  }
}

.bunjou .main-visual__movie, .bunjou .sub-visual__movie {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .bunjou .main-visual__movie, .bunjou .sub-visual__movie {
    height: 800px;
  }
}

.bunjou .main-visual__movie video, .bunjou .sub-visual__movie video {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.bunjou .main-visual__dot {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 800px;
  background: url("./assets/img/bunjo/pattern.png");
  z-index: 100;
}

@media screen and (max-width: 767px) {
  .bunjou .main-visual__dot {
    display: none;
  }
}

.bunjou .main-visual .main-visual__txt, .bunjou .sub-visual .main-visual__txt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 30px;
  width: 700px;
  text-align: center;
  background-color: #fff;
  opacity: 0.7;
}

@media screen and (max-width: 1023px) {
  .bunjou .main-visual .main-visual__txt, .bunjou .sub-visual .main-visual__txt {
    padding: 10px 10px;
    width: 80%;
    font-size: 16px;
    top: 60%;
  }
}

.bunjou .main-visual .main-visual__txt .main-visual__txt--p, .bunjou .sub-visual .main-visual__txt .main-visual__txt--p {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  color: #000;
}

@media screen and (max-width: 1023px) {
  .bunjou .main-visual .main-visual__txt .main-visual__txt--p, .bunjou .sub-visual .main-visual__txt .main-visual__txt--p {
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.05em;
  }
}

.bunjou .main-visual .main-visual__txt--title, .bunjou .sub-visual .main-visual__txt--title {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 10;
  background-color: #eb6767;
  opacity: 0.7;
  padding: 30px;
  width: 700px;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .bunjou .main-visual .main-visual__txt--title, .bunjou .sub-visual .main-visual__txt--title {
    padding: 10px 10px;
    width: 100%;
    font-size: 16px;
  }
}

.bunjou .main-visual .main-visual__txt--title .main-visual__txt--title--p, .bunjou .sub-visual .main-visual__txt--title .main-visual__txt--title--p {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .bunjou .main-visual .main-visual__txt--title .main-visual__txt--title--p, .bunjou .sub-visual .main-visual__txt--title .main-visual__txt--title--p {
    font-size: 18px;
    text-align: center;
  }
}

.bunjou .main-visual #main-visual__txt--p--1, .bunjou .sub-visual #main-visual__txt--p--1 {
  display: block;
}

.bunjou .main-visual #main-visual__txt--p--2, .bunjou .sub-visual #main-visual__txt--p--2 {
  display: none;
}

.bunjou .main-visual #main-visual__txt--p--3, .bunjou .sub-visual #main-visual__txt--p--3 {
  display: none;
}

.bunjou .bunjou__gallery {
  margin-bottom: 50px;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .bunjou .bunjou__gallery {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .bunjou .bunjou__gallery {
    margin-bottom: 20px;
  }
}

.bunjou .bunjou__gallery .slick-slide {
  height: auto;
}

@media screen and (min-width: 1024px) {
  .bunjou .bunjou__gallery__item img {
    width: 100%;
  }
}

.bunjou .section-1 {
  background: url("./assets/img/bunjo/section-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-1 {
    background: url("./assets/img/bunjo/section-1-sp.jpg");
    padding-bottom: 30px;
  }
}

.bunjou .section-1 .section-1__concept {
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 420px;
  line-height: 3;
}

@media screen and (max-width: 767px) {
  .bunjou .section-1 .section-1__concept {
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 2;
    width: 100%;
  }
}

.bunjou .section-2__list::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou .section-2__list .section-2__list__item, .bunjou .section-2__list .section-2__list__item--1, .bunjou .section-2__list .section-2__list__item--2, .bunjou .section-2__list .section-2__list__item--3, .bunjou .section-2__list .section-2__list__item--4 {
  position: relative;
  width: 50%;
  height: 408px;
  float: left;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .bunjou .section-2__list .section-2__list__item, .bunjou .section-2__list .section-2__list__item--1, .bunjou .section-2__list .section-2__list__item--2, .bunjou .section-2__list .section-2__list__item--3, .bunjou .section-2__list .section-2__list__item--4 {
    width: 100%;
    height: 300px;
  }
}

.bunjou .section-2__list .section-2__list__item__txt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
}

@media screen and (max-width: 767px) {
  .bunjou .section-2__list .section-2__list__item__txt {
    width: 90%;
  }
}

.bunjou .section-2__list .section-2__list__item__txt .section-2__list__item__txt--h3 {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .bunjou .section-2__list .section-2__list__item__txt .section-2__list__item__txt--h3 {
    margin-bottom: 25px;
    font-size: 16px;
  }
}

.bunjou .section-2__list .section-2__list__item__txt p {
  line-height: 2.2;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .bunjou .section-2__list .section-2__list__item__txt p {
    line-height: 2;
  }
}

.bunjou .section-2__list .section-2__list__item--1 {
  background-image: url("./assets/img/bunjo/section-3-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bunjou .section-2__list .section-2__list__item--2 {
  background-image: url("./assets/img/bunjo/section-3-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bunjou .section-2__list .section-2__list__item--3 {
  background-image: url("./assets/img/bunjo/section-3-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bunjou .section-2__list .section-2__list__item--4 {
  background-image: url("./assets/img/bunjo/section-3-4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bunjou .section-3 .section-3__movie {
  width: 1020px;
  height: 474px;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-3 .section-3__movie {
    width: 100%;
    height: 300px;
  }
}

.bunjou .section-4 .bunjou__section-4__list {
  width: 100%;
  margin-bottom: 50px;
}

.bunjou .section-4 .bunjou__section-4__list img {
  width: 100%;
}

.bunjou .section-4 .bunjou__section-4__list .slick-slide {
  height: auto;
}

.bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item__name {
  text-align: center;
  padding: 10px 0px;
  background-color: #edf2f8;
}

.bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item img {
  width: 100%;
}

.bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item__info {
  text-align: center;
  padding: 10px 0px;
  background-color: #edf2f8;
}

.bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item:nth-child(2n) .bunjou__section-4__list__item__name, .bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item:nth-child(2n) .bunjou__section-4__list__item__info {
  background-color: #ecfaef;
}

.bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item:nth-child(3n) .bunjou__section-4__list__item__name, .bunjou .section-4 .bunjou__section-4__list .bunjou__section-4__list__item:nth-child(3n) .bunjou__section-4__list__item__info {
  background-color: #fff8f2;
}

.bunjou .section-4 .bunjou__section-4__txt::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou .section-4 .bunjou__section-4__txt__item {
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .bunjou .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 32.31112%;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:nth-child(3n) {
    margin-right: 0;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (max-width: 1023px) {
  .bunjou .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 49.23334%;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:nth-child(2n) {
    margin-right: 0;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (max-width: 767px) {
  .bunjou .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou .section-4 .bunjou__section-4__txt__item:nth-child(n) {
    margin-right: 0;
  }
}

.bunjou .section-4 .bunjou__section-4__txt__item--h3 {
  font-size: 16px;
  font-weight: bold;
  background-color: #999999;
  color: #fff;
  text-align: center;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  padding: 6px 0px;
  letter-spacing: 0.5em;
}

@media screen and (max-width: 767px) {
  .bunjou .section-4 .bunjou__section-4__txt__item--h3 {
    letter-spacing: 0.1em;
  }
}

.bunjou .section-4 .bunjou__section-4__txt__item__table {
  display: table;
  font-size: 14px;
  margin: 0 auto;
}

.bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dt {
  width: 300px;
  display: table-cell;
  font-weight: normal;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dt {
    width: 140px;
  }
}

.bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line {
  margin-right: 5px;
}

.bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 60px;
  border-bottom: 1px solid #000;
  height: 1px;
  position: relative;
  bottom: 5px;
}

@media screen and (max-width: 767px) {
  .bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line:after {
    width: 30px;
  }
}

.bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dd {
  display: table-cell;
  text-align: right;
  width: 128px;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dd {
    width: 70px;
  }
}

.bunjou .section-5__fist {
  margin-bottom: 50px;
}

.bunjou .section-6-map {
  height: 450px;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-6-map {
    height: 300px;
  }
}

.bunjou .section-7 {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 {
    margin-bottom: 20px;
  }
}

.bunjou .section-7 .section-7__bukken, .bunjou .section-7 .section-7__bukken--1, .bunjou .section-7 .section-7__bukken--2, .bunjou .section-7 .section-7__bukken--3 {
  padding: 50px 0px;
}

.bunjou .section-7 .section-7__bukken::after, .bunjou .section-7 .section-7__bukken--1::after, .bunjou .section-7 .section-7__bukken--2::after, .bunjou .section-7 .section-7__bukken--3::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken, .bunjou .section-7 .section-7__bukken--1, .bunjou .section-7 .section-7__bukken--2, .bunjou .section-7 .section-7__bukken--3 {
    padding: 30px 0px;
  }
}

.bunjou .section-7 .section-7__bukken__img-pc {
  margin-bottom: -14px;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__img-pc {
    display: none;
  }
}

.bunjou .section-7 .section-7__bukken__img-sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__img-sp {
    display: block;
    margin-top: 10px;
  }
}

.bunjou .section-7 .section-7__bukken--1 {
  background-color: #edf2f8;
}

.bunjou .section-7 .section-7__bukken--2 {
  background-color: #ecfaef;
}

.bunjou .section-7 .section-7__bukken--3 {
  background-color: #fff8f2;
}

.bunjou .section-7 .section-7__bukken--clearfix {
  margin-bottom: 40px;
}

.bunjou .section-7 .section-7__bukken--clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou .section-7 .section-7__bukken__img--1 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo/bunjyou_1_3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__img--1 {
    float: none;
    width: 292px;
    height: 211px;
    background: url("./assets/img/bunjo/bunjyou_1_3_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

.bunjou .section-7 .section-7__bukken__img--2 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo/bunjyou_1_4.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__img--2 {
    float: none;
    width: 290px;
    height: 209px;
    background: url("./assets/img/bunjo/bunjyou_1_4_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

.bunjou .section-7 .section-7__bukken__img--3 {
  float: left;
  margin-right: 0px;
  width: 457px;
  height: 306px;
  background: url("./assets/img/bunjo/bunjyou_1_5.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__img--3 {
    float: none;
    width: 290px;
    height: 209px;
    background: url("./assets/img/bunjo/bunjyou_1_5_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 0px;
    margin: 0 auto;
  }
}

.bunjou .section-7 .section-7__bukken__txt {
  float: left;
  width: 563px;
  height: 290px;
  padding: 30px;
  border: 2px solid #323333;
  margin-top: 7px;
  background-color: #fff;
}

@media screen and (max-width: 1023px) {
  .bunjou .section-7 .section-7__bukken__txt {
    width: auto;
    height: auto;
    float: none;
    padding: 10px;
    margin-left: 0px;
    margin-top: 0px;
    border: 1px solid #323333;
  }
}

.bunjou .section-7 .section-7__bukken__txt .section-7__bukken__txt--h3 {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
}

.bunjou .section-7 .section-7__bukken__txt .section-7__bukken__txt--dl {
  display: block;
  margin-bottom: 10px;
}

.bunjou .section-7 .section-7__bukken__txt .section-7__bukken__txt--title {
  display: inline-block;
  background-color: #999999;
  color: #fff;
  font-weight: normal;
  width: 90px;
  line-height: 2.0;
  text-align: center;
  margin-right: 20px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__bukken__txt .section-7__bukken__txt--title {
    margin-right: 0px;
    width: 70px;
    font-size: 12px;
  }
}

.bunjou .section-7 .section-7__bukken__txt .section-7__bukken__txt--content {
  display: inline-block;
  font-size: 12px;
}

.bunjou .section-7 .section-7__btn {
  text-align: center;
}

.bunjou .section-7 .section-7__btn a {
  font-size: 18px;
  color: #e07373;
  line-height: 3;
  border: 1px solid #e07373;
  padding: 20px 80px;
  letter-spacing: 0.1em;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .bunjou .section-7 .section-7__btn a {
    padding: 15px 60px;
  }
}

.bunjou .section-7 .section-7__btn a:hover {
  background-color: #e07373;
  color: #fff;
}

.bunjou .bukken__contanct {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou .bukken__contanct {
    display: none;
  }
}

.bunjou .bukken__contanct .content-width__contact {
  width: 650px;
  margin: 0 auto;
}

.bunjou .bukken__contanct .content-width__contact::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .bunjou .bukken__contanct .content-width__contact {
    width: 100%;
  }
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__left {
  width: 360px;
  float: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border: 1px solid #7d7d7d;
  border-radius: 4px;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__left__heading {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 5px;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__left__tel {
  display: block;
  text-align: center;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__left__time {
  text-align: center;
  font-size: 0.8125rem;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__left {
    width: 100%;
    float: none;
  }
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right a {
  display: block;
  padding: 10px;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right__heading {
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.25rem;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right__txt {
  color: #fff;
  font-size: 1rem;
  line-height: 1.4;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right__icon-mail {
  position: absolute;
  right: 15px;
  bottom: 20px;
}

.bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right {
  position: relative;
  width: 280px;
  float: left;
  background: #c70000;
  border-radius: 4px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou .bukken__contanct .content-width__contact .lower-include-contact__container__right {
    margin-left: 0px;
    float: none;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .bunjou .bukken__contanct .bukken__contanct__title {
    font-size: 24px;
    letter-spacing: 0.1em;
    font-weight: bold;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
    text-align: center;
    margin-bottom: 30px;
  }
}

body.page-template-page-bunjou_3 {
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  background: none;
  margin: 0;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
}

.bunjou_kurokawa .txt-center {
  text-align: center;
}

.bunjou_kurokawa .clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou_kurokawa .section {
  padding: 50px 0px;
}

.bunjou_kurokawa .content-width {
  width: 980px;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .content-width {
    width: 100%;
    padding: 0px 10px;
  }
}

.bunjou_kurokawa .col-2-1 {
  float: left;
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
}

.bunjou_kurokawa .col-2-1:nth-child(2n) {
  margin-right: 0;
}

.bunjou_kurokawa .col-2-1:nth-child(2n+1) {
  clear: left;
}

.bunjou_kurokawa .col-2-1:last-child {
  margin-right: 0;
}

.bunjou_kurokawa .col-2-1 img {
  width: 100%;
}

.bunjou_kurokawa .col-3-1 {
  float: left;
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 32.31112%;
}

.bunjou_kurokawa .col-3-1:nth-child(3n) {
  margin-right: 0;
}

.bunjou_kurokawa .col-3-1:nth-child(3n+1) {
  clear: left;
}

.bunjou_kurokawa .col-3-1:last-child {
  margin-right: 0;
}

.bunjou_kurokawa .col-3-1 img {
  width: 100%;
}

.bunjou_kurokawa .col-4-1 {
  float: left;
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 23.85001%;
}

.bunjou_kurokawa .col-4-1:nth-child(4n) {
  margin-right: 0;
}

.bunjou_kurokawa .col-4-1:nth-child(4n+1) {
  clear: left;
}

.bunjou_kurokawa .col-4-1:last-child {
  margin-right: 0;
}

.bunjou_kurokawa .col-4-1 img {
  width: 100%;
}

.bunjou_kurokawa .gray {
  color: #aaaaaa;
}

.bunjou_kurokawa .bunjou_kurokawa__blue {
  background-color: #2173b7;
  color: #fff;
  padding: 25px 0px 30px;
}

.bunjou_kurokawa .bunjou_kurokawa__blue h1 {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 30px;
  letter-spacing: 0.05em;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__blue h1 {
    font-size: 16px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__blue h1 .small {
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__blue h1 .small {
    font-size: 10px;
    margin-left: 5px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__blue .border-white {
  border: 1px solid #fff;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  padding: 5px 15px;
  font-size: 24px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__blue .border-white {
    padding: 5px 10px 3px;
    font-size: 12px;
    margin-right: 5px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__blue p {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  display: inline-block;
  font-size: 28px;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__blue p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__blue p .yellow {
  color: #fffb00;
  letter-spacing: 0.01em;
}

.bunjou_kurokawa .bunjou_kurokawa__blue .attention {
  display: inline-block;
  font-size: 10px;
  text-align: left;
  margin-left: 10px;
}

.bunjou_kurokawa .font-gothic {
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
}

.bunjou_kurokawa .font-mincho {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}

.bunjou_kurokawa .bunjou_kurokawa__title {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.05em;
}

.bunjou_kurokawa .bunjou_kurokawa__title:first-letter {
  color: #2173b7;
  font-size: 30px;
}

.bunjou_kurokawa .bunjou_kurokawa__title__small {
  letter-spacing: 0.01em;
  display: block;
  font-size: 10px;
  color: #aaa;
}

.bunjou_kurokawa .mb70 {
  margin-bottom: 70px;
}

.bunjou_kurokawa .mb50 {
  margin-bottom: 50px !important;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .mb50 {
    margin-bottom: 30px !important;
  }
}

.bunjou_kurokawa .mb30 {
  margin-bottom: 30px;
}

.bunjou_kurokawa .mb10 {
  margin-bottom: 10px !important;
}

.bunjou_kurokawa .mb0 {
  margin-bottom: 0px !important;
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3 {
  font-size: 24px;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  line-height: 1.6;
  font-weight: normal;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__title-h3 {
    font-size: 16px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3--bgblue {
  position: relative;
  background-color: #2173b7;
  font-weight: normal;
  font-size: 18px;
  color: #fff;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  padding: 5px 10px;
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3--bgblue span {
  position: absolute;
  top: 9px;
  right: 10px;
  font-size: 11px;
  color: #fffb00;
  text-align: right;
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3--detail {
  text-align: center;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 28px;
  letter-spacing: 0.05em;
  color: #2173b7;
  font-weight: normal;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__title-h3--detail {
    font-size: 16px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3--detail:before {
  content: "";
  display: inline-block;
  background-image: url("./assets/img/bunjo_kurokawa/detail-title-left.png");
  width: 60px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  top: 5px;
  position: relative;
}

.bunjou_kurokawa .bunjou_kurokawa__title-h3--detail:after {
  content: "";
  display: inline-block;
  background-image: url("./assets/img/bunjo_kurokawa/detail-title-right.png");
  width: 60px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 10px;
  top: 5px;
  position: relative;
}

.bunjou_kurokawa .border-bottom-line {
  display: block;
  position: relative;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 60px;
  height: 1px;
  background-color: #2173b7;
}

.bunjou_kurokawa .bunjou_kurokawa__header {
  width: 100%;
  height: 76px;
  background: #fff;
  border-bottom: 1px solid #2173b7;
}

.bunjou_kurokawa .bunjou_kurokawa__header::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou_kurokawa .bunjou_kurokawa__header__logo {
  float: left;
  padding: 15px 0px 15px 0px;
}

.bunjou_kurokawa .bunjou_kurokawa__header__logo a {
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #333;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  color: #2173b7;
}

.bunjou_kurokawa .bunjou_kurokawa__header__logo a span {
  font-size: 15px;
  position: relative;
  bottom: 5px;
  margin-right: 4px;
  font-weight: normal;
}

.bunjou_kurokawa .bunjou_kurokawa__header__nav {
  float: right;
  padding: 15px 0px 0px 0px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .bunjou_kurokawa__header__nav {
    display: none;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__header__nav ul li {
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  font-size: 14px;
}

.bunjou_kurokawa .bunjou_kurokawa__header__nav ul li a {
  display: block;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}

.bunjou_kurokawa .bunjou_kurokawa__header__nav ul li a:first-letter {
  color: #2173b7;
  font-size: 18px;
}

.bunjou_kurokawa .bunjou_kurokawa__header__nav ul li a span {
  display: block;
  font-size: 10px;
  color: #aaa;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp {
  display: none;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .bunjou_kurokawa__header .header-sp {
    display: block;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-hamburger {
  top: 9px;
  z-index: 10000;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav {
  display: none;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav .drawer-menu li {
  display: block;
  text-align: center;
  margin-right: 8px;
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav .drawer-menu li a {
  display: block;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav .drawer-menu li a:first-letter {
  color: #2173b7;
  font-size: 18px;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav .drawer-menu li a:hover {
  text-decoration: none;
}

.bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav .drawer-menu li a span {
  display: block;
  font-size: 10px;
  color: #aaa;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .bunjou_kurokawa__header .header-sp .drawer-nav {
    display: block;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__top-img {
  font-size: 0px;
  overflow: hidden;
  position: relative;
}

.bunjou_kurokawa .bunjou_kurokawa__top-img .postion-ab {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 40%;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__top-img .postion-ab {
    width: 70%;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__top-img .bg {
  animation-duration: 15s;
  /* アニメーション時間 */
  animation-name: ZoomTop;
  /* アニメーション名 */
  animation-iteration-count: 1;
  /* アニメーションの繰り返し（無限）*/
  animation-fill-mode: forwards;
}

.bunjou_kurokawa .bunjou_kurokawa__top-img img {
  width: 100%;
}

.bunjou_kurokawa .section-1 .bunjou_kurokawa__title {
  font-size: 34px;
  margin-bottom: 15px;
}

.bunjou_kurokawa .section-1 .bunjou_kurokawa__title:first-letter {
  color: #2173b7;
  font-size: 38px;
}

.bunjou_kurokawa .section-1 .bunjou_kurokawa__title-h3 {
  font-size: 26px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .bunjou_kurokawa__title-h3 {
    font-size: 16px;
  }
}

.bunjou_kurokawa .section-1 .bunjou_kurokawa__title__small {
  margin-top: 3px;
}

.bunjou_kurokawa .section-1 .concept_wrap {
  display: table;
}

.bunjou_kurokawa .section-1 .concept_wrap__list {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .concept_wrap__list {
    width: 100%;
    display: block;
  }
}

.bunjou_kurokawa .section-1 .concept_wrap__list__item {
  width: 515px;
  margin: 0 auto;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .concept_wrap__list__item {
    width: 100%;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .concept_wrap__list__item .charm {
    margin-bottom: 30px;
  }
}

.bunjou_kurokawa .section-1 .concept_wrap__list__item .concept {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.bunjou_kurokawa .section-1 .concept_wrap__list__item .small {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .concept_wrap__list__item .small {
    font-size: 10px;
  }
}

.bunjou_kurokawa .section-1 .concept_wrap__list__item .large {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  letter-spacing: 0.05em;
  font-size: 12px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-1 .concept_wrap__list__item .large {
    font-size: 10px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation {
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__wrap {
  display: table;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__wrap {
    display: block;
    text-align: center;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__title {
  width: 20%;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid #999;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__title {
    width: 100%;
    display: block;
    border-right: none;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__txt {
  width: 50%;
  display: table-cell;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__txt {
    margin-top: 15px;
    width: 100%;
    display: block;
    padding: 0px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__txt__list__item {
  margin-bottom: 5px;
  color: #999;
  font-size: 12px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__txt__list__item {
    font-size: 10px;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__infomation__txt__list__item:last-child {
  margin-bottom: 0px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form {
  display: table-cell;
  border: 1px solid #2173b7;
  padding: 15px 33px;
  width: 32%;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form {
    display: none;
  }
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__title {
  color: #2173b7;
  border-bottom: 1px solid #2173b7;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 8px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__tel {
  text-align: center;
  margin-bottom: 5px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__tel a {
  position: relative;
  color: #2173b7;
  font-weight: bold;
  font-size: 28px;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  letter-spacing: 0.08em;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__mail {
  text-align: center;
  margin: 0px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__mail a {
  font-weight: normal;
  color: #2173b7;
  border-radius: 20px;
  border: 1px solid #2173b7;
  font-size: 12px;
  padding: 5px 24px;
}

.bunjou_kurokawa .bunjou_kurokawa__infomation .bunjou_kurokawa__contact-form__mail a:before {
  content: "";
  display: inline-block;
  background-image: url("./assets/img/bunjo_kurokawa/envelop.svg");
  width: 15px;
  height: 15px;
  color: #2173b7;
  background-size: contain;
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.bunjou_kurokawa .section-2 {
  background-image: url("./assets/img/bunjo_kurokawa/bg-img-view.jpg");
  background-repeat: repeat;
  background-size: contain;
  border-top: 1px solid #333333;
}

.bunjou_kurokawa .section-2:before {
  content: "";
  display: block;
  background-color: #333;
  height: 35px;
  width: 1px;
  margin: 0 auto;
  top: -50px;
  position: relative;
}

.bunjou_kurokawa .section-2 .kukakuzu-main {
  border: 5px solid #fffed9;
  width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-2 .kukakuzu-main {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-2 .kukakuzu-main {
    padding: 10px;
  }
}

.bunjou_kurokawa .section-2 p {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-2 p {
    font-size: 10px;
  }
}

.bunjou_kurokawa .section-2 .attention {
  font-size: 10px;
  color: #aaa;
}

.bunjou_kurokawa .section-3 {
  border-top: 1px solid #333333;
}

.bunjou_kurokawa .section-3:before {
  content: "";
  display: block;
  background-color: #333;
  height: 35px;
  width: 1px;
  margin: 0 auto;
  top: -50px;
  position: relative;
}

.bunjou_kurokawa .section-3 .main-img {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-3 .main-img {
    margin-bottom: 30px;
  }
}

.bunjou_kurokawa .section-3 .main-img a {
  display: inline-block;
  font-size: 10px;
  text-decoration-line: underline;
  color: #aaa;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-3 .main-img a {
    font-size: 8px;
    display: block;
  }
}

.bunjou_kurokawa .section-3 .main-img p {
  display: inline-block;
  margin-right: 15px;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-3 .main-img p {
    font-size: 10px;
    margin: 0px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-3 .col-2-1 {
    width: 100%;
    margin: 0px;
  }
}

.bunjou_kurokawa .section-3 .bunjou_kurokawa__location__list {
  margin-bottom: 15px;
}

.bunjou_kurokawa .section-3 .bunjou_kurokawa__location__list::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou_kurokawa .section-3 .bunjou_kurokawa__location__list__item {
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  position: relative;
  border-bottom: 1px dashed #dddddd;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.bunjou_kurokawa .section-3 .bunjou_kurokawa__location__list__item span {
  position: absolute;
  right: 5px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-3 .bunjou_kurokawa__location__list__item {
    font-size: 10px;
  }
}

.bunjou_kurokawa .section-4 {
  background-color: #e8f1f8;
  border-top: 1px solid #2173b7;
}

.bunjou_kurokawa .section-4:before {
  content: "";
  display: block;
  background-color: #2173b7;
  height: 35px;
  width: 1px;
  margin: 0 auto;
  top: -50px;
  position: relative;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap {
  background-color: #2173b7;
  padding: 40px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 28px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap {
    padding: 15px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main {
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main {
    margin-bottom: 20px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dl {
  display: table;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt, .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dd {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt, .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dd {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt, .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dd {
    display: block;
    font-size: 14px;
    text-align: center;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt {
  margin-right: 10px;
  font-weight: normal;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt:before {
  content: "■";
  display: inline-block;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dt {
    margin: 0px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__main dd .large {
  font-size: 30px;
  color: #fffb00;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  margin-right: 5px;
  letter-spacing: 0.05em;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents {
  display: table;
  width: 100%;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail {
  display: table-cell;
  width: 52%;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail {
    display: block;
    width: 100%;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl {
    font-size: 10px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl {
    padding-bottom: 15px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dt, .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dd {
  float: left;
  margin-bottom: 5px;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dt {
  text-align: left;
  font-weight: normal;
  width: 18%;
  padding-left: 7px;
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dt:before {
  content: "■";
  display: inline-block;
  margin-right: 1px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dt {
    width: auto;
    margin-right: 5px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dd {
  width: 32%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl dd {
    width: auto;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl .large {
  width: 82%;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail dl .large {
    width: auto;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail .point {
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  padding-left: 18%;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__detail .point {
    font-size: 12px;
  }
}

.bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__img {
  display: table-cell;
  width: 46%;
  padding-left: 2%;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-4 .bunjou_kurokawa__detail__wrap .bunjou_kurokawa__detail__contents .bunjou_kurokawa__detail__img {
    display: block;
    width: 100%;
  }
}

.bunjou_kurokawa .section-5 .access-map {
  width: 100%;
  height: 450px;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__web {
    width: 100%;
    margin-bottom: 20px;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .must {
  background-color: red;
  color: #fff;
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 10px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact label {
  margin-bottom: 10px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact input {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  padding: 3px 10px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .wpcf7-submit {
  border-radius: 20px;
  padding: 8px 32px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .wpcf7-not-valid-tip {
  margin-bottom: 5px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .small {
  font-size: 11px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .google {
  display: inline-block;
  margin-bottom: 30px;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .wpcf7-form-control-wrap {
  text-align: center;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel {
    width: 100%;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel img {
  width: 80%;
  margin-bottom: 10px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel img {
    width: 95%;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel .tel {
  display: inline-block;
  color: #333;
  font-weight: bold;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  margin-bottom: 0px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel .tel {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel .tel {
    display: block;
    font-size: 36px;
    text-align: center;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl {
  width: 400px;
  margin: 0 auto;
  margin-bottom: 4px;
  text-align: center;
  color: #333;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl {
    width: 100%;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dt, .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dd {
  display: inline-block;
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dt {
  padding: 2px 10px;
  margin-right: 2px;
  font-size: 14px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dt {
    margin: 0px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dt {
    font-size: 12px;
    margin-bottom: 0px;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dd {
  font-size: 16px;
  letter-spacing: 0.02em;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel dl dd {
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel .content {
  text-align: left;
  width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
  color: #aaa;
}

@media screen and (max-width: 767px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact__tel .content {
    width: 100%;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .policy {
  border: 1px solid #aaa;
  padding: 10px;
  margin: 0 auto;
  width: 400px;
  height: 400px;
  overflow-y: scroll;
  text-align: left;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .policy {
    width: 100%;
  }
}

.bunjou_kurokawa .section-6 .bunjou_kurokawa__contact .policy h4 {
  font-size: 16px;
}

.bunjou_kurokawa .fixed {
  position: fixed;
  left: 0px;
  background-color: #fff;
  z-index: 9999;
}

.bunjou_kurokawa .page-top {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 50px;
  bottom: 60px;
}

@media screen and (max-width: 1023px) {
  .bunjou_kurokawa .page-top {
    right: 10px;
    bottom: 10px;
  }
}

.bunjou_kurokawa .inview {
  opacity: 0;
}

.bunjou_kurokawa .mv01 {
  opacity: 1;
  animation-duration: 1s;
  /* アニメーション時間 */
  animation-name: RightToLeft;
  /* アニメーション名 */
  animation-iteration-count: 1;
  /* アニメーションの繰り返し（無限）*/
}

@keyframes RightToLeft {
  0% {
    opacity: 0;
    /* 透明 */
    transform: translateY(50px);
    /* X軸方向に50px */
  }
  100% {
    opacity: 1;
    /* 不透明 */
    transform: translateY(0);
  }
}

@keyframes ZoomTop {
  0% {
    -moz-transition: -moz-transform 0.5s linear;
    -webkit-transition: -webkit-transform 0.5s linear;
    -o-transition: -o-transform 0.5s linear;
    -ms-transition: -ms-transform 0.5s linear;
    transition: transform 0.5s linear;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

body.page-template-page-bunjou_3 {
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  background: none;
  margin: 0;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
}

.bunjou_iseki .drawer-hamburger-icon, .bunjou_iseki .drawer-hamburger-icon::before, .bunjou_iseki .drawer-hamburger-icon::after {
  background-color: #fff;
}

.bunjou_iseki .open .drawer-hamburger-icon::before, .bunjou_iseki .open .drawer-hamburger-icon::after {
  background-color: #222;
}

.bunjou_iseki .page-btn-contact {
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: #0071b9;
  padding: 15px 15px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .page-btn-contact {
    display: block;
  }
}

.bunjou_iseki .page-btn-contact a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
}

.bunjou_iseki .page-btn-contact img {
  width: 20px;
  display: inline-block;
  margin-right: 10px;
}

.bunjou_iseki button:focus {
  outline: none;
}

.bunjou_iseki .txt-center {
  text-align: center;
}

.bunjou_iseki .iseki-btn a {
  display: inline-block;
  background-color: #222;
  color: #777;
  padding: 5px 25px;
  border-radius: 3px;
}

.bunjou_iseki .iseki-h2 {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .iseki-h2 {
    margin-bottom: 40px;
  }
}

.bunjou_iseki .iseki-h2 h2 {
  font-size: 50px;
  letter-spacing: 0.2em;
  color: #222;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-weight: normal;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .iseki-h2 h2 {
    font-size: 40px;
  }
}

.bunjou_iseki .iseki-h2 p {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 20px;
  color: #222;
}

@media screen and (min-width: mobile) {
  .bunjou_iseki .iseki-h2 p {
    font-size: 16px;
  }
}

.bunjou_iseki .iseki-h2--center {
  margin-bottom: 50px;
}

.bunjou_iseki .iseki-h2--center h2 {
  font-size: 50px;
  letter-spacing: 0.2em;
  color: #222;
  text-align: center;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-weight: normal;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .iseki-h2--center h2 {
    font-size: 40px;
  }
}

.bunjou_iseki .iseki-h2--center p {
  text-align: center;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 20px;
  color: #222;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .iseki-h2--center p {
    font-size: 16px;
  }
}

.bunjou_iseki .section {
  padding: 50px 0px;
}

.bunjou_iseki .content-width {
  width: 1200px;
  margin: 0 auto;
  max-width: 90%;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .content-width {
    width: 90%;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue {
  background-color: #0071b9;
  color: #fff;
  padding: 25px 0px 30px;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue {
    margin-bottom: 60px;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue h1 {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  font-size: 38px;
  font-weight: normal;
  letter-spacing: 0.05em;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .bunjou_kurokawa__blue h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue h1 {
    font-size: calc(18 * (100vw / 375));
    line-height: 1.8;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue h1 .green {
  background-color: #008f45;
  color: #fff;
  border-radius: 3px;
  padding: 9px 15px;
  vertical-align: middle;
  font-size: 18px;
  font-weight: normal;
  margin-right: 10px;
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue h1 .green {
    font-size: 12px;
    display: block;
    width: 220px;
    margin: 0 auto 10px;
    padding: 4px 0px;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue h1 .small {
  font-family: "Yu Gothic UI", "ＭＳ ゴシック", "ヒラギノ角ゴシック", "Hiragino Sans", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue h1 .small {
    font-size: 10px;
    margin-left: 5px;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue .border-white {
  border: 1px solid #fff;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  padding: 5px 15px;
  font-size: 24px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue .border-white {
    padding: 5px 10px 3px;
    font-size: 14px;
    width: 180px;
    display: block;
    margin: 0 auto 10px;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue p {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  display: inline-block;
  font-size: 28px;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_kurokawa__blue p {
    font-size: calc(18 * (100vw / 375));
    margin-bottom: 10px;
  }
}

.bunjou_iseki .bunjou_kurokawa__blue p .yellow {
  color: #fffb00;
  letter-spacing: 0.01em;
}

.bunjou_iseki .bunjou_kurokawa__blue .attention {
  display: inline-block;
  font-size: 10px;
  text-align: left;
  margin-left: 10px;
}

.bunjou_iseki .mb70 {
  margin-bottom: 70px;
}

.bunjou_iseki .mb50 {
  margin-bottom: 50px !important;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .mb50 {
    margin-bottom: 30px !important;
  }
}

.bunjou_iseki .mb30 {
  margin-bottom: 30px;
}

.bunjou_iseki .mb10 {
  margin-bottom: 10px !important;
}

.bunjou_iseki .mb0 {
  margin-bottom: 0px !important;
}

.bunjou_iseki .bunjou_iseki__header {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  width: 100%;
  height: 76px;
  background: #0071b9;
}

.bunjou_iseki .bunjou_iseki__header::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__header {
    height: 60px;
  }
}

.bunjou_iseki .bunjou_iseki__header__logo {
  float: left;
  padding: 15px 0px 15px 0px;
}

.bunjou_iseki .bunjou_iseki__header__logo a {
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #fff;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__header__logo a {
    font-size: 20px;
  }
}

.bunjou_iseki .bunjou_iseki__header__logo a span {
  font-size: 15px;
  position: relative;
  bottom: 5px;
  margin-right: 4px;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__header__logo a span {
    font-size: 11px;
    bottom: 2px;
  }
}

.bunjou_iseki .bunjou_iseki__header__nav {
  float: right;
  padding: 15px 0px 0px 0px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .bunjou_iseki__header__nav {
    display: none;
  }
}

.bunjou_iseki .bunjou_iseki__header__nav ul li {
  display: inline-block;
  text-align: center;
  margin-right: 12px;
  font-size: 17px;
  letter-spacing: 0.05em;
}

.bunjou_iseki .bunjou_iseki__header__nav ul li a {
  display: block;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  color: #fff;
}

.bunjou_iseki .bunjou_iseki__header__nav ul li a span {
  display: block;
  font-size: 10px;
  color: #fff;
}

.bunjou_iseki .bunjou_iseki__header .header-sp {
  display: none;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .bunjou_iseki__header .header-sp {
    display: block;
  }
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-hamburger {
  top: 9px;
  z-index: 10000;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__header .header-sp .drawer-hamburger {
    top: 0px;
  }
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav {
  display: none;
  padding: 40px;
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav .drawer-menu li {
  display: block;
  text-align: center;
  margin-right: 8px;
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav .drawer-menu li a {
  display: block;
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav .drawer-menu li a:first-letter {
  color: #0071b9;
  font-size: 18px;
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav .drawer-menu li a:hover {
  text-decoration: none;
}

.bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav .drawer-menu li a span {
  display: block;
  font-size: 10px;
  color: #aaa;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .bunjou_iseki__header .header-sp .drawer-nav {
    display: block;
  }
}

.bunjou_iseki .bunjou_iseki__top-img {
  font-size: 0px;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 76px);
  min-height: 500px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__top-img {
    height: calc(100vh - 60px);
  }
}

.bunjou_iseki .bunjou_iseki__top-img .postion-ab {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1200px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .bunjou_iseki__top-img .postion-ab {
    max-width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .bunjou_iseki__top-img .postion-ab {
    width: 80%;
  }
}

.bunjou_iseki .bunjou_iseki__top-img .bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url("./assets/bunjou/iseki/iseki-top.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  animation-duration: 20s;
  /* アニメーション時間 */
  animation-name: ZoomTop;
  /* アニメーション名 */
  animation-iteration-count: 1;
  /* アニメーションの繰り返し（無限）*/
  animation-fill-mode: forwards;
}

.bunjou_iseki .bunjou_iseki__top-img img {
  width: 100%;
}

.bunjou_iseki .section-1-wrap {
  display: flex;
  flex-wrap: wrap;
}

.bunjou_iseki .section-1-wrap .col-4-1 {
  width: 24%;
  margin-right: 1%;
}

.bunjou_iseki .section-1-wrap .col-4-1:nth-child(4n) {
  margin-right: 0px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1-wrap .col-4-1 {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%;
  }
  .bunjou_iseki .section-1-wrap .col-4-1:nth-child(2n) {
    margin-right: 0px;
  }
}

.bunjou_iseki .section-1-wrap .col-4-1 img {
  width: 100%;
}

.bunjou_iseki .section-1-wrap .col-4-1 p {
  text-align: right;
}

.bunjou_iseki .section-2 {
  margin: 100px 0px;
  padding: 60px 0px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-2 {
    margin: 60px 0px;
  }
}

.bunjou_iseki .section-2:before {
  z-index: -1;
  content: '';
  width: 65%;
  height: 100%;
  background-color: #efebe8;
  position: absolute;
  right: 0px;
  top: 0px;
}

.bunjou_iseki .section-2-wrap {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.bunjou_iseki .section-2-wrap video {
  width: 100%;
}

.bunjou_iseki .section-3 {
  padding: 100px 0px;
  position: relative;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-3 {
    margin-bottom: 60px;
  }
}

.bunjou_iseki .section-3:before {
  z-index: -1;
  content: '';
  width: 65%;
  height: 100%;
  background-color: #efebe8;
  position: absolute;
  left: 0px;
  top: 0px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-3:before {
    width: 100%;
  }
}

.bunjou_iseki .section-3-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-3-wrap {
    max-width: 600px;
    margin: 0 auto;
  }
}

.bunjou_iseki .section-3-wrap-img {
  width: 50%;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-3-wrap-img {
    width: 100%;
    margin-bottom: 30px;
  }
}

.bunjou_iseki .section-3-wrap-txt {
  width: 50%;
  padding-left: 5%;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-3-wrap-txt {
    width: 100%;
    padding-left: 0px;
  }
}

.bunjou_iseki .section-3-wrap-txt-dl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-3-wrap-txt-dl {
    align-items: inherit;
  }
}

.bunjou_iseki .section-3-wrap-txt-dl dt {
  width: 20%;
  font-size: 17px;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-3-wrap-txt-dl dt {
    width: 30%;
    font-size: 14px;
    border-bottom: 1px solid #222;
    padding-bottom: 5px;
  }
}

.bunjou_iseki .section-3-wrap-txt-dl dd {
  margin-bottom: 8px;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  width: 80%;
  font-size: 17px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-3-wrap-txt-dl dd {
    border-bottom: 1px solid #222;
    padding-bottom: 5px;
    width: 70%;
    font-size: 14px;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__list {
  width: 100%;
  margin-bottom: 50px;
}

.bunjou_iseki .section-4 .bunjou__section-4__list img {
  width: 100%;
}

.bunjou_iseki .section-4 .bunjou__section-4__list .slick-slide {
  height: auto;
}

.bunjou_iseki .section-4 .bunjou__section-4__list .bunjou__section-4__list__item__name {
  text-align: center;
  padding: 10px 0px;
  background-color: #efebe8;
  border-right: 1px solid #222;
  border-top: 1px solid #222;
}

.bunjou_iseki .section-4 .bunjou__section-4__list .bunjou__section-4__list__item img {
  width: 100%;
}

.bunjou_iseki .section-4 .bunjou__section-4__list .bunjou__section-4__list__item__info {
  text-align: center;
  padding: 10px 0px;
  background-color: #efebe8;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-4 .bunjou__section-4__list .bunjou__section-4__list__item__info {
    font-size: 10px;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__txt::after {
  clear: both;
  content: "";
  display: block;
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item {
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 32.31112%;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:nth-child(3n) {
    margin-right: 0;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 49.23334%;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:nth-child(2n) {
    margin-right: 0;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item {
    float: left;
    display: block;
    margin-right: 1.53331%;
    width: 100%;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:last-child {
    margin-right: 0;
  }
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item:nth-child(n) {
    margin-right: 0;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item--h3 {
  font-size: 16px;
  font-weight: bold;
  background-color: #0071b9;
  color: #fff;
  border: 1px solid #222;
  text-align: center;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  padding: 6px 0px;
  letter-spacing: 0.5em;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item--h3 {
    letter-spacing: 0.1em;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item__table {
  display: table;
  font-size: 14px;
  margin: 0 auto;
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dt {
  width: 300px;
  display: table-cell;
  font-weight: normal;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dt {
    width: 140px;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line {
  margin-right: 5px;
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 60px;
  border-bottom: 1px solid #000;
  height: 1px;
  position: relative;
  bottom: 5px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--line:after {
    width: 30px;
  }
}

.bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dd {
  display: table-cell;
  text-align: right;
  width: 128px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-4 .bunjou__section-4__txt__item__table .bunjou__section-4__txt__item__table--dd {
    width: 70px;
  }
}

.bunjou_iseki .section-1 .bunjou_kurokawa__title {
  font-size: 34px;
  margin-bottom: 15px;
}

.bunjou_iseki .section-1 .bunjou_kurokawa__title:first-letter {
  color: #0071b9;
  font-size: 38px;
}

.bunjou_iseki .section-1 .bunjou_kurokawa__title-h3 {
  font-size: 26px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .bunjou_kurokawa__title-h3 {
    font-size: 16px;
  }
}

.bunjou_iseki .section-1 .bunjou_kurokawa__title__small {
  margin-top: 3px;
}

.bunjou_iseki .section-1 .concept_wrap {
  display: table;
}

.bunjou_iseki .section-1 .concept_wrap__list {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .concept_wrap__list {
    width: 100%;
    display: block;
  }
}

.bunjou_iseki .section-1 .concept_wrap__list__item {
  width: 515px;
  margin: 0 auto;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .concept_wrap__list__item {
    width: 100%;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .concept_wrap__list__item .charm {
    margin-bottom: 30px;
  }
}

.bunjou_iseki .section-1 .concept_wrap__list__item .concept {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.bunjou_iseki .section-1 .concept_wrap__list__item .small {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .concept_wrap__list__item .small {
    font-size: 10px;
  }
}

.bunjou_iseki .section-1 .concept_wrap__list__item .large {
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
  letter-spacing: 0.05em;
  font-size: 12px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-1 .concept_wrap__list__item .large {
    font-size: 10px;
  }
}

.bunjou_iseki .section-7 {
  overflow: hidden;
  margin-bottom: 100px;
  position: relative;
}

.bunjou_iseki .section-7:before {
  content: '';
  display: block;
  width: 80%;
  max-width: 1000px;
  height: 100%;
  background-image: url("./assets/bunjou/iseki/sec01-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7:before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 {
    margin-bottom: 60px;
  }
}

.bunjou_iseki .section-7-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1105px;
  margin: 0 auto;
}

.bunjou_iseki .section-7-wrap-item {
  width: 100%;
  margin-bottom: 40px;
}

.bunjou_iseki .section-7-wrap-item-row {
  display: flex;
  flex-wrap: wrap;
}

.bunjou_iseki .section-7-wrap-item-row-img {
  width: 50%;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-img {
    width: 100%;
    height: 350px;
    background-position: bottom !important;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt {
  width: 50%;
  background: #0071b9;
  padding: 52px;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-txt {
    width: 100%;
    padding: 40px 20px;
    max-width: 380px;
    margin: 0 auto;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt:before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50vw;
  height: 100%;
  background-color: #0071b9;
  content: '';
  z-index: -1;
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--h3 {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "メイリオ", Meiryo, sans-serif;
  font-size: 24px;
  margin-bottom: 30px;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  margin-left: 0px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl {
    width: 100%;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child {
  justify-content: flex-end;
  border-bottom: 2px solid #fff;
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dt {
  background-color: inherit;
  color: #fff;
  text-align: right;
  width: calc(100% - 270px);
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dt {
    width: calc(100% - 190px);
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dd {
  width: 270px;
  text-align: right;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dd {
    width: 190px;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dd span {
  font-size: 40px;
  font-weight: bold;
  color: yellow;
  display: inline-block;
  margin-right: 5px;
  letter-spacing: 0.05em;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--dl:last-child dd span {
    font-size: 30px;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt p {
  color: #fff;
  font-size: 12px;
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--title {
  font-size: 16px;
  background-color: yellow;
  color: #0071b9;
  font-weight: normal;
  width: 140px;
  line-height: 1.6;
  text-align: center;
  margin-right: 10px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--title {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--title {
    margin-right: 10px;
    width: 90px;
    font-size: 12px;
  }
}

.bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--content {
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--content {
    font-size: 14px;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7-wrap-item-row-txt .section-7__bukken-02__txt--content {
    font-size: 12px;
  }
}

.bunjou_iseki .section-7 .section-7-wrap-item-row-img--1 {
  background: url("./assets/bunjou/iseki/iseki-pc-item-01_ver8.png");
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 .section-7-wrap-item-row-img--1 {
    background: url("./assets/bunjou/iseki/iseki-sp-item-01_ver8.png");
  }
}

.bunjou_iseki .section-7 .section-7-wrap-item-row-img--2 {
  background: url("./assets/bunjou/iseki/iseki-pc-item-02_ver8.png");
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 .section-7-wrap-item-row-img--2 {
    background: url("./assets/bunjou/iseki/iseki-sp-item-02_ver8.png");
  }
}

.bunjou_iseki .section-7 .section-7-wrap-item-row-img--3 {
  background: url("./assets/bunjou/iseki/iseki-pc-item-03_ver8.png");
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 .section-7-wrap-item-row-img--3 {
    background: url("./assets/bunjou/iseki/iseki-sp-item-03_ver8.png");
  }
}

.bunjou_iseki .section-7 .section-7-wrap-item-row-img--4 {
  background: url("./assets/bunjou/iseki/iseki-pc-item-04_ver8.png");
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 .section-7-wrap-item-row-img--4 {
    background: url("./assets/bunjou/iseki/iseki-sp-item-04_ver8.png");
  }
}

.bunjou_iseki .section-7 .section-7-wrap-item-row-img--5 {
  background: url("./assets/bunjou/iseki/iseki-pc-item-05_ver8.png");
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-7 .section-7-wrap-item-row-img--5 {
    background: url("./assets/bunjou/iseki/iseki-sp-item-05_ver8.png");
  }
}

.bunjou_iseki .section-8 {
  position: relative;
  margin-bottom: 100px;
}

.bunjou_iseki .section-8:before {
  z-index: -1;
  content: '';
  width: 65%;
  height: 100%;
  background-color: #efebe8;
  position: absolute;
  right: 0px;
  top: 0px;
}

.bunjou_iseki .section-8-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-8-wrap {
    flex-wrap: wrap-reverse;
  }
}

.bunjou_iseki .section-8-wrap-map {
  width: 50%;
  height: 450px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .section-8-wrap-map {
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-8-wrap-map {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
}

.bunjou_iseki .section-8-wrap-txt {
  width: 50%;
  padding-left: 5%;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-8-wrap-txt {
    width: 100%;
    padding: 0px;
    margin-bottom: 30px;
  }
}

.bunjou_iseki .section-8-wrap-txt p {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 20px;
}

.bunjou_iseki .section-6 {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-6 {
    margin-bottom: 60px;
  }
}

.bunjou_iseki .section-6 .must {
  display: inline-block;
  background: #0071b9;
  color: #fff;
  font-weight: normal;
  margin-left: 10px;
  padding: 2px 15px;
  font-size: 12px;
}

.bunjou_iseki .section-6 input {
  margin-bottom: 30px;
}

.bunjou_iseki .section-6 .wpcf7-submit {
  padding: 15px 0px;
  width: 200px;
  font-weight: normal;
  margin-top: 30px;
}

.bunjou_iseki .section-6 .section-6-privacy {
  margin-top: 30px;
  height: 300px;
  padding: 40px;
  overflow-y: scroll;
  border: 1px solid #222;
}

@media screen and (max-width: 767px) {
  .bunjou_iseki .section-6 .section-6-privacy {
    height: 200px;
    padding: 20px;
  }
}

.bunjou_iseki .section-6 .section-6-privacy p, .bunjou_iseki .section-6 .section-6-privacy h4 {
  font-size: 10px;
}

.bunjou_iseki .fixed {
  position: fixed;
  left: 0px;
  background-color: #0071b9;
  z-index: 9999;
}

.bunjou_iseki .page-top {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 50px;
  bottom: 60px;
}

@media screen and (max-width: 1023px) {
  .bunjou_iseki .page-top {
    right: 0px;
    bottom: 0px;
  }
}

.bunjou_iseki .inview {
  opacity: 0;
}

.bunjou_iseki .mv01 {
  opacity: 1;
  animation-duration: 1s;
  /* アニメーション時間 */
  animation-name: RightToLeft;
  /* アニメーション名 */
  animation-iteration-count: 1;
  /* アニメーションの繰り返し（無限）*/
}

@keyframes RightToLeft {
  0% {
    opacity: 0;
    /* 透明 */
    transform: translateY(50px);
    /* X軸方向に50px */
  }
  100% {
    opacity: 1;
    /* 不透明 */
    transform: translateY(0);
  }
}

@keyframes ZoomTop {
  0% {
    -moz-transition: -moz-transform 0.5s linear;
    -webkit-transition: -webkit-transform 0.5s linear;
    -o-transition: -o-transform 0.5s linear;
    -ms-transition: -ms-transform 0.5s linear;
    transition: transform 0.5s linear;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

body {
  -webkit-print-color-adjust: exact;
}

.print-page {
  width: 690px;
  margin: 0 auto;
  font-size: 11px;
}

.print-page::after {
  clear: both;
  content: "";
  display: block;
}

.print-page__heading {
  margin: 0;
}

.print-page__heading::after {
  clear: both;
  content: "";
  display: block;
}

.print-page__heading__title {
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
}

.print-page__heading__num {
  float: right;
  font-size: 12px;
}

.print-page__images__large {
  margin-bottom: 5px;
}

.print-page__images__large::after {
  clear: both;
  content: "";
  display: block;
}

.print-page__images__large__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 49.23334%;
  text-align: center;
}

.print-page__images__large__item:last-child {
  margin-right: 0;
}

.print-page__images__large__item:nth-child(2n) {
  margin-right: 0;
}

.print-page__images__large__item:nth-child(2n+1) {
  clear: left;
}

.print-page__images__large__item img {
  line-height: 1;
  max-width: 100%;
  vertical-align: top;
  height: 140px;
}

.print-page__images__small::after {
  clear: both;
  content: "";
  display: block;
}

.print-page__images__small__item {
  float: left;
  display: block;
  margin-right: 1.53331%;
  width: 18.77335%;
  text-align: center;
}

.print-page__images__small__item:last-child {
  margin-right: 0;
}

.print-page__images__small__item img {
  line-height: 1;
  max-width: 100%;
  vertical-align: top;
  height: 80px;
}

.print-page__left {
  width: 49%;
  float: left;
}

.print-page__right {
  width: 49%;
  float: right;
}

.print-page table {
  width: 100%;
  font-size: 11px;
  margin: 5px 0;
}

.print-page table tr th {
  padding: 2px 10px;
  width: 110px;
  border: 1px solid #ccc;
}

.print-page table tr td {
  padding: 2px 10px;
  border: 1px solid #ccc;
}

.print-page__bottom {
  line-height: 1.3;
}

.print-page__bottom__note {
  margin-bottom: 5px;
}

.print-page__bottom__top::after {
  clear: both;
  content: "";
  display: block;
}

.print-page__bottom__top__left {
  float: left;
}

.print-page__bottom__top__right {
  float: right;
}

.print-page__bottom__info {
  text-align: left;
}

.print-page__bottom__info__item {
  display: inline-block;
  font-size: 12px;
  margin: 3px 20px 0 0;
}

.print-page__btns {
  margin: 15px 0;
  text-align: center;
}

@media print {
  .print-page__btns .no_print {
    display: none;
  }
}
