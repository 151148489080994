body{
  &.pc{
    position: relative;
    height: 100%;
    background: url(./assets/img/sky_03.png) no-repeat center top;
    background-color: #EAF4F9;
    background-size: 100%;
    z-index: 1;
    margin-top: 38px;
  }
}
.pc{
  @import "pc_component";
  @import "pc_header";
  @import "pc_footer";
  @import "pc_sidebar";
  @import "pc_top";
  @import "pc_detail";
  @import "pc_archive-fudo";
  @import "pc_company";
  @import "pc_contact";
  @import "pc_single";
  @import "pc_archive";
  @import "pc_empty";
}
