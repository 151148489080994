body{
  @include media($middle){
    font-size: 0.875rem;
  }
}
#loader {
	width: 100%;
	height: 100%;
	margin: 0;
	background: #fff;/*Loaderの背景色としたいものを指定*/
	opacity: 1.0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000; /*一番前面に出るように（今回はbxSlidrの矢印が9999だったのでそれを超えるようにしました）*/
}
#loader img {
	position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%); /* Safari用 */
  transform: translate(-50%, -50%);
}
.mb-30{
  margin-bottom: 30px;
}

.content-wrap{
  margin: 0 20px;
  &__inner{
    @include outer-container(960px);
    border-left: 10px solid #9acedb;
    border-right: 10px solid #9acedb;
    padding: 0;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(left, #dff2fa 0%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dff2fa 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dff2fa 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 */
  }
}

.wrapper{
  @include outer-container($wrap-width);
  @include clearfix;
}
.main{
  @include outer-container($wrap-width);
}
.lower-main{
  @include outer-container($lower-width);
  width: 720px;
  float: right;
  position: relative;
  z-index: 1111;
  height: 100%;
  &:before{
    position: absolute;
    left: 0;
    z-index: 4444;
    content: "";
    display: block;
    height: 100%;
    width: 4px;
    background: #040000;
    opacity: 0.4;
  }
}
.lower-gradient{
  &__small{
    padding-right:20px;
  }
  &__h100{
    height: 100%;
  }
  &__wrap{
    padding: 20px;
    border-right: 10px solid #9acedb;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(left, #dff2fa 1%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dff2fa 1%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dff2fa 1%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 */
    &__h100{
      height: 100%;
    }
  }
}
.lower-content-box{
  padding: 30px 20px;
  background: #fff;
  border-radius: 10px;
  &__h100{
    height: 100%;
  }
}

.lower-heading{
  font-size: 1.25rem;
  padding: 10px;
  margin-bottom: 20px;
  color: #010101;/*文字色*/
  background: #f3fafd;/*背景色*/
  border-bottom: solid 2px #0068b7;/*下線*/
}

.lower-sub-heading{
  font-size: 1.125rem;
  line-height: 1.3;
  margin-bottom: 15px;
  padding: 5px 10px;
  border-left: 4px solid #0068b7;
}

.lower-include-contact{
  &:before,&:after{
    width: 700px;
    content: "";
    display: block;
    height: 17px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  }
  &--no-bar{
    @include clearfix;
    &:before,&:after{
      height: 0;
    }
    &:after{
      clear: both;
    }
    .lower-include-contact__container{
      background: none !important;
    }
  }
  &__container{
    @include clearfix;
    position: relative;
    padding: 30px 20px;
    background: #dcdfe7; /* Old browsers */
    background: -moz-linear-gradient(left, #dcdfe7 1%, #f6f2f2 50%, #dcdfe7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dcdfe7 1%,#f6f2f2 50%,#dcdfe7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dcdfe7 1%,#f6f2f2 50%,#dcdfe7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 ); /* IE6-9 */
    box-shadow:0px 4px 0px 0px rgba(0,0,0,0.2);
    &__left{
      width: 360px;
      float: left;
      background-color: rgba(255,255,255,0.8);
      padding: 10px;
      border: 1px solid #7d7d7d;
      border-radius: 4px;
      &__heading{
        text-align: center;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.3;
        margin-bottom: 5px;
      }
      &__tel{
        display: block;
        text-align: center;
      }
      &__time{
        text-align: center;
        font-size: 0.8125rem;
        margin: 0;
      }
    }
    &__right{
      position: relative;
      width: 280px;
      float: left;
      background: #c70000;
      border-radius: 4px;
      margin-left: 10px;
      a{
        display: block;
        padding: 10px;
      }
      &__heading{
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 1.25rem;
      }
      &__txt{
        color: #fff;
        font-size: 1rem;
        line-height: 1.4;
      }
      &__icon-mail{
        position: absolute;
        right: 15px;
        bottom: 20px;
      }
    }
  }
  &__title{
    text-align: center;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.heading-block{
  margin-bottom: -4px;
  background: transparent;
  &:before,&:after{
    @include outer-container($content-width);
    content: "";
    display: block;
    height: 17px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  }
  &:after{
    position: relative;
    bottom: 4px;
    z-index: 1111;
  }

  &__container{
    @include outer-container($wrap-width);
    @include clearfix;
    position: relative;
    background: url(./assets/img/heading_block_bg.png) no-repeat;
    background-size: $wrap-width;
    height: 105px;
    z-index: 2222;
  }
}

.heading-03{
  font-size: 1rem;
  line-height: 1.3;
  border-left: 5px solid #0068b7;
  padding: 5px 0 5px 8px;
  border-bottom: 1px solid #0068b7;
  margin-bottom: 20px;
}

.btn{
  &__red{
    display: block;
    text-align: center;
    width: 166px;
    margin: 0 auto;
    background: #ca2c2c;
    border-radius: 6px;
    border-bottom: 2px solid #cccccc;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 10px 0;
    line-height: 1.3;
    color: #fff;
    span{
      display:inline-block;
    }
    &:hover{
      background: #ca2c2c;
      padding: 11px 0 10px 0;
      border-bottom: 1px solid #cccccc;
      color: #fff;
    }
  }
  &__simulation{
    width: 250px;
    padding: 15px 0;
    &:hover{
      padding: 16px 0 15px 0;
    }
  }
}

.icon-arrow{
  background: url(./assets/img/icon_arrow_white.png) no-repeat left center;
  background-size: 8px 12px;
  padding-left: 15px;
}

.post-list{
  @include clearfix;
  .post-item{
    /*
    @include span-columns(4);
    @include omega(3n);
    */
    border-right: 1px solid #f0f0f0;
    background: #fff;
    padding: 0;
    border-bottom: 2px solid #8ba9b7;
    &__thumb{
      margin-bottom: 10px;
      height: 225px;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
      }
    }
    &__info{
      padding: 15px 15px 20px 15px;
    }
    &__name{
      font-size: 1rem;
      color: #3452a9;
      line-height: 1.3;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
    &__price{
      display: inline-block;
      color: #ca2c2c;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__floor{
      display: inline-block;
      color: #ca2c2c;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__address{
      color: #666666;
    }
    &__access{
      color: #666666;
    }
    &__years{
      color: #666666;
    }

  }

  .slick-prev, .slick-next {
    height: 71px;
    width: 71px;
    z-index: 2222;
  }
  .slick-prev{
    left: -22px;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 50px;
      height: 50px;
      background: url(./assets/img/slider_icon_left.png) no-repeat;
      background-size: cover;
    }
  }
  .slick-next{
    right: -20px;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 50px;
      height: 50px;
      background: url(./assets/img/slider_icon_right.png) no-repeat;
      background-size: cover;
    }
  }

}

.heading-block{
  &__title{
    text-align: center;
    font-size: 1.875rem;
    font-weight: bold;
    padding-top: 30px;
    img{
      vertical-align: bottom;
    }
  }

  &__col-03{
    @include clearfix;
    margin: 0 25px;
    padding-top: 15px;
    &__item{
      float: left;
      margin: 0 25px;
    }
  }

  &__col-02{
    @include clearfix;
    margin: 0 50px;
    padding-top: 15px;
    &__item{
      &:first-child{
        float: left;
      }
      &:last-child{
        float: right;
      }
    }
  }

}

.col-03-content{
  @include clearfix;
  background: #7b7b7b; /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  &__item{
    @include span-columns(4);
    @include omega(3n);
    box-sizing: content-box;
    &:nth-child(1){
      background: #dff2fa; /* Old browsers */
      background: -moz-linear-gradient(36deg, #dff2fa 0%, #b4d7e8 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(36deg, #dff2fa 0%,#b4d7e8 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(36deg, #dff2fa 0%,#b4d7e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#b4d7e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    }
    &:nth-child(2){
      background: #d5ecf6; /* Old browsers */
      background: -moz-linear-gradient(36deg, #d5ecf6 0%, #7bb2cf 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(36deg, #d5ecf6 0%,#7bb2cf 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(36deg, #d5ecf6 0%,#7bb2cf 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5ecf6', endColorstr='#7bb2cf',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    &:nth-child(3){
      background: #9cc8de; /* Old browsers */
      background: -moz-linear-gradient(36deg, #9cc8de 0%, #71accb 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(36deg, #9cc8de 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(36deg, #9cc8de 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9cc8de', endColorstr='#71accb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
    &:last-child{
      margin-right: 0;
    }
  }
  &__list{
    padding: 20px 20px 15px 20px;
    &__tintai{
      .col-03-content__list__item{
        a{
          background: url(./assets/img/icon_tintai.png) no-repeat left 10px center;
          background-size: 30px 30px;
          padding-left: 55px;
        }
      }
    }
    &__uri{
      .col-03-content__list__item{
        a{
          background: url(./assets/img/icon_uri.png) no-repeat left 10px center;
          background-size: 30px 30px;
          padding-left: 55px;
        }
      }
    }
    &__bunjyou{
      .col-03-content__list__item{
        a{
          background: url(./assets/img/icon_bunjyou.png) no-repeat left 10px center;
          background-size: 30px 30px;
          padding-left: 55px;
        }
      }
    }
    &__item{
      background: #fff;
      border-radius: 4px;
      border-bottom: 2px solid #afbfc6;
      margin-bottom: 5px;
      &:hover{
        position: relative;
        top: 1px;
        padding-bottom: 1px;
        border-bottom: 1px solid #afbfc6;
      }
      &:last-child{
        margin-bottom: 0;
      }
      a{
        color: #000;
        display: block;
        padding: 10px;
        font-size: 0.9375rem;
      }
    }
  }
  &__area{
    padding: 0 20px 20px 20px;
    &__heading{
      font-size: 1rem;
      margin-bottom: 8px;
      padding: 5px 10px;
      font-weight: bold;
      &:before{
        content: "\f0dd";
        display: inline-block;
        font-family: FontAwesome;
        font-size: 1rem;
        margin-right: 6px;
        position: relative;
        bottom: 3px;
      }
      &.tintai{
        background: #006EA7;
        color: #fff;
      }
      &.buy{
        background: #FF5E5E;
        color: #fff;
      }
      &.tochi{
        background: #5E951D;
        color: #fff;
      }
    }
    &__list{
      &.tintai{
        .col-03-content__area__list__item{
          a{
            &:before{
              color: #006EA7;
            }
          }
        }
      }
      &.buy{
        .col-03-content__area__list__item{
          a{
            &:before{
              color: #FF5E5E;
            }
          }
        }
      }
      &.tochi{
        .col-03-content__area__list__item{
          a{
            &:before{
              color: #5E951D;
            }
          }
        }
      }
      &__item{
        background: #fff;
        border-radius: 4px;
        border-bottom: 2px solid #afbfc6;
        margin-bottom: 3px;
        &:hover{
          position: relative;
          top: 1px;
          padding-bottom: 1px;
          border-bottom: 1px solid #afbfc6;
        }
        &:last-child{
          margin-bottom: 0;
        }
        a{
          color: #000;
          display: block;
          padding: 5px 10px;
          font-size: 0.75rem;
          &:before{
            content: "\f041";
            display: inline-block;
            font-family: FontAwesome;
            font-size: 1rem;
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.col-02-content{
  @include clearfix;
  background: #7b7b7b; /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  &__left{
    @include span-columns(6);
    padding: 20px;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(45deg, #dff2fa 0%, #98c5dc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #dff2fa 0%,#98c5dc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #dff2fa 0%,#98c5dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#98c5dc',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  &__right{
    @include span-columns(6);
    padding: 20px;
    background: #bcdceb; /* Old browsers */
    background: -moz-linear-gradient(36deg, #bcdceb 0%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(36deg, #bcdceb 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(36deg, #bcdceb 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcdceb', endColorstr='#71accb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  &__box{
    background: #fff;
    border-bottom: 2px solid #b2c2c8;
    border-radius: 4px;
    padding: 25px 25px 20px 25px;
    &__select{
      width: 45%;
      display: inline-block;
      font-size: .875rem;
      margin-bottom: 30px;
    }
  }
  &__word-search{
    margin-top: 30px;
    @include clearfix;
    .btn__red{
      width: 20%;
      display: inline-block;
      float: right;
      letter-spacing: .01rem;
    }
    &__input{
      width: 78%;
      display: inline-block;
      float: left;
    }
  }
  &__list{
    @include clearfix;
    margin-bottom: 15px;
    &__item{
      @include span-columns(6);
      @include omega(2n);
      line-height: 2;
      &:nth-child(1),&:nth-child(2){
        span{
          border-bottom: 1px dotted #7f7f7f;
          padding-bottom: 5px;
          width: 70%;
          display: inline-block;
        }
      }
      label{
        font-weight: normal;
      }
    }
  }
}

.pr{
  margin: 0 20px;
  padding: 20px 0;
}

.info{
  @include clearfix;
  margin: 0 20px;
  padding: 20px 0;
  &__item{
    position: relative;
    @include span-columns(4);
    @include omega(3n);
    background: #fff;
    padding: 13px 10px;
    border-radius: 4px;
    border-bottom: 2px solid #8ba9b7;
    min-height: 380px;
    &__image{
      margin-bottom: 5px;
    }
    &__reform-txt{
      font-size: .75rem;
      text-align: justify;
      line-height: 1.5;
      margin-bottom: 15px;

    }
    &__container{
      padding: 0 10px;
    }
    &__news-list{
      margin-bottom: 10px;
      &__item{
        border-bottom: 1px dotted #7f7f7f;
        a{
          color: #000;
          padding: 5px 0;
          display: inline-block;
        }
      }
    }
    &__archive-link{
      text-align: right;
      position: absolute;
      right: 10px;
      bottom: 5px;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
  }
}

.breadcrumb{
  padding-right: 20px;
  &__list{
    text-align: left;
    font-size: 0;
    padding: 10px 15px;
    background: #554c4c; /* Old browsers */
    background: -moz-linear-gradient(left, #554c4c 1%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #554c4c 1%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #554c4c 1%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#554c4c', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
    &__item{
      display: inline-block;
      font-size: 0.625rem;
      color: #fff;
      &:after{
        content: "＞";
        display: inline-block;
        color: #fff;
        font-size: 0.625rem;
        margin: 0 5px;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
      a{
        color: #fff;
      }
    }
  }
}
#nendebcopy{
  display: none;
}
.pageback{
  a{
    font-size: .75rem;
    display: inline-block;
    padding: 10px 10px;
    background: #FF7E00;
    color: #fff;
  }
}

.search-heading{
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
}
.side-search-heading{
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
  margin: 20px 10px 0 10px;
}
