.single{
  &-post{
    img{
      height: auto;
    }
    &__time{
      font-size: 1rem;
      margin-bottom: 20px;
    }
    &__thumb{
      margin-bottom: 15px;
    }
    &__content{
      margin-bottom: 45px;
      font-size: 0.875rem;
      p{
        margin-bottom: 1.25rem;
        line-height: 1.8;
      }
      img{
        display: inline-block;
        margin: 5px 0;
      }
    }
    &__navi{
      text-align: center;
      a{
        display: inline-block;
        margin: 10px;
      }
    }
  }
}
