.archive-fudo{
  .list_simple_box{
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    .list_picsam{
      a{
        &:first-child{
          display: none;
        }
      }
    }
    .list_picsam_img{
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
      margin: 0 auto 10px;
      height: 230px;
      overflow: hidden;
      img{
        border: 1px solid #e9e9e9;
        width: 100%;
        height: auto;
      }
    }
    .entry-title{
      margin: 0 0 5px 0;
      font-size: 0.875rem;
      line-height: 1.3;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
    .entry-excerpt{
      text-align: left;
      margin-bottom: 5px;
      font-size: .75rem;
      font-weight: bold;
      color: #333;
      text-align: center;
      span{
        display: inline-block;
        background: url(./assets/img/icon_point.png) no-repeat left top;
        background-size: 15px 16px;
        padding-left: 20px;
      }
    }
    .entry-num{
      font-size: 0.625rem;
      margin: 0 0 2px 0;
      color: #666666;
    }
    .list_detail{
      table{
        font-size: .75rem;
        margin: 0;
        th{
          padding: 0;
        }
        td{
          padding: 0;
          border: none;
          dt{
            display: inline-block;
          }
          dd{
            display: inline-block;
            margin: 0 5px;
            color: #666666;
          }
        }
      }
    }
  }
  .color-red{
    color: #ff6464;
    font-weight: bold;
    font-size: 1rem;
  }

  /* ---------- bookmark ---------- */
  &.bookmark{
    .wpfp-link{
      background: #FF7E00;
      display: inline-block;
      padding: 2px 10px;
      color: #fff;
      &.remove-parent{
        background: #FF7E00;
        display: inline-block;
        padding: 2px 10px;
        color: #fff;
        margin-top: 10px;
      }
    }
    .list_simple_box{
      border-bottom: none;
    }
  }

}

.important-items{
  margin-bottom: 0px;
  dl{
    display: inline-block;
    margin-right: 15px;
    font-size: 0;
    &:last-child{
      margin-right: 0;
    }
    dt{
      display: inline-block;
      font-size: 0.75rem;
      font-weight: normal;
      margin-right: 5px;
    }
    dd{
      display: inline-block;
      font-size: .875rem;
      color: #ff6464;
      font-weight: bold;
    }
  }
}

.list_price_others{
  display: table;
  width: 100%;
  @include clearfix;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  display: none;
  dt{
    display: table-cell;
    border: 1px solid #ddd;
    background: #FAFCFD;
  }
  dd{
    display: table-cell;
    border: 1px solid #ddd;
    &.detail-link{
      float: right;
      font-weight: bold;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
  }
}

/* ---------- 並び替え ----------*/
/* ---------- 並び替え ----------*/
.navigation{
  margin: 15px 0;
  text-align: center;
  a{
    line-height: 1;
    display: inline-block;
    border: 1px solid #c7c7c7;
    padding: 5px 10px;
    margin: 0 2px 5px 2px;
    img{
      display: inline-block;
      margin-right: 5px;
      position: relative;
      bottom: 1px;
    }
  }
  b{
  }
  .nav-previous{
    margin-bottom: 15px;
  }
  .nav-next{
    font-size: .625rem;
    font-weight: bold;
    a{
      line-height: 1;
      display: inline-block;
      border: 1px solid #c7c7c7;
      padding: 8px;
      margin: 0 2px;
      font-weight: normal;
    }
    b{
      line-height: 1;
      display: inline-block;
      border: 1px solid #000;
      padding: 8px;
      background: #000;
      color: #fff;
      margin: 0 3px;
      font-weight: normal;
    }
  }
}
