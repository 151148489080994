.slider{
  @include outer-container($content-width);
  background: #7B7B7B;
  margin-top: -3px;

  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  }

  &__list{
    margin: 0 10px;
    padding: 0px;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(36deg, #dff2fa 0%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(36deg, #dff2fa 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(36deg, #dff2fa 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    &__item{
      text-align: center;
    }
  }

  .slick-prev, .slick-next {
    height: 71px;
    width: 71px;
    z-index: 2222;
  }

  .slick-prev{
    left: 5px;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 71px;
      height: 71px;
      background: url(./assets/img/slider_icon_left.png) no-repeat;
      background-size: cover;
    }
  }

  .slick-next{
    right: 5px;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 71px;
      height: 71px;
      background: url(./assets/img/slider_icon_right.png) no-repeat;
      background-size: cover;
    }
  }

}


.top{

  .thumb-slider{
    text-align: center;
    margin: 0 20px;
    padding: 20px 10px;

    &__item{
      position: relative;
      background: #fff;
      text-align: left;
      border-right: 1px solid #f0f0f0;
      &__thumb{
        text-align: center;
        height: 165px;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
      &__detail{
        padding: 10px 15px 15px 15px;
      }
      &__title{
        font-size: .875rem;
        line-height: 1.3;
        margin-bottom: 5px;
        a{
          color: #3452a9;
          text-decoration: underline;
        }
      }
      &__price{
        font-size: .75rem;
        display: inline-block;
        color: #ca2c2c;
        font-weight: bold;
        margin-right: 5px;
      }
      &__floor{
        position: absolute;
        left: 0;
        top: 0;
        background: #ca2c2c;
        font-size: .75rem;
        padding: 2px 5px;
        display: inline-block;
        color: #fff;
        font-weight: bold;
      }
      &__address{
        font-size: .675rem;
        color: #666666;
      }
    }

    .slick-prev, .slick-next {
      height: 71px;
      width: 71px;
      z-index: 2222;
    }
    .slick-prev{
      left: -22px;
      &:before{
        content: "";
        display: inline-block;
        opacity: 1;
        width: 50px;
        height: 50px;
        background: url(./assets/img/slider_icon_left.png) no-repeat;
        background-size: cover;
      }
    }
    .slick-next{
      right: -20px;
      &:before{
        content: "";
        display: inline-block;
        opacity: 1;
        width: 50px;
        height: 50px;
        background: url(./assets/img/slider_icon_right.png) no-repeat;
        background-size: cover;
      }
    }
  }

  .home-search{
    margin: 0 20px;
    &__container{
      padding: 20px 0;
    }
    &__form{
      padding: 20px;
      background: #fff;
      border-radius: 4px;
      @include clearfix;
      &__section{
        @include span-columns(6);
        @include omega(2n);
        @include clearfix;
        margin-bottom: 20px;
        ul{
          line-height: 2.2;
          margin-bottom: 10px;
          font-size: 13px;
          padding: 0 5px;
        }
        .area-search{
          @include span-columns(7);
        }
        .madori-search{
          @include span-columns(5);
          margin-right: 0;
        }
        .price-select{
          clear: both;
          @include clearfix;
          select{
            display: inline-block;
            width: 45%;
            font-size: 14px;
          }
        }
        label{
          font-weight: normal;
        }
        &__left{
          //border-right: 1px solid #999;
        }
        &__right{
          padding-left: 32px;
          &__list{
            @include clearfix;
            li{
              @include span-columns(6);
              @include omega(2n);
            }
          }
        }
      }
      &__txt-search{
        @include clearfix;
        margin-top: 30px;
        padding: 0 60px;
        border-top: 1px solid #999;
        padding-top: 20px;
        &__left{
          @include span-columns(9);
          input{
            width: 100%;
          }
        }
        &__right{
          @include span-columns(3);
          .btn__red{
            width: 100%;
            padding: 12px 0;
          }
        }
      }
      .btn__red{
        clear: both;
      }
    }
  }

  .top-bunjou-link{
    text-align: center;
    padding: 20px 20px 0 20px;
    margin-bottom: -10px;
    img{
      border: 1px solid #fff;
    }
  }

  .kominka-link{
    text-align: center;
    padding: 20px 20px 0 20px;
    margin-bottom: -10px;

    img{
      border: 1px solid #fff;
    }
  }

}
