body{
  &.mobile{
    overflow-x: hidden;
  }
}
.mobile{
  @import "mobile_component";
  @import "mobile_header";
  @import "mobile_footer";
  @import "mobile_top";
  @import "mobile_archive-fudo";
  @import "mobile_detail";
  @import "mobile_company";
  @import "mobile_contact";
  @import "mobile_single";
  @import "mobile_archive";
  @import "mobile_empty";
}
