.detail{
  position: relative;
  &:after{
    width: 700px;
    height: auto;
    position: absolute;
    border-right: 10px solid #9acedb;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(left, #dff2fa 1%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dff2fa 1%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dff2fa 1%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 */
  }
  .detail__print{
    position: absolute;
    right: 6px;
    top: 6px;
    background: #ca2c2c;
    display: inline-block;
    border-radius: 6px;
    border-bottom: 1px solid #a6a6a6;
    &__normal{
      position: static;
      margin: 10px 0;
    }
    a{
      padding: 10px 15px 10px 45px;
      display: block;
      color: #fff;
      font-size: .75rem;
      background: url(./assets/img/icon_print.png) no-repeat left 15px center;
      background-size: 23px 18px;
    }
  }

  &__top-content{


    &__title{
      font-size: 1.5rem;
      &:before{
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        position: relative;
        top: 5px;
      }
      &__tintai{
        &:before{
          background: url(./assets/img/icon_tintai.png) no-repeat center center;
          background-size: cover;
        }
      }
      &__uri{
        &:before{
          background: url(./assets/img/icon_uri.png) no-repeat center center;
          background-size: cover;
        }
      }
      &__bunjyou{
        &:before{
          background: url(./assets/img/icon_bunjyou.png) no-repeat center center;
          background-size: cover;
        }
      }
    }

    &__box{
      position: relative;
      background: #fff;
      padding: 15px 20px;
      border-radius: 10px;
      margin-bottom: 30px;

      .detail__num{
        position: absolute;
        right: 5px;
        top: -47px;
        background: url(./assets/img/detail_num_bg.png) no-repeat;
        width: 189px;
        height: 48px;
        &__item{
          margin: 27px 0 0 56px;
          dt{
            display: inline-block;
            font-weight: normal;
            font-size: 0.75rem;
          }
          dd{
            display: inline-block;
            font-size: 0.75rem;
          }
        }
      }

      &__top{
        @include clearfix;
        padding-bottom: 13px;
        margin-bottom: 13px;
        border-bottom: 1px solid #bfbfbf;
        &__left{
          display: table-cell;
          font-size: 0;
          border-right: 1px solid #ccc;
          padding: 0 20px 5px 0;
          .rent{
            display: inline-block;
            dt{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              margin-right: 10px;
            }
            dd{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              .strong{
                //font-size: 1.875rem;
                font-size: 1.125rem;
                line-height: 1.3;
                font-weight: bold;
                color: #ff6464;
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
          .service-fee{
            font-size: .75rem;
            dt,dd{
              font-weight: normal;
              display: inline;
            }
          }
        }

        &__right{
          font-size: 0;
          padding: 0 0 5px 20px;
          display: table-cell;
          .floor{
            display: inline-block;
            dt{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              margin-right: 10px;
            }
            dd{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              .strong{
                //font-size: 1.875rem;
                font-size: 1.25rem;
                font-weight: bold;
                color: #ff6464;
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
          .area-size{
            font-size: .75rem;
            dt,dd{
              font-weight: normal;
              display: inline;
            }
          }
        }

      }

      &__bottom{

        .other-info{
          @include clearfix;
          margin-bottom: 15px;
          &__item{
            @include span-columns(6);
            @include omega(2n);
            margin-bottom: 1%;
            dt{
              color: #fff;
              background: #ff6464;
              font-weight: normal;
              font-size: .75rem;
              min-width: 70px;
              text-align: center;
              padding: 0 5px;
              display: inline-block;
              margin-right: 5px;
            }
            dd{
              display: inline-block;
              font-size: .75rem;
            }
          }
        }

        .point{
          dt{
            position: relative;
            display: inline-block;
            font-size: 0.8125rem;
            font-weight: bold;
            border: 1px solid #ff6464;
            border-radius: 10px;
            padding: 0 10px 0 30px;
            margin-right: 10px;
            &:before{
              position: absolute;
              left: 0;
              top: -6px;
              content: "";
              display: inline-block;
              background: url(./assets/img/icon_point.png) no-repeat center center;
              background-size: cover;
              width: 25px;
              height: 28px;
            }
          }
          dd{
            display: inline-block;
            font-size: .75rem;
          }
        }

      }

    }


  }

  &__gallery{
    &__main{
      img{}
    }
    &__main{
      img{}
    }
  }

  .viewer {
    @include clearfix;
    width: 650px;
    margin: 0 auto;
    min-height: 300px;
  }
  .viewer div {
      position: relative;
      width: 365px;
      min-height: 300px;
      float: left;
  }
  .viewer{
    img{
      line-height: 1;
      vertical-align: top;
    }
    &__main{
      img{
        background: #fff;
        padding: 2px;
      }
    }
  }
  .viewer div img {
      width: auto;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      line-height: 1;
      vertical-align: top;
  }

  .viewer ul {
      width: 270px;
      float: right;
      @include clearfix;
  }

  .viewer ul li {
    @include span-columns(4);
    @include omega(3n);
    cursor: pointer;
    height: 66px;
    padding: 2px;
    margin-bottom: 3px;
    background: #fff;
    padding: 2px;
    overflow: hidden;
  }

  .viewer .active {
      filter:alpha(opacity=100)!important;
      -moz-opacity: 1!important;
      opacity: 1!important;
  }


  /* =======================================
      ClearFixElements
  ======================================= */
  .viewer ul:after {
      content: ".";
      height: 0;
      clear: both;
      display: block;
      visibility: hidden;
  }

  .viewer ul {
      display: inline-block;
      overflow: hidden;
  }

  .more-info{
    &__box{
      padding: 20px 10px;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 35px;
    }
    &__title{
      position: relative;
      background: #ffd7d7;
      font-size: 1.125rem;
      line-height: 1.3;
      padding: 5px 15px 5px 55px;
      border-radius: 10px 0 0 10px;
      margin-bottom: 40px;
      &:before{
        position: absolute;
        left: -2px;
        top: -5px;
        content: "";
        display: inline-block;
        background: url(./assets/img/detail_more_info_icon.png) no-repeat;
        width: 46px;
        height: 46px;
        background-size: cover;
      }
    }
    &__col-02{
      @include clearfix;
      margin-bottom: 20px;
      &__left{
        @include span-columns(6);
      }
      &__right{
        @include span-columns(6);
        table{
          width: 100%;
          line-height: 1.3;
          margin: 0;
          th{
            background: #e3eef4;
            font-size: 0.8125rem;
            border: 1px solid #dedede;
            width: 120px;
            padding: 10px;
            font-weight: normal;
          }
          td{
            font-size: 0.8125rem;
            border: 1px solid #dedede;
            padding: 10px;
          }
        }
      }
    }
    &__col-01{
      table{
        width: 100%;
        line-height: 1.3;
        margin: 0 0 10px 0;
        th{
          background: #e3eef4;
          font-size: 0.8125rem;
          border: 1px solid #dedede;
          width: 100px;
          padding: 10px;
          font-weight: normal;
        }
        td{
          font-size: 0.8125rem;
          border: 1px solid #dedede;
          padding: 10px;
        }
      }
      &__notices{
        margin-bottom: 15px;
        &__item{
          display: table;
          width: 100%;
          dt{
            width: 90px;
            display: table-cell;
            border: 1px solid #dedede;
            vertical-align: middle;
            padding: 15px;
            background: #ffe3e3;
            color: #ff0000;
            font-weight: normal;
            border-right: none;
          }
          dd{
            display: table-cell;
            border: 1px solid #dedede;
            vertical-align: middle;
            padding: 15px;
            line-height: 1.75;
          }
        }
      }
      &__map{
        margin-bottom: 5px;
        iframe{
          width: 100%;
          height: 290px;
        }
      }
      &__caution{
        margin: 0;
      }
    }
  }

  &__bookmark{
    margin-bottom: 10px;
    &__container{
      background: #FF7E00;
      display: inline-block;
      .wpfp-span{
        line-height: 1.3;
        display: inline-block;
        color: #fff;
        font-size: .875rem;
        width: 216px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        a{
          color: #fff;
          display: block;
          &:before{
            content: "\f02e";
            font-family: FontAwesome;
            display: inline-block;
            font-size: 1rem;
            margin-right: 10px;
            color: #fff;
          }
        }
      }
    }
  }

  .widget_fudo_syousai{
    background: #fff;
    margin-top: 20px;
    padding: 20px 15px 15px 15px;
    border-radius: 8px;
    h3{
      margin: 0 0 20px;
      border-left: 4px solid #0068b7;
      border-bottom: 1px solid #0068b7;
      font-size: 1.125rem;
      padding: 3px 0 5px 10px;
    }
  }
  .syousai-content{
    @include clearfix;
    .syousai-content-li{
      @include span-columns(3);
      @include omega(4n);
      border: 1px solid #f2f2f2;
      position: relative;
      background: #fff;
      padding: 10px;
      margin-bottom: 2%;
      font-size: .75rem;
      a{
        display: block;
        img{
          width: 100%;
        }
      }
      .new_mark{
        background: #ca2c2c;
        color: #fff;
        font-size: .75rem;
        font-weight: bold;
        position: absolute;
        left: 10px;
        top: 10px;
        width: 46px;
        padding: 3px 0;
        line-height: 1;
        text-align: center;
      }
      .top_title{
        font-weight: bold;
        color: #3452a9;
      }
      .top_price{
        color: #ff6464;
        font-weight: bold;
      }
      .top_madori{
        color: #ff6464;
        font-weight: bold;
      }
      .top_menseki{
        color: #ff6464;
        font-weight: bold;
      }
      .top_shozaichi{
        color: #666666;
        line-height: 1.5;
      }
    }
  }

  .akijouhou{
    margin-bottom: 30px;
  }

  &__pet{
    display: inline-block;
    background: url(./assets/img/icon_pet.png) no-repeat;
    background-size: contain;
    padding-left: 20px;
    margin-right: 10px;
    &__inu{
      display: inline-block;
      background: url(./assets/img/icon_dog.png) no-repeat;
      background-size: contain;
      padding-left: 20px;
      margin-right: 10px;
    }
    &__neko{
      display: inline-block;
      background: url(./assets/img/icon_cat.png) no-repeat;
      background-size: contain;
      padding-left: 20px;
      margin-right: 10px;
    }
  }

  .tochikau-images{
    @include clearfix;
    &__item{
      @include span-columns(6);
      @include omega(2n);
      background: #fff;
      padding: 2px;
      margin-bottom: 1%;
      img{
        vertical-align: top;
        width: 100%;
        height: auto;
      }
    }
  }

}
