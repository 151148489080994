


body.page-template-page-bunjou_3{
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  background:none;
  margin: 0;
  font-family: $font-family;
}

$content-width-kurokawa: 980px;
$font-mincho: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','メイリオ', Meiryo,serif;
$font-gothic: 'Yu Gothic UI','ＭＳ ゴシック','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,sans-serif;


$main-blue: #2173b7;


.bunjou_kurokawa{

  .txt-center{
    text-align: center;
  }
  .clearfix{
    @include clearfix;
  }

  .section{
    padding: 50px 0px;
  }

  .content-width{
    width: $content-width-kurokawa;
    margin: 0 auto;
    @include media($middle){
      width: 100%;
      padding: 0px 10px;
    }
  }

  .col-2-1{
    float: left;
    @include omega(2n);
    @include span-columns(6);
    img{
      width: 100%;
    }
  }

  .col-3-1{
    float: left;
    @include omega(3n);
    @include span-columns(4);
    img{
      width: 100%;
    }
  }

  .col-4-1{
    float: left;
    @include omega(4n);
    @include span-columns(3);
    img{
      width: 100%;
    }
  }

  .gray{
    color: #aaaaaa
  }


  //トップ画像の次の青い箇所
  .bunjou_kurokawa__blue{
    background-color: $main-blue;
    color: #fff;
    padding: 25px 0px 30px;
    h1{
      font-family: $font-mincho;
      font-size: 30px;
      letter-spacing: 0.05em;
      text-align: center;
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      margin-bottom: 20px;
      @include media($mobile){
        font-size: 16px;
      }
      .small{
        font-family: $font-gothic;
        font-size: 14px;
        font-weight: normal;
        margin-left: 10px;
        @include media($mobile){
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .border-white{
      border: 1px solid #fff;
      font-family: $font-mincho;
      padding: 5px 15px;
      font-size: 24px;
      margin-right: 10px;
      @include media($mobile){
        padding: 5px 10px 3px;
        font-size: 12px;
        margin-right: 5px;
      }
    }
    p{
      font-family: $font-mincho;
      display: inline-block;
      font-size: 28px;
      margin: 0px;
      @include media($mobile){
        font-size: 16px;
        margin-bottom: 10px;
      }
      .yellow{
        color: #fffb00;
        letter-spacing: 0.01em;
      }
    }
    .attention{
      display: inline-block;
      font-size: 10px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .font-gothic{
    font-family: $font-gothic;
  }

  .font-mincho{
    font-family: $font-mincho;
  }

  .bunjou_kurokawa__title{
    font-family: $font-mincho;
    font-size: 1.8rem;
    font-weight: normal;
    letter-spacing: 0.05em;
    &:first-letter{
      color: $main-blue;
      font-size: 30px;
    }
    &__small{
      letter-spacing: 0.01em;
      display: block;
      font-size: 10px;
      color: #aaa;
    }
  }

  .mb70{
    margin-bottom: 70px;
  }
  .mb50{
    margin-bottom: 50px !important;
    @include media($mobile){
      margin-bottom: 30px !important;
    }
  }
  .mb30{
    margin-bottom: 30px;
  }
  .mb10{
    margin-bottom: 10px !important;
  }
  .mb0{
    margin-bottom: 0px !important;
  }



  .bunjou_kurokawa__title-h3{
    font-size: 24px;
    font-family: $font-mincho;
    line-height: 1.6;
    font-weight: normal;
    margin-bottom: 10px;
    @include media($mobile){
      font-size: 16px;
    }
  }
  .bunjou_kurokawa__title-h3--bgblue{
    position: relative;
    background-color: $main-blue;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    font-family: $font-mincho;
    padding: 5px 10px;
    span{
      position: absolute;
      top:9px;
      right: 10px;
      font-size: 11px;
      color: #fffb00;
      text-align: right;
    }
  }
  .bunjou_kurokawa__title-h3--detail{
    text-align: center;
    font-family: $font-mincho;
    font-size:28px;
    letter-spacing: 0.05em;
    color: $main-blue;
    font-weight: normal;
    margin-bottom: 10px;
    @include media($mobile){
      font-size: 16px;
    }
    &:before{
      content:"";
      display: inline-block;
      background-image: url('./assets/img/bunjo_kurokawa/detail-title-left.png');
      width: 60px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
      top:5px;
      position: relative;
    }
    &:after{
      content:"";
      display: inline-block;
      background-image: url('./assets/img/bunjo_kurokawa/detail-title-right.png');
      width: 60px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 10px;
      top:5px;
      position: relative;
    }
  }
  .border-bottom-line{
    display: block;
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 60px;
    height: 1px;
    background-color: $main-blue;
  }

  .bunjou_kurokawa__header{
    //position: fixed;
    width: 100%;
    height: 76px;
    background: #fff;
    @include clearfix;
    border-bottom: 1px solid $main-blue;
    &__logo{
      float: left;
      padding: 15px 0px 15px 0px;
      a{
        font-size: 30px;
        letter-spacing: 0.05em;
        color: #333;
        font-family: $font-mincho;
        color: $main-blue;
        span{
          font-size: 15px;
          position: relative;
          bottom: 5px;
          margin-right: 4px;
          font-weight: normal;
        }
      }
    }
    &__nav{
      float: right;
      padding: 15px 0px 0px 0px;
      @include media($middle){
        display: none;
      }
      ul{
        li{
          display: inline-block;
          text-align: center;
          margin-right: 8px;
          font-size: 14px;
          a{
            display: block;
            font-family: $font-mincho;
            &:first-letter{
              color: $main-blue;
              font-size: 18px;
            }
            span{
              display: block;
              font-size: 10px;
              color: #aaa;
            }
          }
        }
      }
    }
    .header-sp{
      display: none;
      @include media($middle){
        display: block;
      }
      .drawer-hamburger{
        top:9px;
        z-index: 10000;
      }
      .drawer-nav{
        display: none;
        .drawer-menu{
          li{
            display: block;
            text-align: center;
            margin-right: 8px;
            font-size: 14px;
            padding: 5px;
            border-bottom: 1px solid #ddd;
            a{
              display: block;
              &:first-letter{
                color: $main-blue;
                font-size: 18px;
              }
              &:hover{
                text-decoration: none;
              }
              span{
                display: block;
                font-size: 10px;
                color: #aaa;
              }
            }
          }
        }
        @include media($middle){
          display: block;
        }
      }
    }

  }


  .bunjou_kurokawa__top-img{
    font-size: 0px;
    overflow: hidden;
    position: relative;
    .postion-ab{
      position:absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      width: 40%;
      @include media($mobile){
        width: 70%;
      }
    }
    .bg{
      animation-duration: 15s;/* アニメーション時間 */
      animation-name: ZoomTop;/* アニメーション名 */
      animation-iteration-count: 1;/* アニメーションの繰り返し（無限）*/
      animation-fill-mode:forwards;
    }
    img{
      width: 100%;
    }
  }
  .section-1{
    .bunjou_kurokawa__title{
      font-size: 34px;
      margin-bottom: 15px;
      &:first-letter{
        color: $main-blue;
        font-size: 38px;
      }
    }
    .bunjou_kurokawa__title-h3{
      font-size: 26px;
      @include media($mobile){
        font-size: 16px;
      }
    }
    .bunjou_kurokawa__title__small{
      margin-top: 3px;
    }
    .concept_wrap{
      display: table;
      &__list{
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        @include media($mobile){
          width: 100%;
          display: block;
        }
        &__item{
          width: 515px;
          margin: 0 auto;
          padding-left: 15px;
          @include media($mobile){
            width: 100%;
            padding: 0px 10px;
          }
          .charm{
            @include media($mobile){
              margin-bottom: 30px;
            }
          }
          .concept{
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
          .small{
            font-size: 12px;
            @include media($mobile){
              font-size: 10px;
            }
          }
          .large{
            font-family: $font-mincho;
            letter-spacing: 0.05em;
            font-size: 12px;
            line-height: 1.6;
            @include media($mobile){
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  //新着情報
  .bunjou_kurokawa__infomation{
    background-color: #f9f9f9;
    padding: 50px 0px;
    .bunjou_kurokawa__infomation__wrap{
      display: table;
      @include media($mobile){
        display: block;
        text-align: center;
      }
    }
    .bunjou_kurokawa__infomation__title{
      width: 20%;
      display: table-cell;
      vertical-align: middle;
      border-right: 1px solid #999;
      @include media($mobile){
        width: 100%;
        display: block;
        border-right:none;
      }
    }
    .bunjou_kurokawa__infomation__txt{
      width: 50%;
      display: table-cell;
      padding-left: 20px;
      padding-right: 20px;
      vertical-align: middle;
      @include media($mobile){
        margin-top: 15px;
        width: 100%;
        display: block;
        padding: 0px;
      }
      &__list{
        &__item{
          margin-bottom: 5px;
          color: #999;
          font-size: 12px;
          @include media($middle){
            font-size: 10px;
          }
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
    .bunjou_kurokawa__contact-form{
      display: table-cell;
      border: 1px solid $main-blue;
      padding: 15px 33px;
      width: 32%;
      @include media($middle){
        display: none;
      }
      &__title{
        color: $main-blue;
        border-bottom: 1px solid $main-blue;
        padding-bottom: 8px;
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 8px;
      }
      &__tel{
        text-align: center;
        margin-bottom: 5px;
        a{
          position: relative;
          color: $main-blue;
          font-weight: bold;
          font-size: 28px;
          font-family: $font-gothic;
          letter-spacing: 0.08em;
        }
      }
      &__mail{
        text-align: center;
        margin: 0px;
        a{
          font-weight: normal;
          color: $main-blue;
          border-radius: 20px;
          border: 1px solid $main-blue;
          font-size: 12px;
          padding: 5px 24px;
          &:before{
            content: "";
            display: inline-block;
            background-image: url('./assets/img/bunjo_kurokawa/envelop.svg');
            width: 15px;
            height: 15px;
            color: $main-blue;
            background-size: contain;
            margin-right: 5px;
            top:2px;
            position: relative;
          }
        }
      }
    }
  }
  .section-2{
    background-image: url('./assets/img/bunjo_kurokawa/bg-img-view.jpg');
    background-repeat: repeat;
    background-size: contain;
    border-top: 1px solid #333333;
    &:before{
      content:"";
      display: block;
      background-color: #333;
      height: 35px;
      width: 1px;
      margin: 0 auto;
      top:-50px;
      position:relative;
    }
    .kukakuzu-main{
      border: 5px solid #fffed9;
      width: 800px;
      margin: 0 auto;
      background-color: #fff;
      padding: 50px;
      margin-bottom: 30px;
      @include media($middle){
        width: 100%;
      }
      @include media($mobile){
        padding: 10px;
      }
    }
    p{
      font-size: 12px;
      @include media($mobile){
        font-size: 10px;
      }

    }
    .attention{
      font-size: 10px;
      color: #aaa;
    }
  }

  .section-3{
    border-top: 1px solid #333333;
    &:before{
      content:"";
      display: block;
      background-color: #333;
      height: 35px;
      width: 1px;
      margin: 0 auto;
      top:-50px;
      position:relative;
    }
    .main-img{
      margin-bottom: 50px;
      @include media($mobile){
        margin-bottom: 30px;
      }
      a{
        display: inline-block;
        font-size: 10px;
        text-decoration-line: underline;
        color: #aaa;
        @include media($mobile){
          font-size: 8px;
          display: block;
        }
      }
      p{
        display: inline-block;
        margin-right: 15px;
        font-family: $font-gothic;
        @include media($mobile){
          font-size: 10px;
          margin: 0px;
          line-height: 1.2;
        }
      }
    }
    .col-2-1{
      @include media($mobile){
        width: 100%;
        margin: 0px;
      }
    }
    .bunjou_kurokawa__location__list{
      @include clearfix;
      margin-bottom: 15px;
      &__item{
        font-family: $font-gothic;
        position: relative;
        border-bottom: 1px dashed #dddddd;
        margin-bottom: 5px;
        padding-bottom: 5px;
        span{
          position: absolute;
          right: 5px;
        }
        @include media($mobile){
          font-size: 10px;
        }
      }
    }
  }
  .section-4{
    background-color: #e8f1f8;
    border-top: 1px solid $main-blue;
    &:before{
      content:"";
      display: block;
      background-color: $main-blue;
      height: 35px;
      width: 1px;
      margin: 0 auto;
      top:-50px;
      position:relative;
    }

    .bunjou_kurokawa__detail__wrap{
      background-color: $main-blue;
      padding: 40px;
      color: #fff;
      font-weight: normal;
      margin-bottom: 28px;
      @include media($middle){
        padding: 15px;
      }
      .bunjou_kurokawa__detail__main{
        margin-bottom: 35px;
        @include media($mobile){
          margin-bottom: 20px;
        }
        dl{
          display: table;
          width: 100%;
          border-bottom: 1px solid #fff;
          padding-bottom: 5px;
          font-family: $font-gothic;
        }
        dt,dd{
          display: table-cell;
          vertical-align: middle;
          text-align: left;
          @include media($middle){
            font-size: 10px;
          }
          @include media($mobile){
            display: block;
            font-size: 14px;
            text-align: center;
          }
        }
        dt{
          margin-right: 10px;
          font-weight: normal;
          &:before{
            content:"■";
            display: inline-block;
            margin-right: 5px;
          }
          @include media($mobile){
            margin: 0px;
          }
        }
        dd{
          .large{
            font-size: 30px;
            color: #fffb00;
            font-family: $font-mincho;
            margin-right: 5px;
            letter-spacing: 0.05em;
          }
        }
      }
      .bunjou_kurokawa__detail__contents{
        @include clearfix;
        display: table;
        width: 100%;
        .bunjou_kurokawa__detail__detail{
          display: table-cell;
          width: 52%;
          vertical-align: middle;
          @include media($mobile){
            display: block;
            width: 100%;
          }
          dl{
            @include clearfix;
            border-bottom: 1px solid #fff;
            padding-bottom: 10px;
            font-family: $font-gothic;
            font-size: 14px;
            margin-bottom: 10px;
            @include media($middle){
              font-size: 10px;
              margin-bottom: 15px;
            }
            @include media($mobile){
              padding-bottom: 15px;
            }
            dt,dd{
              float: left;
              margin-bottom: 5px;
            }
            dt{
              text-align: left;
              font-weight: normal;
              width: 18%;
              padding-left: 7px;
              &:before{
                content:"■";
                display: inline-block;
                margin-right: 1px;
              }
              @include media($mobile){
                width: auto;
                margin-right: 5px;
              }
            }
            dd{
              width: 32%;
              text-align: right;
              @include media($mobile){
                width: auto;
              }
            }
            .large{
              width: 82%;
              text-align: left;
              @include media($mobile){
                width: auto;
              }
            }
          }
          .point{
            font-family: $font-gothic;
            padding-left: 18%;
            @include media($middle){
              font-size: 12px;
            }
          }
        }
        .bunjou_kurokawa__detail__img{
          display: table-cell;
          width: 46%;
          padding-left: 2%;
          vertical-align: middle;
          @include media($mobile){
            display: block;
            width: 100%;
          }
        }
      }

    }
  }

  .section-5{
    .access-map{
      width: 100%;
      height: 450px;
    }
  }

  .section-6{
    .bunjou_kurokawa__contact{
      &__web{
        @include media($mobile){
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .must{
        background-color: red;
        color: #fff;
        padding: 3px 8px;
        margin-left: 10px;
        border-radius: 5px;
        font-size: 10px;
      }
      label{
        margin-bottom: 10px;
      }
      input{
        font-family: $font-mincho;

        padding: 3px 10px;
      }
      .wpcf7-submit{
        border-radius: 20px;
        padding: 8px 32px;
      }
      .wpcf7-not-valid-tip{
        margin-bottom: 5px;
      }
      .small{
        font-size: 11px;
      }
      .google{
        display: inline-block;
        margin-bottom: 30px;
      }
      .wpcf7-form-control-wrap{
        text-align: center;
      }

      &__tel{
        text-align: center;
        @include media($mobile){
          width: 100%;
        }
        img{
          width: 80%;
          margin-bottom: 10px;
          @include media($middle){
            width: 95%;
          }
        }
        .tel{
          display: inline-block;
          color: #333;
          font-weight: bold;
          font-family: $font-gothic;
          letter-spacing: 0.08em;
          margin-bottom: 0px;

          @include media($middle){
            font-size: 40px;
          }
          @include media($mobile){
            display: block;
            font-size: 36px;
            text-align: center;
          }
        }
        dl{
          width: 400px;
          margin: 0 auto;
          margin-bottom: 4px;
          text-align: center;
          color: #333;
          @include media($mobile){
            width: 100%;
          }
          dt,dd{
            display: inline-block;
            @include media($middle){

            }
          }
          dt{
            padding: 2px 10px;
            margin-right: 2px;
            font-size: 14px;
            @include media($middle){
              margin: 0px;
            }
            @include media($mobile){
              font-size: 12px;
              margin-bottom: 0px;
            }
          }
          dd{
            font-size: 16px;
            letter-spacing: 0.02em;
            //color: #333;
            @include media($mobile){
              font-size: 14px;
              margin-bottom: 0px;
            }
          }
        }
        .content{
          text-align: left;
          width: 400px;
          margin: 0 auto;
          margin-bottom: 40px;
          color: #aaa;
          @include media($mobile){
            width: 100%;
          }
        }
      }
      .policy{
        border:1px solid #aaa;
        padding: 10px;
        margin: 0 auto;
        width: 400px;
        height: 400px;
        overflow-y: scroll;
        text-align: left;
        @include media($middle){
          width: 100%;
        }
        h4{
          font-size: 16px;
        }
      }
    }
  }

  .fixed{
    position: fixed;
    left: 0px;
    background-color: #fff;
    z-index: 9999;
  }


  .page-top{
    width: 50px;
    height: 50px;
    position: fixed;
    right:50px;
    bottom:60px;
    @include media($middle){
      right: 10px;
      bottom: 10px;
    }


  }

  .inview{
    opacity: 0;
  }


  .mv01{
    opacity: 1;
    animation-duration: 1s;/* アニメーション時間 */
    animation-name: RightToLeft;/* アニメーション名 */
    animation-iteration-count: 1;/* アニメーションの繰り返し（無限）*/
  }

  @keyframes RightToLeft {
    0% {
      opacity: 0;/* 透明 */
      transform: translateY(50px);/* X軸方向に50px */
    }
    100% {
      opacity: 1;/* 不透明 */
      transform: translateY(0);
    }
  }


  @keyframes ZoomTop{
    0% {
      -moz-transition: -moz-transform 0.5s linear;
      -webkit-transition: -webkit-transform 0.5s linear;
      -o-transition: -o-transform 0.5s linear;
      -ms-transition: -ms-transform 0.5s linear;
      transition: transform 0.5s linear;
    }
    100% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }



}
.kurokawa-footer{

}
