body {
-webkit-print-color-adjust: exact;
}
.print-page{
  width: 690px;
  margin: 0 auto;
  font-size: 11px;
  @include clearfix;
  &__heading{
    @include clearfix;
    margin: 0;
    &__title{
      font-size: 16px;
      line-height: 1.3;
      margin: 0;
    }
    &__num{
      float: right;
      font-size: 12px;
    }
  }
  &__images{
    &__large{
      @include clearfix;
      margin-bottom: 5px;
      &__item{
        @include span-columns(6);
        @include omega(2n);
        text-align: center;
        img{
          line-height: 1;
          max-width: 100%;
          vertical-align: top;
          height: 140px;
        }
      }
    }
    &__small{
      @include clearfix;
      &__item{
        @include span-columns(2.4);
        text-align: center;
        img{
          line-height: 1;
          max-width: 100%;
          vertical-align: top;
          height: 80px;
        }
      }
    }
  }
  &__left{
    width: 49%;
    float: left;
  }
  &__right{
    width: 49%;
    float: right;
  }
  table{
    width: 100%;
    font-size: 11px;
    margin: 5px 0;
    tr{
      th{
        padding: 2px 10px;
        width: 110px;
        //background: #dcdcdc !important;
        border: 1px solid #ccc;
      }
      td{
        padding: 2px 10px;
        border: 1px solid #ccc;
      }
    }
  }
  &__bottom{
    line-height: 1.3;
    &__note{
      margin-bottom: 5px;
    }
    &__top{
      @include clearfix;
      &__left{
        float: left;
      }
      &__right{
        float: right;
      }
    }
    &__info{
      text-align: left;
      &__item{
        display: inline-block;
        font-size: 12px;
        margin: 3px 20px 0 0;
      }
    }
  }
  &__btns{
    margin: 15px 0;
    text-align: center;
    @media print{
    .no_print{
        display: none;
      }
    }
  }
}
