.header{
  width: 100%;
  &.mobile-header{
    .header__navi{
      opacity: 1;
      transition: all 0.3s;
      &.is-fixed {
      	position: fixed;
      	top: 0;
      	left: 0;
      	z-index: 3333;
      	width: 100%;
      }
    }
  }
  &__bar{
    background: #d90000;
    padding: 5px 10px;
    &__item{
      color: #fff;
      font-size: 0.675rem;
      line-height: 1.3;
    }
  }

  &__container{
    position: relative;
    width: 100%;
    height: 55px;
    background: url(./assets/img/sp_header_bg.png) no-repeat center bottom;
    background-size: cover;
  }

  &__logo{
    position: absolute;
    left: 10px;
    bottom: -1px;
    margin: 0;
    vertical-align: bottom;
    img{
      vertical-align: bottom;
    }
  }

  &__contact{
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 0;
    &__tel{
      display: inline-block;
      font-size: 1rem;
      margin-right: 5px;
    }
    &__mail{
      display: inline-block;
      font-size: 1rem;
    }
  }

  &__navi{
    display: block;
    background: #dcdfe7; /* Old browsers */
    background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 ); /* IE6-9 */
    border-bottom: 2px solid #969698;
    &__list{
      text-align: center;
      font-size: 0;
      padding: 10px 0;
      &__item{
        display: inline-block;
        vertical-align: middle;
        //font-size: .65rem;
        font-size: calc(11* (100vw / 375));
        border-left: 1px solid #cdcdce;
        &:first-child{
          border-left: none;
        }
        a{
          display: block;
          padding: 5px 4px;
          vertical-align: middle;
          color: #010101;
          font-weight: bold;
          position: relative;
        }
      }
    }
  }
}
