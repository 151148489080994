body.page-template-page-bunjou_3{
  background: url(./assets/img/bunjo_2/bunjo-2-bg.jpg) repeat center center;
  background:none;
  margin: 0;
  font-family: $font-family;
}

$content-width-iseki: 1200px;
$font-mincho: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','メイリオ', Meiryo,serif;
$font-gothic: 'Yu Gothic UI','ＭＳ ゴシック','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,sans-serif;


$main-blue: #0071b9;


.bunjou_iseki{
  .drawer-hamburger-icon, .drawer-hamburger-icon::before, .drawer-hamburger-icon::after{
    background-color: #fff;
  }
  .open{
    .drawer-hamburger-icon::before, .drawer-hamburger-icon::after{
      background-color: #222;
    }
  }
  .page-btn-contact{
    @include media($middle){
      display: block;
    }
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: $main-blue;
    padding: 15px 15px;
    a{
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;

    }
    img{
      width: 20px;
      display: inline-block;
      margin-right: 10px;
    }
  }
  button{

    &:focus{
      outline: none;
    }
  }
  .txt-center{
    text-align:center;
  }
  .iseki-btn{
    a{
      display: inline-block;
      background-color: #222;
      color: #777;
      padding: 5px 25px;
      border-radius: 3px;
    }
  }

  .iseki-h2{
    margin-bottom: 50px;
    @include media($mobile){
      margin-bottom: 40px;
    }
    h2{
      font-size: 50px;
      letter-spacing: 0.2em;
      color: #222;
      font-family: $font-mincho;
      font-weight: normal;
      margin-bottom: 0px;
      @include media($mobile){
        font-size: 40px;
      }
    }
    p{
      font-family: $font-mincho;
      font-size: 20px;
      color: #222;
      @include media(mobile){
        font-size: 16px;
      }
    }
  }
  .iseki-h2--center{
    margin-bottom: 50px;
    h2{
      font-size: 50px;
      letter-spacing: 0.2em;
      color: #222;
      text-align: center;
      font-family: $font-mincho;
      font-weight: normal;
      margin-bottom: 0px;
      @include media($mobile){
        font-size: 40px;
      }
    }
    p{
      text-align: center;
      font-family: $font-mincho;
      font-size: 20px;
      color: #222;
      @include media($mobile){
        font-size: 16px;
      }
    }
  }


  .section{
    padding: 50px 0px;
  }

  .content-width{
    width: $content-width-iseki;
    margin: 0 auto;
    max-width: 90%;
    @include media($middle){
      width: 90%;
    }
  }



  //トップ画像の次の青い箇所
  .bunjou_kurokawa__blue{
    background-color: $main-blue;
    color: #fff;
    padding: 25px 0px 30px;
    margin-bottom: 100px;
    @include media($mobile){
      margin-bottom: 60px;
    }
    h1{
      font-family: $font-mincho;
      font-size: 38px;
      font-weight: normal;
      letter-spacing: 0.05em;
      text-align: center;
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      margin-bottom: 20px;
      @include media($middle){
        font-size: 24px;
      }
      @include media($mobile){
        font-size: calc(18 * (100vw / 375));
        line-height: 1.8;
      }
      .green{
        background-color: #008f45;
        color: #fff;
        border-radius: 3px;
        padding: 9px 15px;
        vertical-align: middle;
        font-size: 18px;
        font-weight: normal;
        margin-right: 10px;
        font-family: $font-gothic;
        @include media($mobile){
          font-size: 12px;
          display: block;
          width: 220px;
          margin: 0 auto 10px;
          padding: 4px 0px;
        }
      }
      .small{
        font-family: $font-gothic;
        font-size: 14px;
        font-weight: normal;
        margin-left: 10px;
        @include media($mobile){
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
    .border-white{
      border: 1px solid #fff;
      font-family: $font-mincho;
      padding: 5px 15px;
      font-size: 24px;
      margin-right: 10px;
      @include media($mobile){
        padding: 5px 10px 3px;
        font-size: 14px;
        width: 180px;
        display: block;
        margin: 0 auto 10px;
      }
    }
    p{
      font-family: $font-mincho;
      display: inline-block;
      font-size: 28px;
      margin: 0px;
      @include media($mobile){
        font-size: calc(18 * (100vw / 375));
        margin-bottom: 10px;
      }
      .yellow{
        color: #fffb00;
        letter-spacing: 0.01em;
      }
    }
    .attention{
      display: inline-block;
      font-size: 10px;
      text-align: left;
      margin-left: 10px;
    }
  }



  .mb70{
    margin-bottom: 70px;
  }
  .mb50{
    margin-bottom: 50px !important;
    @include media($mobile){
      margin-bottom: 30px !important;
    }
  }
  .mb30{
    margin-bottom: 30px;
  }
  .mb10{
    margin-bottom: 10px !important;
  }
  .mb0{
    margin-bottom: 0px !important;
  }



  .bunjou_iseki__header{
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1000;
    width: 100%;
    height: 76px;
    background: $main-blue;
    @include clearfix;
    @include media($mobile){
      height: 60px;
    }
    &__logo{
      float: left;
      padding: 15px 0px 15px 0px;
      a{
        font-size: 30px;
        letter-spacing: 0.05em;
        color: #fff;
        font-family: $font-mincho;
        @include media($mobile){
          font-size: 20px;
        }
        span{
          font-size: 15px;
          position: relative;
          bottom: 5px;
          margin-right: 4px;
          font-weight: normal;
          @include media($mobile){
            font-size: 11px;
            bottom: 2px;
          }
        }
      }
    }
    &__nav{
      float: right;
      padding: 15px 0px 0px 0px;
      @include media($middle){
        display: none;
      }
      ul{
        li{
          display: inline-block;
          text-align: center;
          margin-right: 12px;
          font-size: 17px;
          letter-spacing: 0.05em;
          a{
            display: block;
            font-family: $font-mincho;
            color: #fff;
            span{
              display: block;
              font-size: 10px;
              color: #fff;
            }
          }
        }
      }
    }
    .header-sp{
      display: none;
      @include media($middle){
        display: block;
      }
      .drawer-hamburger{
        top:9px;
        z-index: 10000;
        @include media($mobile){
          top:0px;
        }
      }
      .drawer-nav{
        display: none;
        padding: 40px;
        .drawer-menu{
          li{
            display: block;
            text-align: center;
            margin-right: 8px;
            font-size: 14px;
            padding: 5px;
            border-bottom: 1px solid #ddd;
            a{
              display: block;
              &:first-letter{
                color: $main-blue;
                font-size: 18px;
              }
              &:hover{
                text-decoration: none;
              }
              span{
                display: block;
                font-size: 10px;
                color: #aaa;
              }
            }
          }
        }
        @include media($middle){
          display: block;
        }
      }
    }

  }


  .bunjou_iseki__top-img{
    font-size: 0px;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 76px);
    min-height: 500px;
    @include media($mobile){
      height: calc(100vh - 60px);
    }

    .postion-ab{
      position:absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      width: 80%;
      max-width: 1200px;
      @include media($middle){
        max-width: 500px;
      }
      @include media($mobile){
        width: 80%;
      }
    }
    .bg{
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-image: url('./assets/bunjou/iseki/iseki-top.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      animation-duration: 20s;/* アニメーション時間 */
      animation-name: ZoomTop;/* アニメーション名 */
      animation-iteration-count: 1;/* アニメーションの繰り返し（無限）*/
      animation-fill-mode:forwards;
    }
    img{
      width: 100%;
    }
  }
  .section-1{
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      .col-4-1{
        width: 24%;
        margin-right: 1%;
        &:nth-child(4n){
          margin-right: 0px;
        }
        @include media($mobile){
          width: 48%;
          margin-right: 4%;
          margin-bottom: 4%;
          &:nth-child(2n){
            margin-right: 0px;
          }
        }
        img{
          width: 100%;
        }
        p{
          text-align: right;
        }
      }
    }
  }

  .section-2{
    margin: 100px 0px;
    padding: 60px 0px;
    @include media($mobile){
      margin: 60px 0px;
    }
    position: relative;
    &:before{
      z-index: -1;
      content: '';
      width: 65%;
      height: 100%;
      background-color: #efebe8;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    &-wrap{
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      video{
        width: 100%;
      }
    }
  }

  .section-3{
    padding: 100px 0px;
    position: relative;
    margin-bottom: 100px;
    @include media($mobile){
      margin-bottom: 60px;
    }
    &:before{
      z-index: -1;
      content: '';
      width: 65%;
      height: 100%;
      background-color: #efebe8;
      position: absolute;
      left: 0px;
      top: 0px;
      @include media($middle){
        width: 100%;
      }
    }
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      @include media($middle){
        max-width: 600px;
        margin: 0 auto;
      }
      &-img{
        width: 50%;
        @include media($middle){
          width: 100%;
          margin-bottom: 30px;
        }
      }
      &-txt{
        width: 50%;
        padding-left: 5%;
        @include media($middle){
          width: 100%;
          padding-left: 0px;
        }
        &-dl{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include media($mobile){
            align-items:inherit;
          }
          dt{
            width: 20%;
            font-size: 17px;
            font-family: $font-mincho;
            margin-bottom: 8px;;
            @include media($mobile){
              width: 30%;
              font-size: 14px;
              border-bottom: 1px solid #222;
              padding-bottom: 5px;
            }
          }
          dd{
            margin-bottom: 8px;
            font-family: $font-mincho;
            width: 80%;
            font-size: 17px;
            @include media($mobile){
              border-bottom: 1px solid #222;
              padding-bottom: 5px;
              width: 70%;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .section-4{
    .bunjou__section-4__list{
      width: 100%;
      margin-bottom: 50px;
      img{
        width: 100%;
      }
      @include media($middle){

      }
      .slick-slide{
        height: auto;
      }
      .bunjou__section-4__list__item{

        &__name{
          text-align: center;
          padding: 10px 0px;
          background-color: #efebe8;
          border-right: 1px solid #222;
          border-top: 1px solid #222;
        }
        img{
          width: 100%;
        }
        &__info{
          text-align: center;
          padding: 10px 0px;
          background-color: #efebe8;
          border-right: 1px solid #222;
          border-bottom: 1px solid #222;
          @include media($mobile){
            font-size: 10px;
          }
        }
      }
    }

    .bunjou__section-4__txt{
      @include clearfix;
    }
    .bunjou__section-4__txt__item{
      @include media($large){
        @include span-columns(4);
        @include omega(3n);
      }
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
      }
      @include media($mobile){
        @include span-columns(12);
        @include omega(n);
      }
      margin-bottom: 30px;
    }
    .bunjou__section-4__txt__item--h3{
      font-size:16px;
      font-weight: bold;
      background-color: #0071b9;
      color: #fff;
      border: 1px solid #222;
      text-align: center;
      font-family: $font-family;
      padding: 6px 0px;
      letter-spacing: 0.5em;
      @include media($mobile){
        letter-spacing: 0.1em;
      }
    }
    .bunjou__section-4__txt__item__table{
      display: table;
      font-size: 14px;
      margin : 0 auto;

      .bunjou__section-4__txt__item__table--dt{
        width: 300px;
        display: table-cell;
        font-weight: normal;
        @include media($middle){
          width: 140px;
        }

      }
      .bunjou__section-4__txt__item__table--line{
        margin-right: 5px;
        &:after{
          content: "";
          display: inline-block;
          background: #fff;
          width: 60px;
          border-bottom: 1px solid #000;
          height: 1px;
          position: relative;
          bottom: 5px;
          @include media($mobile){
            width: 30px;
          }
        }
      }
      .bunjou__section-4__txt__item__table--dd{
        display: table-cell;
        text-align: right;
        width: 128px;
        @include media($middle){
          width: 70px;
        }
      }
    }
  }


  .section-1{
    .bunjou_kurokawa__title{
      font-size: 34px;
      margin-bottom: 15px;
      &:first-letter{
        color: $main-blue;
        font-size: 38px;
      }
    }
    .bunjou_kurokawa__title-h3{
      font-size: 26px;
      @include media($mobile){
        font-size: 16px;
      }
    }
    .bunjou_kurokawa__title__small{
      margin-top: 3px;
    }
    .concept_wrap{
      display: table;
      &__list{
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        @include media($mobile){
          width: 100%;
          display: block;
        }
        &__item{
          width: 515px;
          margin: 0 auto;
          padding-left: 15px;
          @include media($mobile){
            width: 100%;
            padding: 0px 10px;
          }
          .charm{
            @include media($mobile){
              margin-bottom: 30px;
            }
          }
          .concept{
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
          .small{
            font-size: 12px;
            @include media($mobile){
              font-size: 10px;
            }
          }
          .large{
            font-family: $font-mincho;
            letter-spacing: 0.05em;
            font-size: 12px;
            line-height: 1.6;
            @include media($mobile){
              font-size: 10px;
            }
          }
        }
      }
    }
  }




  .section-7{
    overflow: hidden;
    margin-bottom: 100px;
    position: relative;
    &:before{

      content: '';
      display: block;
      width: 80%;
      max-width: 1000px;
      height: 100%;
      background-image: url('./assets/bunjou/iseki/sec01-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      @include media($mobile){
        display: none;
      }
    }
    @include media($mobile){
      margin-bottom: 60px;
    }
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      max-width: 1105px;
      margin: 0 auto;
      &-item{
        width: 100%;
        margin-bottom: 40px;
        &-row{
          display: flex;
          flex-wrap: wrap;
          //margin-bottom: 40px;
          &-img{
            width: 50%;
            background-repeat: no-repeat !important;
            background-size:contain !important;
            @include media($mobile){
              width: 100%;
              height: 350px;
              background-position: bottom !important;
            }
          }
          &-txt{
            width: 50%;
            background: #0071b9;
            padding: 52px;
            position: relative;
            @include media($middle){
              padding: 40px 20px;
            }
            @include media($mobile){
              width: 100%;
              padding: 40px 20px;
              max-width: 380px;
              margin: 0 auto;
            }
            &:before{
              position: absolute;
              left: 0px;
              top:0px;
              width: 50vw;
              height: 100%;
              background-color: #0071b9;
              content: '';
              z-index: -1;
            }
            .section-7__bukken-02__txt--h3{
              //h3要素
              font-family:$font-family;
              font-size: 24px;
              margin-bottom: 30px;
              color: #fff;
              border-bottom: 2px solid #fff;
              padding-bottom: 10px;
              text-align: center;
              @include media($middle){
                font-size: 20px;
                margin-bottom: 15px;
              }
              @include media($mobile){
                font-size: 20px;
                margin-bottom: 20px;
              }
            }
            .section-7__bukken-02__txt--dl{
              display: flex;
              margin-bottom: 20px;
              align-items: center;
              margin-left: 0px;
              @include media($middle){
                width: 300px;
                margin: 0 auto;
                margin-bottom: 10px;
              }
              @include media($mobile){
                width: 100%;
              }
              &:last-child{
                justify-content: flex-end;
                border-bottom: 2px solid #fff;
                dt{
                  background-color: inherit;
                  color: #fff;
                  text-align: right;
                  width: calc(100% - 270px);
                  @include media($middle){
                    width: calc(100% - 190px);
                  }
                }
                dd{
                  width: 270px;
                  text-align: right;
                  @include media($middle){
                    width: 190px;
                  }
                  span{
                    font-size: 40px;
                    font-weight: bold;
                    color: yellow;
                    display: inline-block;
                    margin-right: 5px;
                    letter-spacing: 0.05em;
                    font-family: $font-mincho;
                    @include media($middle){
                      font-size: 30px;
                    }
                  }
                }
              }
            }
            p{
              color: #fff;
              font-size: 12px;
            }
            .section-7__bukken-02__txt--title{
              font-size: 16px;
              background-color: yellow;
              color: #0071b9;
              font-weight: normal;
              width: 140px;
              line-height: 1.6;
              text-align: center;
              margin-right: 10px;
              letter-spacing: 0.05em;
              @include media($middle){
                font-size: 14px;
              }
              @include media($mobile){
                margin-right: 10px;
                width: 90px;
                font-size: 12px;
              }
            }
            .section-7__bukken-02__txt--content{
              font-size: 16px;
              letter-spacing: 0.05em;
              color: #fff;
              @include media($middle){
                font-size: 14px;
                letter-spacing: 0em;
              }
              @include media($mobile){
                font-size: 12px;
              }
            }
          }
        }
      }
    }


    .section-7-wrap-item-row-img--1{
      background: url('./assets/bunjou/iseki/iseki-pc-item-01_ver8.png');
      @include media($mobile){
        background: url('./assets/bunjou/iseki/iseki-sp-item-01_ver8.png');
      }
    }
    .section-7-wrap-item-row-img--2{
      background: url('./assets/bunjou/iseki/iseki-pc-item-02_ver8.png');
      @include media($mobile){
        background: url('./assets/bunjou/iseki/iseki-sp-item-02_ver8.png');
      }
    }
    .section-7-wrap-item-row-img--3{
      background: url('./assets/bunjou/iseki/iseki-pc-item-03_ver8.png');
      @include media($mobile){
        background: url('./assets/bunjou/iseki/iseki-sp-item-03_ver8.png');
      }
    }
    .section-7-wrap-item-row-img--4{
      background: url('./assets/bunjou/iseki/iseki-pc-item-04_ver8.png');
      @include media($mobile){
        background: url('./assets/bunjou/iseki/iseki-sp-item-04_ver8.png');
      }
    }
    .section-7-wrap-item-row-img--5{
      background: url('./assets/bunjou/iseki/iseki-pc-item-05_ver8.png');
      @include media($mobile){
        background: url('./assets/bunjou/iseki/iseki-sp-item-05_ver8.png');
      }
    }


  }
  .section-8{
    position: relative;
    margin-bottom: 100px;
    &:before{
      z-index: -1;
      content: '';
      width: 65%;
      height: 100%;
      background-color: #efebe8;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    &-wrap{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include media($mobile){
        flex-wrap: wrap-reverse;
      }
      &-map{
        width: 50%;
        height: 450px;
        @include media($middle){
          height: 400px;
        }
        @include media($mobile){
          width: 100%;
          height: 300px;
          margin-bottom: 20px;
        }
      }
      &-txt{
        width: 50%;
        padding-left: 5%;
        @include media($mobile){
          width: 100%;
          padding: 0px;
          margin-bottom: 30px;
        }
        p{
          font-size: 18px;
          line-height: 1.7;
          margin-bottom: 20px;
        }
      }
    }
  }
  .section-6{
    margin-bottom: 100px;
    @include media($mobile){
      margin-bottom: 60px;
    }
    .must{
      display: inline-block;
      background: #0071b9;
      color: #fff;
      font-weight: normal;
      margin-left: 10px;
      padding: 2px 15px;
      font-size: 12px;
    }
    input{
      margin-bottom: 30px;
    }
    .wpcf7-submit{
      padding: 15px 0px;
      width: 200px;
      font-weight: normal;
      margin-top: 30px;
    }
    .section-6-privacy{
      margin-top: 30px;
      height: 300px;
      padding: 40px;
      overflow-y: scroll;
      border: 1px solid #222;
      @include media($mobile){
        height: 200px;
        padding: 20px;
      }
      p,h4{
        font-size: 10px;
      }
    }
  }




  .fixed{
    position: fixed;
    left: 0px;
    background-color: $main-blue;
    z-index: 9999;
  }


  .page-top{
    width: 50px;
    height: 50px;
    position: fixed;
    right:50px;
    bottom:60px;
    @include media($middle){
      right: 0px;
      bottom: 0px;
    }


  }

  .inview{
    opacity: 0;
  }


  .mv01{
    opacity: 1;
    animation-duration: 1s;/* アニメーション時間 */
    animation-name: RightToLeft;/* アニメーション名 */
    animation-iteration-count: 1;/* アニメーションの繰り返し（無限）*/
  }

  @keyframes RightToLeft {
    0% {
      opacity: 0;/* 透明 */
      transform: translateY(50px);/* X軸方向に50px */
    }
    100% {
      opacity: 1;/* 不透明 */
      transform: translateY(0);
    }
  }


  @keyframes ZoomTop{
    0% {
      -moz-transition: -moz-transform 0.5s linear;
      -webkit-transition: -webkit-transform 0.5s linear;
      -o-transition: -o-transform 0.5s linear;
      -ms-transition: -ms-transform 0.5s linear;
      transition: transform 0.5s linear;
    }
    100% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }



}
.kurokawa-footer{

}
