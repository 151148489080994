.slider{
  overflow: hidden;
  padding: 0 6px;
  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  }

  &__list{
    width: 100%;
    padding: 7px;
    border-left: 4px solid #9acedb;
    border-right: 4px solid #9acedb;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(36deg, #dff2fa 0%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(36deg, #dff2fa 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(36deg, #dff2fa 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    &__item{
      text-align: center;
    }
  }

  .slick-prev, .slick-next {
    display: none;
  }
}


.top{
  .tab-content{
    display: none;
    &:first-child{
      display: block;
    }
    &__list{
      @include clearfix;
      padding-top: 1%;
      margin-bottom: 5px;
      &.tintai{
        .tab-content__list__item{
          background: url(./assets/img/icon_tintai.png) no-repeat left 8px center;
          background-size: 16px 16px;
          background-color: #fff;
        }
      }
      &.uri{
        .tab-content__list__item{
          background: url(./assets/img/icon_uri.png) no-repeat left 8px center;
          background-size: 16px 16px;
          background-color: #fff;
        }
      }
      &.bunjyou{
        .tab-content__list__item{
          background: url(./assets/img/icon_bunjyou.png) no-repeat left 8px center;
          background-size: 16px 16px;
          background-color: #fff;
        }
      }
      &__item{
        @include span-columns(6);
        @include omega(2n);
        margin-bottom: 1%;
        background: #fff;
        border-radius: 4px;
        border-bottom: 1px solid #abc1cb;
        text-align: left;
        font-weight: bold;
        font-size: 0.75em;
        letter-spacing: -0.01rem;
        a{
          display: block;
          padding: 15px 0 15px 30px;
        }
      }
    }
    &__area{
      padding: 0 0 10px 0;
      &__heading{
        font-size: .875rem;
        margin-bottom: 8px;
        padding: 5px 10px;
        cursor: pointer;
        &.open{
          &:before{
            content: "\f0de";
            position: relative;
            top: 4px;
          }
        }
        &:before{
          content: "\f0dd";
          display: inline-block;
          font-family: FontAwesome;
          font-size: 1rem;
          margin-right: 6px;
          position: relative;
          bottom: 3px;
        }
        &.tintai{
          background: #006EA7;
          color: #fff;
        }
        &.buy{
          background: #FF5E5E;
          color: #fff;
        }
        &.tochi{
          background: #5E951D;
          color: #fff;
        }
      }
      &__list{
        display: none;
        &.tintai{
          .tab-content__area__list__item{
            a{
              &:before{
                color: #006EA7;
              }
            }
          }
        }
        &.buy{
          .tab-content__area__list__item{
            a{
              &:before{
                color: #FF5E5E;
              }
            }
          }
        }
        &.tochi{
          .tab-content__area__list__item{
            a{
              &:before{
                color: #5E951D;
              }
            }
          }
        }
        &__item{
          background: #fff;
          border-radius: 4px;
          border-bottom: 1px solid #afbfc6;
          margin-bottom: 2px;
          display: inline-block;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            color: #000;
            display: block;
            padding: 5px 10px;
            font-size: 0.625rem;
            font-weight: bold;
            &:before{
              content: "\f041";
              display: inline-block;
              font-family: FontAwesome;
              font-size: .875rem;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  .sp-thumb-slider{
    padding: 10px 0;
    &__item{
      margin: 0 3px;
      background: #fff;
      &__thumb{
        text-align: center;
        overflow: hidden;
        height: 150px;
        img{
          width: 100%;
          height: auto;
        }
      }
      &__detail{
        padding: 10px 15px 15px 15px;
      }
      &__title{
        font-size: .875rem;
        line-height: 1.3;
        margin-bottom: 5px;
        a{
          color: #3452a9;
          text-decoration: underline;
        }
      }
      &__price{
        font-size: .75rem;
        display: inline-block;
        color: #ca2c2c;
        font-weight: bold;
        margin-right: 5px;
      }
      &__floor{
        position: absolute;
        left: 0;
        top: 0;
        background: #ca2c2c;
        font-size: .75rem;
        padding: 2px 5px;
        display: inline-block;
        color: #fff;
        font-weight: bold;
      }
      &__address{
        font-size: .675rem;
        color: #666666;
      }
    }
  }

  .home-search{
    &__container{
      padding: 10px 0;
    }
    &__form{
      padding: 10px;
      background: #fff;
      border-radius: 4px;
      font-size: 12px;
      @include clearfix;
      &__section{
        margin-bottom: 10px;
        ul{
          line-height: 2.2;
        }
        label{
          font-weight: normal;
        }
        select{
          font-size: 14px;
          border: 1px solid #999;
          padding: 4px;
        }
        option{
        }
      }
      &__txt-search{
        @include clearfix;
        margin-top: 15px;
        border-top: 1px solid #999;
        padding-top: 15px;
        input.home-search-input::-webkit-input-placeholder {
          font-size: 14px;
        }
        input.home-search-input:-ms-input-placeholder {
          font-size: 14px;
        }
        input.home-search-input::-moz-placeholder {
          font-size: 14px;
        }
        &__left{
          @include span-columns(9);
          input{
            width: 100%;
          }
        }
        &__right{
          @include span-columns(3);
          .btn__red{
            width: 100%;
            padding: 12px 0;
          }
        }
      }
      .btn__red{
        clear: both;
      }
    }
  }

}

.madori-search{
  @include clearfix;
  li{
    @include span-columns(6);
    @include omega(2n);
  }
}
.kakaku-search{
  @include clearfix;
  select{
    display: inline-block;
    width: 42%;
    font-size: 14px;
    margin: 0 5px;
  }
}
.kodawari-search{
  text-align: left;
  li{
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }
}
.top-bunjo-link{
  text-align: center;
  padding-top: 8px;
  margin-bottom: -10px;
  img{
    border: 1px solid #fff;
  }
}
.kominka-link{
  text-align: center;
  padding-top: 8px;
  margin-bottom: -20px;
  img{
    border: 1px solid #fff;
  }
}

.search-heading{
  background: #FF7E00;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 5px 5px 10px;
}
