.footer-fixed{
  position: fixed;
  bottom: 65px;
  left: 10px;
  z-index: 2223;
  img{
    width: 200px;
  }
}
.footer-fixed-btn{
  position: absolute;
  right: -25px;
  top: 0px;
  &:hover{
    cursor: pointer;
  }
  img{
    width: 20px;
  }
}

.mobile-navi{
  @include clearfix;
  opacity: 0.9;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2222;
  background: #dcdfe7; /* Old browsers */
  background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 ); /* IE6-9 */
  box-shadow:0px 0px 1px 1px rgba(0,0,0,0.3);
  &__list{
    text-align: center;
    font-size: 0;
    &__item{
      position: relative;
      font-size: 0.625rem;
      width: 25%;
      float: left;
      &:hover{
        background: #cdcdce;
      }
      &:nth-child(2){
        a{
          padding: 18px 0 12px;
        }
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
      &:after{
        content: "";
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        z-index: 3333;
        background: #cdcdce;
      }
      a{
        padding: 15px 0;
        display: block;
        img{
          width: auto;
          margin: 0 auto 3px;
          display: block;
          vertical-align: bottom;
        }
      }
    }
  }
}
.mobile-footer-bar{
  &:before{
    @include outer-container($content-width);
    content: "";
    display: block;
    height: 6px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }
}
.bottom-contact{
  position: relative;
  background: url(./assets/img/footer_door_bg.png) repeat center bottom;
  background-size: 100% 165px;
  &__container{
    position: relative;
    width: 290px;

    background-size: contain;
    height: 165px;
    margin: 0 auto;
  }
  &__btn{
    position: absolute;
    right: 10px;
    bottom: 20px;
    z-index:10;
  }
}
#mobile_img_01{
  position: absolute;
  width: 100%;
  z-index:5;
}
#mobile_img_02{
  position: absolute;

  top:49px;
  left:92px;
  z-index:3;
 }

 #mobile_img_03{
  position: absolute;
  top:49px;
  left:152px;
  z-index:3;
 }
 #mobile_peple img{
    position: absolute;
    right: 0;
    bottom: 2px;
    left: 0;
    margin: auto;
    z-index:2000;
    display:none;
    height:130px;
    width:auto;
 }

.footer{
  padding-bottom: 41px;
  &__container{
    @include clearfix;
    padding: 20px 10px;
  }

  &__logo{
    text-align: center;
    margin-bottom: 15px;
    img{
      vertical-align: bottom;
    }
  }
  &__tel{
    text-align: center;
    margin-bottom: 10px;
    img{
      vertical-align: bottom;
    }
  }
  &__txt{
    font-size: .75rem;
    color: #545454;
    text-align: center;
  }

  &__bottom{
    background: #c70000;

    &__container{
      padding: 20px;
    }

    &__navi{
      text-align: center;
      font-size: 0;
      line-height: 2.5;
      margin-bottom: 15px;
      &__item{
        display: inline-block;
        font-weight: bold;
        font-size: .875rem;
        &:last-child{
          &:after{
            display: none;
          }
        }
        &:after{
          content: "|";
          display: inline-block;
          font-size: .875rem;
          color: #fff;
          margin: 0 .875rem;
        }
        a{
          color: #fff;
        }
      }
    }

    .copy{
      display: block;
      text-align: center;
      color: #fff;
      font-size: .75rem;
    }

  }
  &__bunjo-link{
    text-align: center;
    padding-top: 8px;
    margin-bottom: -10px;
    img{
      border: 1px solid #fff;
    }
  }
  &__kominka-link{
    text-align: center;
    padding-top: 8px;
    margin-bottom: 0px;
    img{
      border: 1px solid #fff;
    }
  }
}


.add-fixed-koukoku{
  position: fixed;
  bottom: 62px;
  left:0px;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index:9998;
  padding: 5px 10px;
  &__list{
    @include clearfix;
    text-align: center;
    &__item{
      &:first-child{
        width: 23%;
        display: inline-block;
        //float: left;
      }
      &:last-child{
        width: 14%;
        //float: right;
        display: inline-block;
      }
    }
  }
  &__close{
    width: 50px;
    position: fixed;
    bottom:75px;
    right:10px;
    z-index: 9999;
    width: 25px;
  }
  img{
    display: block;
    width: 90%;

  }
}
