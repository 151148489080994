.company{

  .greeting{
    margin-bottom: 45px;
    &__txt{
    }
    &__name{
      text-align: right;
      margin-bottom: 30px;
    }
    &__photo{
      @include clearfix;
      padding: 0 30px;
      &__item{
        @include span-columns(6);
      }
    }
  }

  .info{
    &__table{
      @include outer-container(100%);
      tr{
        th{
          padding: 10px;
          width: 140px;
          background: #fafcfd;
          font-size: 0.8125rem;
          border: 1px solid #dedede;
          text-align: center;
          vertical-align: middle;
        }
        td{
          padding: 10px;
          border: 1px solid #ccc;
          vertical-align: middle;
        }
      }
    }
  }

}
