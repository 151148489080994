.sidebar{
  position: relative;
  z-index: 3333;
  margin-top: -4px;
  width: 260px;
  float: left;
  background: #dee1e8;
  margin-left: 20px;
  padding: 17px 10px;
  height: 100%;

  &__menu{
    background: #fff;
    padding-bottom: 20px;
    &__heading{
      position: relative;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      line-height: 1.3;
      &:after{
        content:"";
        display:block;
        position:absolute;
        left: 0;
        right: 0;
        bottom:-8px;
        width:0;
        margin: 0 auto;
        border:25px solid transparent;
        border-top: 10px solid transparent;
        border-top-color:#ccc;
        border-bottom-width:0;
      }
      &__icon{
        display: inline-block;
        padding: 15px 0;
      }
      a{
        color: #fff;
        display: block;
      }
    }
    &__list{
      padding: 10px 20px 15px 20px;
      &__item{
        border-bottom: 1px dotted #c7c7c7;
        a{
          display: block;
          padding: 5px 0;
        }
        label{
          font-weight: normal;
          line-height: 1.8;
          padding-top: 5px;
        }
      }
    }
    &__area{
      padding: 0 20px;
      &__heading{
        font-size: .875rem;
        margin-bottom: 5px;
        padding: 5px 10px;
        font-weight: bold;
        cursor: pointer;
        &.open{
          &:before{
            content: "\f0de";
            position: relative;
            top: 4px;
          }
        }
        &:before{
          content: "\f0dd";
          display: inline-block;
          font-family: FontAwesome;
          font-size: 1rem;
          margin-right: 6px;
          position: relative;
          bottom: 3px;
        }
        &.tintai{
          background: #006EA7;
          color: #fff;
        }
        &.buy{
          background: #FF5E5E;
          color: #fff;
        }
        &.tochi{
          background: #5E951D;
          color: #fff;
        }
      }
      &__list{
        display: none;
        &.tintai{
          .sidebar__menu__area__list__item{
            a{
              &:before{
                color: #006EA7;
              }
            }
          }
        }
        &.buy{
          .sidebar__menu__area__list__item{
            a{
              &:before{
                color: #FF5E5E;
              }
            }
          }
        }
        &.tochi{
          .sidebar__menu__area__list__item{
            a{
              &:before{
                color: #5E951D;
              }
            }
          }
        }
        &__item{
          background: #fff;
          margin-bottom: 3px;
          border-bottom: 1px dotted #c7c7c7;
          &:last-child{
            margin-bottom: 0;
          }
          a{
            color: #000;
            display: block;
            padding: 5px 5px;
            font-size: 0.813rem;
            &:before{
              content: "\f041";
              display: inline-block;
              font-family: FontAwesome;
              font-size: 1rem;
              margin-right: 6px;
            }
          }
        }
      }
    }
    &--tintai{
      .sidebar__menu__heading{
        background: #006ea7;
        &:after{
          border-top-color:#006ea7;
        }
        .sidebar__menu__heading__icon{
          background: url(./assets/img/icon_sidebar_tintai.png) no-repeat left center;
          background-size: 34px 33px;
          padding-left: 42px;
        }
      }
      .sidebar__menu__list__item{
        a{
          background: url(./assets/img/icon_tintai.png) no-repeat left center;
          background-size: 20px 20px;
          padding-left: 28px;
        }
      }
    }
    &--uri{
      .sidebar__menu__heading{
        background: #ff5e5e;
        &:after{
          border-top-color:#ff5e5e;
        }
        .sidebar__menu__heading__icon{
          background: url(./assets/img/icon_sidebar_uri.png) no-repeat left center;
          background-size: 28px 33px;
          padding-left: 36px;
        }
      }
      .sidebar__menu__list__item{
        a{
          background: url(./assets/img/icon_uri.png) no-repeat left center;
          background-size: 20px 20px;
          padding-left: 28px;
        }
      }
    }
    &--bunjyou{
      .sidebar__menu__heading{
        background: #5e951d;
        &:after{
          border-top-color:#5e951d;
        }
        .sidebar__menu__heading__icon{
          background: url(./assets/img/icon_sidebar_bunjyou.png) no-repeat left center;
          background-size: 30px 30px;
          padding-left: 38px;
        }
      }
      .sidebar__menu__list__item{
        a{
          background: url(./assets/img/icon_bunjyou.png) no-repeat left center;
          background-size: 20px 20px;
          padding-left: 28px;
        }
      }
    }
    &--search{
      .sidebar__menu__heading{
        background: #ff7e00;
        &:after{
          border-top-color:#ff7e00;
        }
        .sidebar__menu__heading__icon{
          background: url(./assets/img/icon_sidebar_search.png) no-repeat left center;
          background-size: 30px 30px;
          padding-left: 38px;
        }
      }
    }

    &__select{
      padding: 0 20px;
      margin-bottom: 20px;
      font-size: .75rem;
      &__item{
        margin: 0;
        padding: 0;
        font-size: .75rem;
        width: 44%;
        display: inline-block;
      }
    }

    &__word{
      margin-top: 20px;
      padding: 0 20px;
    }

  }

  &__pr{
    margin-top: 20px;
    &__title{
      text-align: center;
      margin: 0 0 15px 0;
      line-height: 1.3;
    }
    &__item{
      background: #fff;
      padding: 10px;
      &__thumb{
        margin-bottom: 10px;
        height: 170px;
        overflow: hidden;
        img{
          width: 100%;
          height: auto;
        }
      }
      &__name{
        font-size: .875rem;
        color: #3452a9;
        line-height: 1.3;
        a{
          color: #3452a9;
          text-decoration: underline;
        }
      }
      &__price{
        display: inline-block;
        color: #ca2c2c;
        font-weight: bold;
        margin-bottom: 5px;
      }
      &__floor{
        display: inline-block;
        color: #ca2c2c;
        font-weight: bold;
        margin-bottom: 5px;
      }
      &__address{
        color: #666666;
        font-size: .75rem;
      }
      &__access{
        color: #666666;
        font-size: .75rem;
      }
      &__years{
        color: #666666;
        font-size: .75rem;
      }
    }
    &__bunjo{
      padding: 5px;
      background-color: #fff;
      img{
        border: 1px solid #000;
      }
    }
    &__kominka{
      padding: 0px 5px;
      background-color: #fff;
      img{
        border: 1px solid #000;
      }
    }
    .slick-prev{
      left: 0;
      z-index: 2222;
      &:before{
        content: "";
        display: inline-block;
        opacity: 1;
        width: 20px;
        height: 20px;
        background: url(./assets/img/slider_icon_left.png) no-repeat;
        background-size: auto auto;
        background-size: cover;
      }
    }
    .slick-next{
      right: 0;
      z-index: 2222;
      &:before{
        content: "";
        display: inline-block;
        opacity: 1;
        width: 20px;
        height: 20px;
        background: url(./assets/img/slider_icon_right.png) no-repeat;
        background-size: auto auto;
        background-size: cover;
      }
    }
  }

  &__info{
    margin-top: 20px;
    &__title{
      text-align: center;
      margin: 0 0 15px 0;
      line-height: 1.3;
    }
    &__sub-title{
      border-left: 4px solid #0068b7;
      border-bottom: 1px solid #0068b7;
      font-size: 0.8125rem;
      padding: 2px 0 4px 8px;
    }
    &__container{
      background: #fff;
      padding: 15px 10px;
    }
    &__image{
      margin-bottom: 5px;
    }
    &__txt{
      font-size: .75rem;
      margin-bottom: 30px;
      text-align: justify;
    }
    .btn__simulation{
      width: 208px;
      font-size: .75rem;
      margin-bottom: 10px;
    }
  }

  &__reform{
    margin-top: 20px;
    min-height: 150px;
    &__title{
      text-align: center;
      margin: 0 0 15px 0;
      line-height: 1.3;
    }
    &__sub-title{
      border-left: 4px solid #0068b7;
      border-bottom: 1px solid #0068b7;
      font-size: 0.8125rem;
      padding: 2px 0 4px 8px;
    }
    &__container{
      background: #fff;
      padding: 15px 10px;
    }
    &__txt{
      font-size: .75rem;
      margin-bottom: 30px;
    }
    .btn__simulation{
      width: 208px;
      font-size: .75rem;
      margin-bottom: 10px;
    }
  }

  &__news{
    margin-top: 20px;
    min-height: 150px;
    position: relative;
    &__title{
      text-align: center;
      margin: 0 0 15px 0;
      line-height: 1.3;
    }
    &__sub-title{
      border-left: 4px solid #0068b7;
      border-bottom: 1px solid #0068b7;
      font-size: 0.8125rem;
      padding: 2px 0 4px 8px;
    }
    &__container{
      background: #fff;
      padding: 15px 10px;
    }
    &__txt{
      font-size: .75rem;
      margin-bottom: 30px;
    }
    &__link{
      text-align: right;
      margin-bottom: 10px;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
    &__list{
      &__item{
        margin-bottom: 5px;
        border-bottom: 1px dotted #7f7f7f;
      }
    }
  }

}
