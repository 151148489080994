.empty{
  width: 100%;
  max-width: 100%;

  .breadcrumb{
    padding-right: 0px;
  }

  .lower-gradient__wrap{
    border-right: 0px;
  }
  &-title--h1{
    margin: 30px 0 10px;
    padding: 0;
    font-size: 28px;
    line-height: 1.6;
    font-weight: 600;
    text-align: center;
    color: #104e6f;
    @include media($mobile){
      font-size: 16px;
      text-align: left;
    }
  }
  &-img{
    text-align: center;
    img{
      max-width: 750px;
      @include media($mobile){
        max-width: 100%;
      }
    }
  }
  &-wrap{
    margin-bottom: 60px;
    &-title--h3{
      margin: 20px 0 10px;
      padding: 0;
      font-size: 24px;
      font-weight: 500;
      color: #1aa8b5;
      line-height: 1.6;
      text-align: left;
      padding: 0px 30px;
      .txt-red{
        color: #ea512d;
      }
    }
    p{
      color: #104e6f;
      font-size: 16px;
      line-height: 1.8;
      padding: 0px 30px;
    }
    &-clear{
      @include clearfix;
      &-left{
        float: left;
        width: 48%;
        @include media($mobile){
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &-right{
        width: 48%;
        float: right;
        @include media($mobile){
          width: 100%;
        }
      }
      img{

      }
    }
    &-bbox{
      background-color: #1aa8b5;
      background-color: #4db7bf;
      border-radius: 3px;
      padding: 12px;
      color: #fff;
      h2{
        margin-bottom: 0;
        font-size: 15px;
        line-height: 1.5;
        font-weight: normal;
      }
    }
    &-btn{
      width: 350px;
      margin: 0 auto;
      @include media($mobile){
        width: 100%;
      }
      a{
        text-align: center;
        background-color: #d90000;
        color: #fff;
        display: block;
        padding: 10px 0px;
      }
    }
    &-link{
      text-align: center;
      margin-top: 25px;
      a{
        text-decoration: underline;
      }
    }
  }
}
