/* ---------- content-width ---------- */
$wrap-width: 1000px;
$content-width: 960px;
$lower-width: 720px;

/* ---------- breakpoints ---------- */
$mobile: new-breakpoint(max-width 767px 12);
$middle: new-breakpoint(max-width 1023px 12);
$large: new-breakpoint(min-width 1024px 12);

/* ---------- gutter ---------- */
$fg-gutter: 0.9375em;
