.detail{

  &__num{
    margin-bottom: 6px;
    &__item{
      dt{
        background: #ff6464;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        font-size: 0.75rem;
        padding: 0 5px;
      }
      dd{
        display: inline-block;
        font-size: 0.875rem;
      }
    }
  }

  &__top-content{
    padding-top: 15px;

    &__title{
      font-size: 1.125rem;
      margin-bottom: 10px;
      line-height: 1.5;
      &:before{
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        position: relative;
        top: 5px;
      }
      &__tintai{
        &:before{
          background: url(./assets/img/icon_tintai.png) no-repeat center center;
          background-size: cover;
        }
      }
      &__uri{
        &:before{
          background: url(./assets/img/icon_uri.png) no-repeat center center;
          background-size: cover;
        }
      }
      &__bunjyou{
        &:before{
          background: url(./assets/img/icon_bunjyou.png) no-repeat center center;
          background-size: cover;
        }
      }
    }

    &__box{
      position: relative;
      background: #fff;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 20px;

      &__top{
        @include clearfix;
        padding-bottom: 13px;
        margin-bottom: 13px;
        border-bottom: 1px solid #bfbfbf;
        &__left{
          //display: table-cell;
          display: block;
          font-size: 0;
          //border-right: 1px solid #ccc;
          padding: 5px 15px 5px 0;
          .rent{
            display: inline-block;
            dt{
              font-size: 0.875rem;
              font-weight: normal;
              display: inline-block;
              margin-right: 5px;
            }
            dd{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              .strong{
                //font-size: 1.5rem;
                font-size: 1.125rem;
                line-height: 1.3;
                font-weight: bold;
                color: #ff6464;
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
          .service-fee{
            font-size: .625rem;
            dt,dd{
              font-weight: normal;
              display: inline;
            }
          }
        }

        &__right{
          font-size: 0;
          //padding: 5px 0 5px 15px;
          //display: table-cell;
          display: block;
          .floor{
            display: inline-block;
            dt{
              font-size: 0.875rem;
              font-weight: normal;
              display: inline-block;
              margin-right: 5px;
            }
            dd{
              font-size: 1.125rem;
              font-weight: normal;
              display: inline-block;
              .strong{
                //font-size: 1.5rem;
                font-size: 1.125rem;
                font-weight: bold;
                color: #ff6464;
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
          .area-size{
            font-size: .75rem;
            dt,dd{
              font-weight: normal;
              display: inline;
            }
          }
        }

      }

      &__bottom{

        .detail__num{
          position: absolute;
          right: 0;
          top: -47px;
          background: url(./assets/img/detail_num_bg.png) no-repeat;
          width: 189px;
          height: 48px;
          &__item{
            margin: 27px 0 0 56px;
            dt{
              display: inline-block;
              font-weight: normal;
              font-size: 0.75rem;
            }
            dd{
              display: inline-block;
              font-size: 0.75rem;
            }
          }
        }
        .other-info{
          @include clearfix;
          margin-bottom: 15px;
          &__item{
            @include span-columns(6);
            @include omega(2n);
            margin-bottom: 1%;
            &__w100{
              @include span-columns(12);
              @include omega(1n);
            }
            dt{
              color: #fff;
              background: #ff6464;
              font-weight: normal;
              font-size: .75rem;
              min-width: 70px;
              text-align: center;
              padding: 0 5px;
              display: inline-block;
              margin-right: 5px;
            }
            dd{
              display: inline-block;
              font-size: .75rem;
            }
            &__btn-map{
              display: inline-block;
              margin-left: 10px;
              a{
                display: block;
                padding: 2px 10px;
                border-radius: 4px;
                font-size: .75rem;
                border: 1px solid #b7b7b7;
                background: #e7e7e7; /* Old browsers */
                background: #ffffff; /* Old browsers */
                /* IE9 SVG, needs conditional override of 'filter' to 'none' */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg4JSIgc3RvcC1jb2xvcj0iI2U3ZTdlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
                background: -moz-linear-gradient(top, #ffffff 0%, #e7e7e7 88%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #ffffff 0%,#e7e7e7 88%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #ffffff 0%,#e7e7e7 88%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e7e7',GradientType=0 ); /* IE6-8 */
                &:after{
                  content: "";
                  display: inline-block;
                  width: 12px;
                  height: 16px;
                  background: url(./assets/img/icon_map.png) no-repeat center center;
                  background-size: cover;
                  margin-left: 5px;
                  position: relative;
                  top: 3px;
                }
              }
            }
          }
        }

        .point{
          dt{
            position: relative;
            display: inline-block;
            font-size: 0.8125rem;
            font-weight: bold;
            border: 1px solid #ff6464;
            border-radius: 10px;
            padding: 0 10px 0 30px;
            margin-right: 10px;
            &:before{
              position: absolute;
              left: 0;
              top: -6px;
              content: "";
              display: inline-block;
              background: url(./assets/img/icon_point.png) no-repeat center center;
              background-size: cover;
              width: 25px;
              height: 28px;
            }
          }
          dd{
            display: inline-block;
            font-size: .75rem;
          }
        }

      }

    }

  }


  .viewer {
    @include clearfix;
    height: auto !important;
    &__main{
      img{
        background: #fff;
        padding: 2px;
      }
    }
  }
  .viewer div {
      position: relative;
      width: 290px;
      height: 226px;
      margin: 0 auto;
      overflow: hidden;
  }
  .viewer img{
    line-height: 1;
    vertical-align: top;
  }
  .viewer div img {
      top: 0;
      left: 0;
      position: absolute;
  }

  .viewer ul {
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      @include clearfix;
  }

  .viewer ul li {
    @include span-columns(4);
    @include omega(3n);
    cursor: pointer;
    text-align: center;
    background: #fff;
    padding: 2px;
    margin-bottom: 3px;
  }
  .viewer ul li img{
    height: 80px;
    width: auto;
    overflow: hidden;
  }

  .viewer .active {
      filter:alpha(opacity=100)!important;
      -moz-opacity: 1!important;
      opacity: 1!important;
  }


  /* =======================================
      ClearFixElements
  ======================================= */
  .viewer ul:after {
      content: ".";
      height: 0;
      clear: both;
      display: block;
      visibility: hidden;
  }

  .viewer ul {
      display: inline-block;
      overflow: hidden;
  }

  .more-title{
    background: #3d77ae;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 1rem;
    padding: 10px 0;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  .more-image{
    text-align: center;
    padding: 10px;
    background: #fff;
    margin-bottom: 20px;
  }

  .more-info-01,.more-info-02{
    border-radius: 4px;
    border: 1px solid #dedede;
    padding: 0;
    margin: 0 0 15px 0;
    font-size: .75rem;
    table{
      width: 100%;
      margin: 0;
      th{
        background: #e3eef4;
        border: 1px solid #dedede;
        padding: 10px;
        font-weight: normal;
        width: 30%;
      }
      td{
        background: #fff;
        border: 1px solid #dedede;
        padding: 10px;
      }
    }
  }

  .more-notices{
    border: 1px solid #dedede;
    border-radius: 4px;
    margin-bottom: 10px;
    &__item{
      dt{
        background: #ffe3e3;
        color: #ff0000;
        text-align: center;
        border-bottom: 1px solid #dedede;
        font-size: 1rem;
        padding: 10px 0;
        font-weight: normal;
      }
      dd{
        background: #fff;
        font-size: .875rem;
        padding: 10px;
      }
    }
  }
  .more-info__map{
    margin-bottom: 10px;
  }
  .more-caution{
    font-size: .75rem;
  }

  &__bookmark{
    margin-bottom: 10px;
    &__container{
      background: #FF7E00;
      display: inline-block;
      .wpfp-span{
        line-height: 1.3;
        display: inline-block;
        color: #fff;
        font-size: .75rem;
        width: 186px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        a{
          color: #fff;
          display: block;
          &:before{
            content: "\f02e";
            font-family: FontAwesome;
            display: inline-block;
            font-size: 1rem;
            margin-right: 10px;
            color: #fff;
          }
        }
      }
    }
  }

  .widget_fudo_syousai{
    background: #fff;
    margin-top: 15px;
    padding: 15px 10px 10px 10px;
    border-radius: 8px;
    h3{
      margin: 0 0 20px;
      border-left: 4px solid #0068b7;
      border-bottom: 1px solid #0068b7;
      font-size: 1.125rem;
      padding: 3px 0 5px 10px;
    }
  }
  .syousai-content{
    @include clearfix;
    .syousai-content-li{
      @include span-columns(6);
      @include omega(2n);
      border: 1px solid #f2f2f2;
      position: relative;
      background: #fff;
      padding: 5px;
      margin-bottom: 2%;
      font-size: .75rem;
      a{
        display: block;
        img{
          width: 100%;
        }
      }
      .new_mark{
        background: #ca2c2c;
        color: #fff;
        font-size: .75rem;
        font-weight: bold;
        position: absolute;
        left: 10px;
        top: 10px;
        width: 46px;
        padding: 3px 0;
        line-height: 1;
        text-align: center;
      }
      .top_title{
        font-weight: bold;
        color: #3452a9;
      }
      .top_price{
        color: #ff6464;
        font-weight: bold;
      }
      .top_madori{
        color: #ff6464;
        font-weight: bold;
      }
      .top_menseki{
        color: #ff6464;
        font-weight: bold;
      }
      .top_shozaichi{
        color: #666666;
        line-height: 1.5;
      }
    }
  }

  .akijouhou{
    width: 280px;
    margin: 0 auto 20px;
  }

  &__pet{
    display: block;
    background: url(./assets/img/icon_pet.png) no-repeat;
    background-size: contain;
    padding-left: 25px;
    margin-right: 10px;
    margin-bottom: 2px;
    &__inu{
      display: block;
      background: url(./assets/img/icon_dog.png) no-repeat;
      background-size: contain;
      padding-left: 25px;
      margin-right: 10px;
      margin-bottom: 2px;
    }
    &__neko{
      display: block;
      background: url(./assets/img/icon_cat.png) no-repeat;
      background-size: contain;
      padding-left: 25px;
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }

  .tochikau-images{
    @include clearfix;
    &__item{
      @include span-columns(6);
      @include omega(2n);
      background: #fff;
      padding: 2px;
      margin-bottom: 1%;
      img{
        vertical-align: top;
        width: 100%;
        height: auto;
      }
    }
  }

}
