.contact{
  &__table{
    @include outer-container(100%);
    margin-bottom: 20px;
    border-top: 1px solid #dedede;
    tr{
      th{
        text-align: left;
        display: block;
        padding: 10px;
        width: 100%;
        background: #fafcfd;
        font-size: 0.8125rem;
        border: 1px solid #dedede;
        border-top: none;
        vertical-align: middle;
        @include clearfix;
        .required{
          float: right;
          color: #fff;
          background: #ff7e00;
          padding: 2px 10px;
          display: inline-block;
          font-size: .75rem;
        }
      }
      td{
        display: block;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        vertical-align: middle;
        border-top: none;
        input{
          margin: 0;
        }
      }
      select{
        margin: 0;
      }
      textarea{
        margin: 0;
      }
    }
  }
  input[type="submit"]{
    display: block;
    width: 180px;
    background: #ff7e00;
    color: #fff;
    margin: 0 auto;
  }
}
