


/* -------------------- Variables -------------------- */
$flg-blue: #5454ff;
$flg-red: #e94e4e;
$content-width: 1020px;
$font-family:'ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ 明朝', 'MS Mincho','メイリオ', Meiryo,sans-serif;

$color-bulue:#edf2f8;
$color-red:#fff8f2;
$color-green:#ecfaef;
//fixedの高さ PC
$fixed-height-pc:53px;
//fixedの高さ mobile
$fixed-height-mobile:40px;
/* -------------------- import -------------------- */
@import "bunjo02";
/* -------------------- common -------------------- */
body.page-template-page-bunjou{
  background: url(./assets/img/bunjo/kominka/vol01/bg_normal.jpg) repeat center center;
  margin: 0;
  font-family: $font-family;
}


.bunjou{
  .bunjou-heading{
    font-family: $font-family;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
    @include media($mobile){
      font-size: 16px;
      margin-bottom: 30px;
    }
    &:before{
      width: 50px;
      height: 1px;
      background: #333333;
      content: "";
      display: inline-block;
      margin-bottom: 9px;
      margin-right: 10px;
      @include media($mobile){
        margin-bottom: 6px;
        width: 25px;
      }
    }
    &:after{
      width: 50px;
      height: 1px;
      background: #333333;
      content: "";
      display: inline-block;
      margin-bottom: 9px;
      margin-left:  10px;
      @include media($mobile){
        margin-bottom: 6px;
        width: 25px;
      }
    }

  }
  .section-5-bunjou-heading{
    font-family: $font-family;
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
    @include media($mobile){
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .section-5-bunjou-heading--large{
    font-family: $font-family;
    font-size: 26px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
    @include media($mobile){
      font-size: 16px;
      margin-bottom: 10px;
    }
    color: #EB6767;
  }
  .section-5-bunjou-heading--bgcolor{
    padding: 40px 0px;
    background-color: #f5f5f5;
    margin: 20px 0;
  }
  .bunjou-heading--h4{
    text-align: center;
    font-family: $font-family;
    margin-bottom: 30px;
    font-size: 14px;

  }
  .bunjou-heading--h4--large{
    @extend .bunjou-heading--h4;
    color: #EB6767;
    font-size: 14px;
    margin-bottom: 0px;
    letter-spacing: 0.05em;
  }
  .bunjou-heading--time{
    font-family: $font-family;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
    .red{
      color: #EB6767;
    }
    @include media($mobile){
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .section{
    padding-top: 50px;
    @include media($middle){
      padding-top: 30px;
    }
  }
  .content-width{
    width:$content-width;
    margin: 0 auto;

    @include media($middle){
      width: 100%;
      padding: 0px 15px;
    }
  }




}




/* -------------------- 嘉川 -------------------- */
.bunjou{
  &__header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:$fixed-height-pc;
    @include media($mobile){
      height: $fixed-height-mobile;
    }
    background-color: #fff;
    opacity: 0.85;
    z-index: 888;
    @include clearfix;
    .bunjou__header__logo{
      float: left;
      margin-left: 10px;
      margin-top: 2px;

      img{
        height: 50px;
        width: auto;
        @include media($mobile){
          height: 30px;
        }
      }
    }
    .bunjou__header__nav{
      float: right;
      @include media($middle){
        display: none;
      }
      .bunjou__header__nav__list{
        line-height: 4.5;
        font-size: 12px;
        &__item{
          display: inline-block;
          margin-right: 10px;
          @include media($middle){
            margin-right: 7px;
          }
        }

      }
    }
    .bunjou__header_flag{
      float: right;
      margin-right: 10px;
      @include media($middle){
        display: none;
      }
      &__list{
        margin-top: 8px;
        font-size: 16px;

        &__item--blue{
          display: inline-block;
          background-color: $flg-blue;
          padding: 5px 15px;
          margin-right: 5px;
          a{
            color: #fff;
          }
        }
        &__item--red{
          display: inline-block;
          background-color: $flg-red;
          padding: 5px 15px;
          a{color: #fff;}
        }
      }

    }
  }
  //drawer.js
  .banner-sp{
    display: none;
    @include media($middle){
      display: block;

    }
    .drawer-hamburger{
      padding-top: 8px;
      width: 1.8rem;
    }
    .drawer-menu-item{
      text-align: center;
    }
    .drawer-menu-item:hover{
      text-decoration: none;
    }
  }
  .header_after{
    width:100%;
    height:$fixed-height-pc;
    margin-bottom: 50px;
    @include media($mobile){
      height: $fixed-height-mobile;
    }
  }
  .kanbai-img{
    text-align: center;
    margin-bottom: 90px;
    @include media($mobile){
      margin-bottom: 50px;
    }
    img{
      width: 100%;
    }
  }

  .main-visual{
    height: auto;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    top:0;
    border-bottom: 0;
    left: 0;
    right:0;
    @include media($large){
      height: 574px;
    }
    @include media($mobile){
      width: 100%;
      padding: 0px;
      height: auto;
      margin-bottom: 20px;
    }



    &__movie{
      width: 100%;
      height: auto;
      @include media($large){
        height: 800px;
      }

      video{
        width: 100%;
        height: auto;
        vertical-align: top;
      }

    }
    &__dot{
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 800px;
      background: url('./assets/img/bunjo/pattern.png');
      z-index:100;
      @include media($mobile){
        display: none;
      }
    }
    .main-visual__txt{
      position: absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      z-index: 10;
      //background-color: #fff;
      //opacity: 0.7;
      padding: 30px;
      width: 700px;
      text-align: center;
      background-color: #fff;
      opacity: 0.7;
      @include media($middle){
        padding: 10px 10px;
        width: 80%;
        font-size:16px;
        top:60%;
      }
      .main-visual__txt--p{
        font-size: 40px;
        font-weight: bold;
        margin: 0;
        color: #000;
        @include media($middle){
          font-size: 18px;
          text-align: center;
          letter-spacing: 0.05em;

        }
      }
    }
    .main-visual__txt--title{
      position: absolute;
      top:0%;
      left:0%;
      //-webkit-transform: translate(-50%,-50%);
      //-moz-transform: translate(-50%,-50%);
      //-ms-transform: translate(-50%,-50%);
      //-o-transform: translate(-50%,-50%);
      //transform: translate(-50%,-50%);
      z-index: 10;
      background-color: #eb6767;
      opacity: 0.7;
      padding: 30px;
      width: 700px;
      text-align: center;
      @include media($middle){
        padding: 10px 10px;
        width: 100%;
        font-size:16px;

      }
      .main-visual__txt--title--p{
        font-size: 40px;
        font-weight: bold;
        margin: 0;
        color: #fff;
        @include media($middle){
          font-size: 18px;
          text-align: center;

        }
      }
    }
    #main-visual__txt--p--1{
      display: block;
    }
    #main-visual__txt--p--2{
      display: none;
    }
    #main-visual__txt--p--3{
      display: none;
    }
  }
  //第三期様子
  .sub-visual{
    @extend .main-visual;
    &__movie{
      @extend .main-visual__movie;
      //border: 1px solid #777;
    }
  }
  //ギャラリー//
  .bunjou__gallery{
    margin-bottom: 50px;
    width: 100%;
    @include media($middle){
      width: 100%;
    }
    @include media($mobile){
      margin-bottom: 20px;
    }
    .slick-slide{
      height: auto;
    }
    &__item{
      img{
        @include media($large){
          width: 100%;
        }
      }
    }
  }

  .section-1{
    background: url("./assets/img/bunjo/section-1.jpg");
    background-repeat:no-repeat;
    background-size: cover;
    padding-bottom: 80px;
    @include media($middle){
      background: url("./assets/img/bunjo/section-1-sp.jpg");
      padding-bottom: 30px;
    }
    .section-1__concept{
      text-align: center;
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 15px;
      width: 420px;
      line-height: 3;


      @include media($mobile){
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 2;
        width: 100%;
      }
    }
  }
  .section-2{
    &__list{
      @include clearfix;
      .section-2__list__item{
        position: relative;
        width: 50%;
        height: 408px;
        float: left;
        text-align: center;

        @include media($mobile){
          width: 100%;
          height: 300px;
        }
      }
      .section-2__list__item__txt{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        width: 50%;

        @include media($mobile){
          width: 90%;
        }
        .section-2__list__item__txt--h3{
          //h3要素
          font-family:$font-family;
          font-size: 20px;
          margin-bottom: 30px;
          @include media($mobile){
            margin-bottom: 25px;
            font-size: 16px;
          }
        }
        p{
          line-height: 2.2;
          margin-bottom: 0;
          @include media($mobile){
            line-height: 2;

          }
        }
      }
      .section-2__list__item--1{
        @extend .section-2__list__item;
        background-image: url("./assets/img/bunjo/section-3-1.jpg");
        background-repeat:no-repeat;
        background-size: cover;
      }
      .section-2__list__item--2{
        @extend .section-2__list__item;
        background-image: url("./assets/img/bunjo/section-3-2.jpg");
        background-repeat:no-repeat;
        background-size: cover;
      }
      .section-2__list__item--3{
        @extend .section-2__list__item;
        background-image: url("./assets/img/bunjo/section-3-3.jpg");
        background-repeat:no-repeat;
        background-size: cover;
      }
      .section-2__list__item--4{
        @extend .section-2__list__item;
        background-image: url("./assets/img/bunjo/section-3-4.jpg");
        background-repeat:no-repeat;
        background-size: cover;
      }

    }
  }
  //動画
  .section-3{
    .section-3__movie{
      width: 1020px;
      height: 474px;
      margin: 0 auto;
      @include media($middle){
        width: 100%;
        height: 300px;
      }

    }
  }
  .section-4{
    .bunjou__section-4__list{
      width: 100%;
      margin-bottom: 50px;
      img{
        width: 100%;
      }
      @include media($middle){

      }
      .slick-slide{
        height: auto;
      }
      .bunjou__section-4__list__item{

        &__name{
          text-align: center;
          padding: 10px 0px;
          background-color: #edf2f8;
        }
        img{
          width: 100%;
        }
        &__info{
          text-align: center;
          padding: 10px 0px;
          background-color: #edf2f8;
        }
        &:nth-child(2n){
          .bunjou__section-4__list__item__name, .bunjou__section-4__list__item__info{
            background-color: #ecfaef;
          }
        }
        &:nth-child(3n){
          .bunjou__section-4__list__item__name, .bunjou__section-4__list__item__info{
            background-color: #fff8f2;
          }
        }

      }
    }

    .bunjou__section-4__txt{
      @include clearfix;
    }
    .bunjou__section-4__txt__item{
      @include media($large){
        @include span-columns(4);
        @include omega(3n);
      }
      @include media($middle){
        @include span-columns(6);
        @include omega(2n);
      }
      @include media($mobile){
        @include span-columns(12);
        @include omega(n);
      }
      margin-bottom: 30px;
    }
    .bunjou__section-4__txt__item--h3{
      font-size:16px;
      font-weight: bold;
      background-color: #999999;
      color: #fff;
      text-align: center;
      font-family: $font-family;
      padding: 6px 0px;
      letter-spacing: 0.5em;
      @include media($mobile){
        letter-spacing: 0.1em;
      }
    }
    .bunjou__section-4__txt__item__table{
      display: table;
      font-size: 14px;
      margin : 0 auto;

      .bunjou__section-4__txt__item__table--dt{
        width: 300px;
        display: table-cell;
        font-weight: normal;
        @include media($middle){
          width: 140px;
        }

      }
      .bunjou__section-4__txt__item__table--line{
        margin-right: 5px;
        &:after{
          content: "";
          display: inline-block;
          background: #fff;
          width: 60px;
          border-bottom: 1px solid #000;
          height: 1px;
          position: relative;
          bottom: 5px;
          @include media($mobile){
            width: 30px;
          }
        }
      }
      .bunjou__section-4__txt__item__table--dd{
        display: table-cell;
        text-align: right;
        width: 128px;
        @include media($middle){
          width: 70px;
        }
      }
    }
  }

  .section-5__fist{
    margin-bottom: 50px;
  }

  .section-6-map{
    height: 450px;
    @include media($middle){
      height: 300px;
    }

  }

  .section-7{
    margin-bottom: 50px;
    @include media($mobile){
      margin-bottom: 20px;
    }
    .section-7__bukken{
      padding: 50px 0px;
      @include clearfix;
      @include media($mobile){
        padding: 30px 0px;
      }
    }
    .section-7__bukken__img-pc{
      margin-bottom: -14px;
      @include media($mobile){
        display: none;
      }
    }
    .section-7__bukken__img-sp{
      display: none;
      @include media($mobile){
        display: block;
        margin-top: 10px;

      }
    }

    .section-7__bukken--1{
      @extend .section-7__bukken;
      background-color:$color-bulue;
    }
    .section-7__bukken--2{
      @extend .section-7__bukken;
      background-color:$color-green;
    }
    .section-7__bukken--3{
      @extend .section-7__bukken;
      background-color:$color-red;
    }
    .section-7__bukken--clearfix{
      @include clearfix;
      margin-bottom: 40px;
    }
    .section-7__bukken__img--1{
      float: left;
      margin-right: 0px;
      width: 457px;
      height: 306px;
      background: url('./assets/img/bunjo/bunjyou_1_3.png');
      background-repeat: no-repeat;
      background-size:cover;
      @include media($mobile){
        float: none;
        width: 292px;
        height: 211px;
        background: url('./assets/img/bunjo/bunjyou_1_3_1.png');
        background-size:contain;
        background-repeat: no-repeat;
        margin-right: 0px;
        margin: 0 auto;
      }
    }
    .section-7__bukken__img--2{
      float: left;
      margin-right: 0px;
      width: 457px;
      height: 306px;
      background: url('./assets/img/bunjo/bunjyou_1_4.png');
      background-repeat: no-repeat;
      background-size:cover;
      @include media($mobile){
        float: none;
        width: 290px;
        height: 209px;
        background: url('./assets/img/bunjo/bunjyou_1_4_1.png');
        background-size:contain;
        background-repeat: no-repeat;
        margin-right: 0px;
        margin: 0 auto;
      }
    }
    .section-7__bukken__img--3{
      float: left;
      margin-right: 0px;
      width: 457px;
      height: 306px;
      background: url('./assets/img/bunjo/bunjyou_1_5.png');
      background-repeat: no-repeat;
      background-size:cover;
      @include media($mobile){
        float: none;
        width: 290px;
        height: 209px;
        background: url('./assets/img/bunjo/bunjyou_1_5_1.png');
        background-size:contain;
        background-repeat: no-repeat;
        margin-right: 0px;
        margin: 0 auto;
      }
    }
    .section-7__bukken__txt{
      float: left;
      width: 563px;
      height: 290px;
      padding: 30px;
      border: 2px solid #323333;
      margin-top: 7px;
      background-color: #fff;
      @include media($middle){
        width: auto;
        height: auto;
        float: none;
        padding: 10px;
        margin-left: 0px;
        margin-top: 0px;
        border: 1px solid #323333;

      }


      .section-7__bukken__txt--h3{
        //h3要素
        font-family:$font-family;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .section-7__bukken__txt--dl{
        display: block;
        margin-bottom: 10px;
      }
      .section-7__bukken__txt--title{
        display: inline-block;
        background-color: #999999;
        color: #fff;
        font-weight: normal;
        width: 90px;
        line-height: 2.0;
        text-align: center;
        margin-right: 20px;
        letter-spacing: 0.05em;
        @include media($mobile){
          margin-right: 0px;
          width: 70px;
          font-size: 12px;
        }
      }
      .section-7__bukken__txt--content{
        display: inline-block;
        font-size: 12px;
      }
    }
    .section-7__btn{
      text-align:center;
      a{
        font-size: 18px;
        color: #e07373;
        line-height: 3;
        border: 1px solid #e07373;
        padding: 20px 80px;
        letter-spacing: 0.1em;
        background-color: #fff;
        @include media($mobile){
          padding: 15px 60px;
        }
        &:hover{
          background-color: #e07373;
          color: #fff;
        }
      }


    }
  }
  .bukken__contanct{

    margin-bottom: 50px;
    @include media($mobile){
      display: none;
    }

    .content-width__contact{
      @include clearfix;
      width: 650px;
      margin: 0 auto;
      @include media($mobile){
        width: 100%;
      }
      .lower-include-contact__container__left {
        width: 360px;
        float: left;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border: 1px solid #7d7d7d;
        border-radius: 4px;
      }
      .lower-include-contact__container__left__heading {
        text-align: center;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.3;
        margin-bottom: 5px;
      }
      .lower-include-contact__container__left__tel {
        display: block;
        text-align: center;
      }
      .lower-include-contact__container__left__time {
        text-align: center;
        font-size: 0.8125rem;
        margin: 0;
      }
      .lower-include-contact__container__left{
        @include media($mobile){
          width: 100%;
          float: none;

        }
      }
      .lower-include-contact__container__right a{
        display: block;
        padding: 10px;
      }
      .lower-include-contact__container__right__heading{
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 1.25rem;
      }
      .lower-include-contact__container__right__txt{
        color: #fff;
        font-size: 1rem;
        line-height: 1.4;
      }
      .lower-include-contact__container__right__icon-mail {
        position: absolute;
        right: 15px;
        bottom: 20px;
      }
      .lower-include-contact__container__right{
        position: relative;
        width: 280px;
        float: left;
        background: #c70000;
        border-radius: 4px;
        margin-left: 10px;
        @include media($mobile){
          margin-left: 0px;
          float: none;
          margin: 0 auto;
        }
      }
    }
    .bukken__contanct__title{
      @include media($large){
        font-size:24px;
        letter-spacing: 0.1em;
        font-weight: bold;
        font-family: $font-family;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }

}
