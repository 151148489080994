/* -------------------- common -------------------- */
.kominka{
  width: 100%;
  max-width: 100%;
  font-size: 1rem;
  line-height: 2.2;
  letter-spacing: 0.1rem;
  color: #333;
  @include media($middle){
    font-size: 1rem;
    line-height: 1.6;
  }
  h1,h2,h3{
    font-family: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ 明朝', 'MS Mincho','ＭＳ Ｐ明朝','MS PMincho','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
    font-weight: normal;
    line-height: 1.3;
    color: #000;
  }
  .contant-wrap{
    @include outer-container(1240px);
    padding: 0 0 50px;
    @include media($middle){
      padding: 0 10px 25px 10px;
    }
  }
  &__heading{
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 30px;
    padding-top: 100px;
    @include media($middle){
      padding-top: 45px;
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
    &:after{
      content: "";
      display: block;
      width: 75px;
      height: 1px;
      background: #000;
      margin: 15px auto 0;
      @include media($middle){
        margin: 10px auto 0;
      }
    }
  }
  .normal-txt{
    margin: 0;
    @include media($middle){
      font-size: .875rem;
      text-align: left;
    }
    &__center{
      text-align: center;
    }
  }
}
.tablet-show{
  display: none !important;
  @include media($middle){
    display: block !important;
  }
}
.tablet-none{
  display: table-cell !important;
  @include media($middle){
    display: none !important;
  }
}
/* -------------------- 田楽庵 -------------------- */
body.page-template-page-kominka_vol_1{
  background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
  margin: 0;
  font-family: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ヒラギノ明朝 ProN','Hiragino Mincho ProN','ＭＳ 明朝', 'MS Mincho','ＭＳ Ｐ明朝','MS PMincho','ヒラギノ明朝 ProN','Hiragino Mincho ProN',sans-serif;
  .main-visual{
    &__title{
      margin: 0;
      line-height: 0;
      img{
        width: 100%;
        height: auto;
        line-height: 1;
      }
    }
  }
  .section-01{
    background: url(./assets/img/kominka/vol01/sec_01_bg.jpg) no-repeat center bottom;
    background-size: cover;
    &__col2{
      @include clearfix;
      margin-top: 75px;
      @include media($middle){
        margin-top: 45px;
      }
      &__item{
        @include span-columns(6);
        @include omega(2n);
        @include clearfix;
        margin-bottom: 20px;
        &__left{
          @include span-columns(5);
          @include media($middle){
            @include span-columns(12);
          }
        }
        &__right{
          @include span-columns(7);
          padding: 5px 10px 0 10px;
          @include media($middle){
            @include span-columns(12);
            padding: 5px 5px 0 5px;
          }
          &__heading{
            font-size: 1.25rem;
            margin-bottom: 10px;
            @include media($middle){
              font-size: 1rem;
            }
          }
          &__txt{
            font-size: 0.8125rem;
            line-height: 1.5;
            text-align: justify;
            @include media($middle){
              font-size: .75rem;
            }
          }
        }
      }
    }
  }
  .section-02{
    background: url(./assets/img/kominka/vol01/sec_02_bg.jpg) no-repeat center top;
    background-size: cover;
    .normal-txt{
      @include media($middle){
        padding: 0 10px;
      }
    }
    &__col2{
      display: table;
      width: 100%;
      margin-top: 75px;
      @include media($middle){
        margin-top: 45px;
        display: block;
      }
      &__item{
        background: rgba(255,255,255,0.6);
        display: table-row;
        @include media($middle){
          display: block;
        }
        &__thumb{
          position: relative;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          width: 50%;
          line-height: 0;
          @include media($middle){
            width: 100%;
            display: block;
            margin-bottom: 20px;
          }
          img{
            width: 100%;
            height: auto;
          }
        }
        &__txtarea{
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          width: 50%;
          font-size: 0.875rem;
          @include media($middle){
            width: 100%;
            padding: 0 10px 30px 10px;
            display: block;
          }
          &__heading{
            font-size: 1.5rem;
            margin-bottom: 25px;
            @include media($middle){
              font-size: 1.25rem;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .section-03{
    background: url(./assets/img/kominka/vol01/sec_03_bg.jpg) no-repeat center top;
    background-size: cover;
    &__col2{
      @include clearfix;
      margin-top: 75px;
      @include media($middle){
        margin-top: 30px;
      }
      &__item{
        width: 580px;
        float: left;
        margin-right: 80px;
        margin-bottom: 75px;
        @include omega(2n);
        @include media($middle){
          width: 100%;
          float: none;
          margin-right: 0;
          margin-bottom: 45px;
        }
        &__thumb{
        }
        &__info{
          padding: 0 20px;
          margin-top: -35px;
        }
        &__heading{
          background: #000;
          color: #fff;
          display: inline-block;
          padding: 8px 0;
          width: 200px;
          text-align: center;
          font-size: 1rem;
          margin-bottom: 20px;
        }
        &__txt{
          @include media($middle){
            font-size: .875rem;
          }
        }
      }
    }
  }

  .section-04{
    background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
    &__contents{
      @include clearfix;
      margin-top: 75px;
      @include media($middle){
        margin-top: 30px;
      }
      &__item{
        width: 310px;
        float: left;
        box-shadow: 0 0px 3px #e9e9e9;
        padding: 35px 25px;
        position: relative;
        height: 390px;
        background: #fff;
        @include media($middle){
          width: 100%;
          float: none;
          padding: 35px 20px;
          height: auto;
        }
        &__thumb{
          text-align: center;
          margin-bottom: 20px;
        }
        &__txt{
          font-size: 0.875rem;
          line-height: 1.8;
          text-align: justify;
          letter-spacing: 0.05rem;
        }
      }
    }
  }

  .section-05{
    background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
    &__lightbox{
      margin-top: 20px;
      &__list{
        @include clearfix;
        &__item{
          @include span-columns(2);
          @include omega(6n);
        }
      }
    }
    &__media{
      margin-top: 45px;
      @include media($middle){
        margin-top: 30px;
      }
      &__heading{
        text-align: center;
        margin-bottom: 30px;
        @include media($middle){
          font-size: 1rem;
        }
      }
      &__image{
        text-align: center;
      }
    }
  }

  .section-06{
    background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
  }
  .section-07{
    background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
    &__container{
      @include outer-container(980px);
      @include clearfix;
      margin-top: 75px;
      @include media($middle){
        margin-top: 30px;
      }
      &__left{
        @include span-columns(5);
        @include media($middle){
          @include span-columns(12);
          text-align: center;
          margin-bottom: 10px;
        }
      }
      &__right{
        @include span-columns(7);
        @include media($middle){
          @include span-columns(12);
        }
      }
    }
    &__table{
      line-height: 1.3;
      font-size: .875rem;
      width: 100%;
      margin: 0;
      th{
        width: 28%;
        padding: 10px;
      }
      td{
        padding: 10px;
      }
    }
    .map{
      iframe{
        width: 100%;
      }
    }
  }

  .section-08{
    background: url(./assets/img/kominka/vol01/bg_normal.jpg) repeat center center;
    .map{
      iframe{
        width: 100%;
        @include media($middle){
          height: 300px;
        }
      }
    }
    &__txt{
      text-align: center;
      @include media($middle){
        font-size: .75rem;
      }
    }
  }

  .contact{
    background-size: cover;
    margin-bottom: 150px;
    @include media($middle){
      margin-bottom: 60px;
    }
    &__container{
      background: url(./assets/img/kominka/vol01/bg_contag.jpg) no-repeat center center;
      height: 320px;
      @include media($middle){
        height: auto;
        padding: 10px;
      }
    }
    &__heading{
      color: #fff;
      text-align: center;
      padding: 75px 0 0 0;
      font-size: 1.5rem;
      margin-bottom: 55px;
      @include media($middle){
        font-size: 1rem;
        padding: 30px 0 0 0;
        margin-bottom: 20px;
      }
    }
    &__list{
      text-align: center;
      &__item{
        display: inline-block;
        width: 360px;
        font-size: 1.5rem;
        margin: 0 10px;
        @include media($middle){
          margin: 5px 0;
          width: 270px;
          font-size: 1rem;
        }
        a{
          color: #fff;
          display: block;
          padding: 10px 0;
          border: 1px solid #fff;
        }
      }
    }
  }

}
/* -------------------- 古民家ヘッダー -------------------- */
/* -------------------- 古民家フッター -------------------- */
.kominka-footer{
  &__list{
    text-align: center;
    margin-bottom: 30px;
    &__item{
      display: inline-block;
      margin: 0 20px;
      @include media($middle){
        font-size: .75rem;
        margin: 0 5px;
      }
    }
  }
  .copy{
    display: block;
    text-align: center;
    background: #000;
    color: #fff;
    padding: 15px 0;
    @include media($middle){
      font-size: .75rem;
    }
  }
}
.kominka-sns{
  text-align: center;
  margin-bottom: 45px;
  &__list{
    &__item{
      display: inline-block;
      line-height: 1;
      vertical-align: top;
      margin: 0 5px;
      @include media($mobile){
        margin: 5px 5px;
      }
    }
  }
}
