html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
  height: 100%;
}

body{
  font-size: 0.875rem;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust:100%
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: #000;
  text-decoration-skip: ink;
  transition: color $base-duration $base-timing;
  text-decoration: none;

  &:hover {
    //color: shade(#000, 25%);
    opacity: 0.8;
    transition: all 0.3s ease;
  }

  &:focus {
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
img{
  border: none;
}
