.archive{

  &__list{
    &__item{
      @include clearfix;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px dotted #7f7f7f;
      &__left{
        @include span-columns(3);
        img{
          height: auto;
          vertical-align: top;
        }
      }
      &__right{
        @include span-columns(9);
        padding-top: 5px;
      }
      &__time{
        font-size: .75rem;
        margin-bottom: 5px;
      }
      &__heading{
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.3;
        a{
          color: #3452a9;
        }
      }
    }
  }

  &__paging{
    text-align: center;
    font-size: 0;
    margin-top: 45px;
    .page-numbers{
      font-size: .875rem;
      display: inline-block;
      margin: 0 5px;
      padding: 10px;
      line-height: 1;
      border: 1px solid #000;
      color: #000;
      &.current{
        color: #fff;
        background: #000;
      }
    }
  }

}
