.header{
  position: relative;
  z-index: 4444;
  &__bar{
    width: 100%;
    background: #d90000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4444;
    &__item{
      @include clearfix;
      @include outer-container($content-width);
      padding: 10px 0;
      &__txt{
        float: left;
        margin: 0;
        color: #fff;
        font-size: 0.75rem;
        margin-top: 7px;
      }
      &__menu{
        float: right;
        &__item{
          display: inline-block;
          font-size: 0.75rem;
          margin-left: 15px;
          border: 1px solid #fff;
          a{
            display: block;
            padding: 5px 10px;
            color: #fff;
          }
        }
      }
    }
  }

  &__container{
    @include outer-container($wrap-width);
    @include clearfix;
  }

  &__logo{
    float: left;
    margin: 7px 0 0 20px;
    vertical-align: bottom;
    line-height: 0;
    position: relative;
    top: 1px;
  }

  &__contact{
    float: right;
    margin-top: 30px;
    &__tel{
      display: inline-block;
      margin-right: 15px;
      position: relative;
      bottom: 2px;
    }
    &__mail{
      display: inline-block;
    }
  }

  &__navi{
    @include outer-container($wrap-width);
    position: relative;
    &__list{
      @include clearfix;
      background: url(./assets/img/header_navi_bg.png) no-repeat;
      background-size: $wrap-width;
      padding: 0 20px;
      &:after{
        content: "";
        display: block;
      }
      &__item{
        width: 137px;
        float: left;
        text-align: center;
        font-size: 1rem;
        padding: 10px 0;
        font-weight: bold;
        &:last-child{
          a{
            border-right: none;
          }
        }
        a{
          color: #010101;
          display: block;
          border-right: 1px solid #dadada;
          padding: 11px 0;
          transition: all  0.3s ease;
          &:hover{
            background: #d5d5d5;
            transition: all  0.3s ease;
          }
        }
        &__en{
          display: block;
          text-align: center;
          color: #0078cc;
          font-size: 0.75rem;
        }
      }
    }
  }
}
