#loader {
	width: 100%;
	height: 100%;
	margin: 0;
	background: #fff;/*Loaderの背景色としたいものを指定*/
	opacity: 1.0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000; /*一番前面に出るように（今回はbxSlidrの矢印が9999だったのでそれを超えるようにしました）*/
}
#loader img {
  width: 200px;
	position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%); /* Safari用 */
  transform: translate(-50%, -50%);
}
.mb-30{
  margin-bottom: 30px;
}
.container{
  width: 100%;
}
.wrapper{
  //padding: 6px 0;
}
.white-box{
  background: #fff;
  //border-radius: 4px;
  padding: 15px 10px;
}
.content-wrap{
  padding: 0 6px;
  &__first{
    &:before{
      content: "";
      display: block;
      height: 6px;
      background: #7b7b7b; /* Old browsers */
      background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
    }
  }
  &__inner{
    border-left: 4px solid #9acedb;
    border-right: 4px solid #9acedb;
    padding: 0 7px;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(left, #dff2fa 0%, #71accb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dff2fa 0%,#71accb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dff2fa 0%,#71accb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#71accb',GradientType=1 ); /* IE6-9 */
    &__add-pd{
      padding: 10px 7px;
    }
  }
}

.heading-block{
  &:before,&:after{
    content: "";
    display: block;
    height: 6px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }
  &__container{
    @include clearfix;
    position: relative;
    background: #dcdfe7; /* Old browsers */
    background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 ); /* IE6-9 */
    min-height: 60px;
    padding: 10px 10px;
    z-index: 2222;
    box-shadow:0px 2px 0px 0px rgba(0,0,0,0.2);
  }

  &__tab{
    @include clearfix;
    &__item{
      @include span-columns(4);
      cursor: pointer;
      position: relative;
      bottom: 2px;
      text-align: center;
      font-weight: bold;
      padding: 10px 0;
      background: #999999;
      &:before{
        margin-bottom: 2px !important;
      }
      &.tintai{
        &:before{
          content: "";
          display: block;
          width: 30px;
          height: 29px;
          margin: 0 auto;
          background: url(./assets/img/sp_icon_tab_tintai_off.png) no-repeat center bottom;
          background-size: cover;
        }
      }
      &.uri{
        &:before{
          content: "";
          display: block;
          width: 22px;
          height: 29px;
          margin: 0 auto;
          background: url(./assets/img/sp_icon_tab_uri_off.png) no-repeat center bottom;
          background-size: cover;
        }
      }
      &.bunjyou{
        &:before{
          content: "";
          display: block;
          width: 25px;
          height: 29px;
          margin: 0 auto;
          background: url(./assets/img/sp_icon_tab_bunjyou_off.png) no-repeat center bottom;
          background-size: cover;
        }
      }
      &--active{
        position: relative;
        cursor: auto;
        &:after{
          content:"";
          display:block;
          position:absolute;
          left: 0;
          right: 0;
          bottom:-6px;
          width:0;
          margin: 0 auto;
          border:20px solid transparent;
          border-top: 8px solid transparent;
          border-top-color:#ccc;
          border-bottom-width:0;
        }
        &.tintai{
          background: #006ea7;
          &:before{
            background: url(./assets/img/sp_icon_tab_tintai_on.png) no-repeat center bottom;
            background-size: cover;
          }
          &:after{
            border-top-color: #006ea7;
          }
        }
        &.uri{
          background: #ff5e5e;
          &:before{
            background: url(./assets/img/sp_icon_tab_uri_on.png) no-repeat center bottom;
            background-size: cover;
          }
          &:after{
            border-top-color: #ff5e5e;
          }
        }
        &.bunjyou{
          &:before{
            background: url(./assets/img/sp_icon_tab_bunjyou_on.png) no-repeat center bottom;
            background-size: cover;
          }
          background: #5e951d;
          &:after{
            border-top-color: #5e951d;
          }
        }
      }
      &__txt{
        color: #fff;
      }
    }
  }

}

.heading-03{
  font-size: 0.875rem;
  line-height: 1.3;
  border-left: 6px solid #0068b7;
  padding: 5px 0 5px 8px;
  border-bottom: 2px solid #0068b7;
  margin-bottom: 15px;
}

.btn{
  &__red{
    display: block;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    background: #ca2c2c;
    border-radius: 6px;
    border-bottom: 1px solid #cccccc;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 10px 0;
    color: #fff;
    span{
      display:inline-block;
    }
  }
  &__simulation{
    width: 250px;
    padding: 15px 0;
    &:hover{
      padding: 16px 0 15px 0;
    }
  }
}

.page-title{
  font-size: .875rem;
}

.icon-arrow{
  background: url(./assets/img/icon_arrow_white.png) no-repeat left center;
  background-size: 8px 12px;
  padding-left: 15px;
}

.sp-post-list{
	padding: 10px 0;
  .post-item{
		margin: 0 3px;
		background: #fff;
		&__thumb{
			text-align: center;
			overflow: hidden;
			height: 150px;
			img{
				width: 100%;
				height: auto;
			}
		}
		&__right{
			padding: 10px 15px 15px 15px;
		}
		&__name{
			font-size: .875rem;
			line-height: 1.3;
			margin-bottom: 5px;
			a{
				color: #3452a9;
				text-decoration: underline;
			}
		}
		&__price{
			font-size: .75rem;
			display: inline-block;
			color: #ca2c2c;
			font-weight: bold;
			margin-right: 5px;
		}
		&__floor{
			position: absolute;
			left: 0;
			top: 0;
			background: #ca2c2c;
			font-size: .75rem;
			padding: 2px 5px;
			display: inline-block;
			color: #fff;
			font-weight: bold;
		}
		&__address{
			font-size: .675rem;
			color: #666666;
		}
  }
  .slick-prev{
    left: -5px;
    z-index: 2222;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 20px;
      height: 20px;
      background: url(./assets/img/slider_icon_left.png) no-repeat;
      background-size: cover;
    }
  }

  .slick-next{
    right: -5px;
    z-index: 2222;
    &:before{
      content: "";
      display: inline-block;
      opacity: 1;
      width: 20px;
      height: 20px;
      background: url(./assets/img/slider_icon_right.png) no-repeat;
      background-size: cover;
    }
  }
}

.heading-block{
  &__title{
    text-align: center;
    font-size: 1.875rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    img{
      vertical-align: middle;
    }
  }

  &__col-03{
    @include clearfix;
    margin: 0 25px;
    padding-top: 15px;
    &__item{
      float: left;
      margin: 0 25px;
    }
  }
	&__col-01{
		position: relative;
		bottom: 2px;
		background: #ff7e00;
		font-size: 1rem;
		font-weight: bold;
		color: #fff;
		text-align: center;
		cursor: pointer;
		padding: 15px 0;
		margin-bottom: 0;
		&:after{
			content:"";
			display:block;
			position:absolute;
			left: 0;
			right: 0;
			bottom:-8px;
			width:0;
			margin: 0 auto;
			border:20px solid transparent;
			border-top: 8px solid transparent;
			border-top-color:#ff7e00;
			border-bottom-width:0;
		}
	}
  &__col-02{
    @include clearfix;
    &__item{
      @include span-columns(6);
      position: relative;
      bottom: 2px;
      background: #ff7e00;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      text-align: center;
      cursor: pointer;
      padding: 15px 0;
      &:after{
        content:"";
        display:block;
        position:absolute;
        left: 0;
        right: 0;
        bottom:-8px;
        width:0;
        margin: 0 auto;
        border:20px solid transparent;
        border-top: 8px solid transparent;
        border-top-color:#ff7e00;
        border-bottom-width:0;
      }
    }
  }

}


.col-02-content{
  @include clearfix;
  background: #7b7b7b; /* Old browsers */
  background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
  &__left{
    @include span-columns(6);
    padding: 10px 8px 10px 0;
    background: #dff2fa; /* Old browsers */
    background: -moz-linear-gradient(left, #dff2fa 0%, #aad0e3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dff2fa 0%,#aad0e3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dff2fa 0%,#aad0e3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dff2fa', endColorstr='#aad0e3',GradientType=1 ); /* IE6-9 */
  }
  &__right{
    @include span-columns(6);
    padding: 10px 0 10px 8px;
    background: #a6cee2; /* Old browsers */
    background: -moz-linear-gradient(left, #a6cee2 0%, #72adcb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #a6cee2 0%,#72adcb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #a6cee2 0%,#72adcb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6cee2', endColorstr='#72adcb',GradientType=1 ); /* IE6-9 */
  }
  &__box{
    background: #fff;
    border-bottom: 1px solid #b2c2c8;
    border-radius: 4px;
    padding: 10px 8px;
    margin-bottom: 8px;
    &__select{
      width: 100%;
      font-size: .875rem;
      border: 1px solid #ccc;
      margin: 3px 0;
    }
    &__01{
      margin-bottom: 15px;
    }
  }
  &__price{
    margin-bottom: 10px;
  }
  &__list{
    @include clearfix;
    margin-bottom: 15px;
    &__item{
      border-bottom: 1px solid #e5e5e5;
      padding-top: 5px;
      &:last-child{
        border-bottom: none;
      }
      label{
        font-size: .8125rem;
      }
    }
  }
}

.pr{
  padding: 10px 0;
}

.info{
  @include clearfix;
  margin: 0;
  padding: 8px 0;
  &__item{
    position: relative;
    background: #fff;
    padding: 8px 8px 20px 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border-bottom: 1px solid #8ba9b7;
    &:last-child{
      margin-bottom: 0;
    }
		&__image{
      margin-bottom: 5px;
			text-align: center;
    }
    &__reform-txt{
      font-size: .75rem;
      text-align: justify;
      line-height: 1.5;
      margin-bottom: 15px;

    }
    &__container{

    }
    &__news-list{
      margin-bottom: 10px;
      &__item{
        border-bottom: 1px dotted #7f7f7f;
        &:last-child{
          border-bottom: none;
        }
        a{
          color: #000;
          padding: 10px 0;
          display: inline-block;
        }
      }
    }
    &__archive-link{
      text-align: right;
      position: absolute;
      right: 10px;
      bottom: 0px;
      font-size: .75rem;
      a{
        color: #3452a9;
        text-decoration: underline;
      }
    }
  }
}
.page-top{
  display: none;
  position: fixed;
  z-index: 3333;
  right: 10px;
  bottom: 60px;
}
.lower-content-box{
  background: #fff;
  padding: 20px 10px;
}
.lower-heading{
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 15px;
  color: #010101;
  background: #f3fafd;
  border-bottom: solid 2px #0068b7;
  line-height: 1.3;
}
.lower-sub-heading{
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 10px;
  padding: 5px 0 5px 7px;
  border-left: 3px solid #0068b7;
}
.lower-include-contact{
  &__no-bar{
    &:before,&:after{
      display: none !important;
    }
    .lower-include-contact__container{
      background: none !important;
      box-shadow: none;
    }
  }
  &:before,&:after{
    content: "";
    display: block;
    height: 6px;
    background: #7b7b7b; /* Old browsers */
    background: -moz-linear-gradient(left, #7b7b7b 0%, #554c4c 50%, #7b7b7b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #7b7b7b 0%,#554c4c 50%,#7b7b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b7b7b', endColorstr='#7b7b7b',GradientType=1 ); /* IE6-9 */
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }
  &__container{
    @include clearfix;
    position: relative;
    background: #dcdfe7; /* Old browsers */
    background: -moz-linear-gradient(left, #dcdfe7 0%, #f6f2f2 50%, #dcdfe7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dcdfe7 0%,#f6f2f2 50%,#dcdfe7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcdfe7', endColorstr='#dcdfe7',GradientType=1 ); /* IE6-9 */
    min-height: 60px;
    padding: 20px 10px;
    z-index: 2222;
    box-shadow:0px 2px 0px 0px rgba(0,0,0,0.2);
  }
  &__tel{
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    background: #fff;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    a{
      display: block;
      background: url(./assets/img/sp_include_contact_icon_tel.png) no-repeat left 10px center;
      background-size: 34px 34px;
      padding: 15px 0 15px 70px;
    }
  }
  &__mail{
    border-radius: 4px;
    background: #c70000;
    font-size: 1rem;
    font-weight: bold;
    a{
      display: block;
      background: url(./assets/img/sp_include_contact_icon_mail.png) no-repeat left 10px center;
      background-size: 34px 34px;
      color: #fff;
      padding: 15px 0 15px 70px;
    }
  }
}
#nendebcopy{
  display: none;
}
.pageback{
  a{
    font-size: .75rem;
    display: inline-block;
    padding: 5px 10px;
    background: #FF7E00;
    color: #fff;
  }
}
