.footer-fixed{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2223;
}
.footer-fixed-btn{
  position: absolute;
  right: 0px;
  top: -30px;
  &:hover{
    cursor: pointer;
  }
  img{
    width: 25px;
  }
}
.bottom-contact{
  position: relative;
  z-index: 2222;
  background: url(./assets/img/bottom_contact_bg.png) repeat-x center bottom;
  height: 360px;
  &__title{
    text-align: center;
    padding-top: 34px;
    z-index:3000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  &__container {
    position: relative;
    width: 1057px;
    height: 334px;
    margin: 0 auto;
    /*background: url(./assets/img/bottom_contact_building_2.png) no-repeat center top;*/
    background-size: 1057px;
  }
  &__btn{
    position: absolute;
    right: 90px;
    bottom: 10px;
    z-index:3333;
  }


  #img_01{
    position: absolute;
    width: 1057px;
    height: 334px;
    z-index:5;
}

}
#img_02{
  position: absolute;

  top:122px;
  left:379px;
  z-index:1;
  z-index:4;
 }

 #img_03{
  position: absolute;
  top:122px;
  left:530px;
  z-index:4;
 }
 #img_04{
  position: absolute;
  top:122px;
  left:381px;
  z-index:2;
 }
 #peple img{
    position: absolute;
    right: 0;
    bottom: -25px;
    left: 0;
    margin: auto;
    z-index:4000;
    display:none;
    height:310px;
    width:auto;
 }

.footer{
  background: #fff;
  &__container{
    @include outer-container($wrap-width);
    @include clearfix;
    padding: 20px 20px 50px 20px;
    background: #fff;
  }

  &__left{
    width: 318px;
    float: left;
    &__logo{
      display: block;
      margin-bottom: 10px;
    }
    &__info{
      @include clearfix;
      &__tel{
        display: block;
        margin-bottom: 5px;
        @include span-columns(6);
        &:first-child{
          padding-right: 5px;
        }
        &:last-child{
          padding-left: 5px;
        }
      }
    }
    &__txt{
      margin: 0;
      font-size: 0.75rem;
      color: #646464;
    }
  }

  &__right{
    width: 620px;
    float: right;
    &__txt{
      color: #666666;
      font-size: 0.625rem;
    }
  }

  &__search{
    display: table;
    width: 100%;
    border-bottom: 1px solid #666666;
    padding-bottom: 10px;
    margin-bottom: 15px;
    &__item{
      display: table-row;
      line-height: 1.8;
    }
    &__heading{
      display: table-cell;
      color: #666666;
      font-size: 0.75rem;
      width: 117px;
    }
    &__list{
      display: table-cell;
      &__item{
        display: inline-block;
        font-size: 0.75rem;
        a{
          color: #666666;
        }
      }
    }
  }

  &__bottom{
    background: #c70000;

    &__container{
      @include outer-container($wrap-width);
      @include clearfix;
      padding: 30px 20px;
    }

    &__navi{
      text-align: center;
      font-size: 0;
      margin-bottom: 35px;
      &__item{
        display: inline-block;
        font-weight: bold;
        font-size: 0.875rem;
        &:after{
          content: "|";
          color: #fff;
          display: inline-block;
          margin: 0 5px;
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        a{
          color: #fff;
        }
      }
    }

    .copy{
      display: block;
      text-align: center;
      color: #fff;
      font-size: .75rem;
    }

  }

}


.add-fixed-koukoku{
  position: fixed;
  bottom: 0px;
  left:0px;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index:9998;
  padding: 7px 0px;
  &__list{
    @include clearfix;
    text-align: center;
    &__item{
      &:first-child{
        width: 40%;
        display: inline-block;
        //float: left;
      }
    }
  }
  &__close{
    width: 50px;
    position: fixed;
    bottom:50px;
    right:20px;
    z-index: 9999;
    width: 30px;
  }
  img{
    display: block;
    width: 780px;
    margin: 0 auto;

  }
}
