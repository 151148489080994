.company{

  .greeting{
    margin-bottom: 20px;
    &__name{
      text-align: right;
    }
    &__photo{
      @include clearfix;
      &__item{
        @include span-columns(6);
      }
    }
  }

  .info{
    &__table{
      @include outer-container(100%);
      tr{
        th{
          padding: 10px;
          width: 80px;
          background: #fafcfd;
          font-size: 0.8125rem;
          border: 1px solid #dedede;
          text-align: center;
          vertical-align: middle;
          font-size: .75rem;
        }
        td{
          padding: 10px;
          border: 1px solid #ccc;
          vertical-align: middle;
          font-size: .75rem;
        }
      }
    }
  }

}
